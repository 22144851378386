import { UserInfo, UpdateUserPayload, UserRole } from 'nvzn-models';
import React, { Component } from 'react';
import { Form, Card, Button, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { updateUser, adminGetUser, getUser } from '../../state/actions';
import { State } from '../../state/reducer';
import { routes } from '../../util';

interface IEditUserProps extends RouteChildrenProps<{ uuid: string }> {
  loading: boolean;
  submitting: boolean;
  error: string;
  submissionError: string;
  userInfo: UserInfo;
  userDetails: UserInfo;
  adminUserId: string;
  adminGetUser(): void;
  updateUser(payload: UpdateUserPayload): void;
  getUser(): void;
}

interface FieldState<T = string> {
  value: T;
  clean: boolean;
  errorMessage?: string;
}

interface IEditUserState {
  email: FieldState;
  password: FieldState;
  username: FieldState;
  firstName: FieldState;
  lastName: FieldState;
  companyName: FieldState;
  role: FieldState;
  confirm_password: FieldState;
}

const mapStateToProps = (state: State, ownProps: IEditUserProps) => ({
  loading: state.ADMIN_GET_USER.loading,
  error: state.ADMIN_GET_USER.error?.message,
  userInfo: state.ADMIN_GET_USER.data,
  adminUserId: state.GET_USER.data.uuid,
  submitting: state.ADMIN_UPDATE_USER.loading,
  submissionError: state.ADMIN_UPDATE_USER.error?.message,
  userDetails: state.GET_USER.data,
});

const mapDispatchToProps = (dispatch, ownProps: IEditUserProps) => ({
  adminGetUser: () =>
    dispatch(
      adminGetUser(undefined, { userUserId: ownProps.match.params.uuid }),
    ),
  updateUser: (payload: UpdateUserPayload) =>
    dispatch(updateUser(payload, { userUserId: payload.userUserId })),
  getUser: () => dispatch(getUser()),
});

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

class EditUser extends Component<IEditUserProps, IEditUserState> {
  public state: IEditUserState = {
    email: {
      value: '',
      clean: true,
    },
    password: {
      value: '',
      clean: true,
      errorMessage: '',
    },
    firstName: {
      value: '',
      clean: true,
    },
    lastName: {
      value: '',
      clean: true,
    },
    companyName: {
      value: '',
      clean: true,
    },
    username: {
      value: '',
      clean: true,
    },
    role: {
      value: '',
      clean: true,
    },
    confirm_password: {
      value: '',
      clean: true,
    },
  };

  public componentDidMount() {
    if (this.props.userDetails.role !== UserRole.PUBLISHER) {
      this.props.adminGetUser();
    } else {
      if (this.props.userDetails.uuid !== this.userUserId) {
        this.props.history.push(routes.PAGE_NOT_FOUND);
      } else {
        this.setUserDetails();
      }
    }
  }

  public setUserDetails() {
    const {
      email,
      firstName,
      lastName,
      companyName,
      username,
      role,
    } = this.props.userDetails;
    this.setState({
      email: {
        value: email,
        clean: true,
      },
      password: {
        value: '',
        clean: true,
      },
      firstName: {
        value: firstName,
        clean: true,
      },
      lastName: {
        value: lastName,
        clean: true,
      },
      companyName: {
        value: companyName,
        clean: true,
      },
      username: {
        value: username,
        clean: true,
      },
      role: {
        value: role,
        clean: true,
      },
    });
  }
  public componentDidUpdate(prevProps: IEditUserProps) {
    if (
      prevProps.submitting &&
      !this.props.submitting &&
      !this.props.submissionError
    ) {
      this.props.getUser();
      this.props.history.push(
        (this.props.location.state
          ? this.props.location.state.referrer
          : false) ||
          (this.props.userDetails.role === UserRole.PUBLISHER
            ? routes.HOME
            : routes.ADMIN_ALL_USERS),
      );
    } else if (prevProps.loading && !this.props.loading && !this.props.error) {
      const {
        email,
        firstName,
        lastName,
        companyName,
        username,
        role,
      } = this.props.userInfo;
      this.setState({
        email: {
          value: email,
          clean: true,
        },
        password: {
          value: '',
          clean: true,
        },
        confirm_password: {
          value: '',
          clean: true,
        },
        firstName: {
          value: firstName,
          clean: true,
        },
        lastName: {
          value: lastName,
          clean: true,
        },
        companyName: {
          value: companyName,
          clean: true,
        },
        username: {
          value: username,
          clean: true,
        },
        role: {
          value: role,
          clean: true,
        },
      });
    }
  }

  private get userUserId(): string {
    return this.props.match.params.uuid;
  }

  private handleChange = (field: keyof IEditUserState, { target }) => {
    this.setState((state) => {
      state[field].value = target.value;
      state[field].clean = false;
      return {
        ...state,
      };
    });
  };

  private isValid = (): boolean => {
    const { email } = this.state;
    const isEmailValid = email.value && EMAIL_REGEX.test(email.value);
    return isEmailValid;
  };

  private isChanged = (): boolean => {
    return Object.values(this.state).some(
      (fieldState: FieldState) => !fieldState.clean,
    );
  };

  private handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      //TODO: Need to do it in eligent way
      throw new Error('Not Authorized');
    }
    const changedFields = Object.entries(this.state).reduce(
      (accum, [fieldName, { clean, value }]) => {
        if (!clean) {
          accum[fieldName] = value;
        }
        return accum;
      },
      {
        userUserId: this.userUserId,
        token: token,
      },
    );
    this.props.updateUser(changedFields);
  };

  private renderError = () => {
    const { submissionError } = this.props;
    let rv;
    if (submissionError) {
      rv = <Alert variant="danger">{submissionError}</Alert>;
    }

    return rv;
  };

  public render() {
    const {
      email,
      password,
      username,
      firstName,
      lastName,
      companyName,
      role,
      confirm_password,
    } = this.state;
    const isFormValid = this.isValid();
    const isFormChanged = this.isChanged();
    const { loading, userInfo, adminUserId, userDetails } = this.props;
    const canSubmitForm = isFormValid && isFormChanged && !loading;
    return userInfo && !loading ? (
      <Row>
        <Helmet>
          <meta name="title" content="Sign Up | Nvzn Augmented Reality" />
          <meta
            name="description"
            content="Sign Up to Nvzn Augmented Reality to get a never before experience in AR. Publish your models and share your fascinating experience with others too."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Col>
          <Card className="login">
            <Card.Header as="h5">Edit User Details</Card.Header>
            <Card.Body>
              {this.renderError()}
              <Form>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    onChange={(e) => this.handleChange('email', e)}
                    value={email.value}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Change password"
                    onChange={(e) => this.handleChange('password', e)}
                    value={password.value}
                  />
                </Form.Group>
                <Form.Group controlId="confirm_password">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    disabled={!!!password.value}
                    type="password"
                    onChange={(e) => this.handleChange('confirm_password', e)}
                    value={confirm_password.value}
                  />
                </Form.Group>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => this.handleChange('username', e)}
                    value={username.value}
                    required
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First"
                        onChange={(e) => this.handleChange('firstName', e)}
                        value={firstName.value}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last"
                        onChange={(e) => this.handleChange('lastName', e)}
                        value={lastName.value}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="companyName">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    disabled={userDetails.role === UserRole.PUBLISHER}
                    type="text"
                    placeholder="Company (Optional)"
                    onChange={(e) => this.handleChange('companyName', e)}
                    value={companyName.value}
                  />
                </Form.Group>
                {userDetails.role !== UserRole.PUBLISHER && (
                  <fieldset>
                    <Form.Group>
                      <Form.Label>User Role</Form.Label>
                      <Col sm={12}>
                        <Form.Check
                          disabled={userInfo.uuid === adminUserId}
                          inline
                          type="radio"
                          label="Publisher"
                          checked={role.value === 'publisher'}
                          onChange={(e) => this.handleChange('role', e)}
                          value="publisher"
                        />
                        <Form.Check
                          disabled={userInfo.uuid === adminUserId}
                          inline
                          type="radio"
                          label="Admin"
                          checked={role.value === 'admin'}
                          onChange={(e) => this.handleChange('role', e)}
                          value="admin"
                        />
                        <Form.Check
                          disabled={userInfo.uuid === adminUserId}
                          inline
                          type="radio"
                          label="Super Admin"
                          checked={role.value === 'superadmin'}
                          onChange={(e) => this.handleChange('role', e)}
                          value="superadmin"
                        />
                      </Col>
                    </Form.Group>
                  </fieldset>
                )}
                <Button
                  variant="primary"
                  disabled={!canSubmitForm}
                  onClick={canSubmitForm ? this.handleSubmit : undefined}
                  className="mr-4 submit-edit-user"
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.props.history.push(
                      (this.props.location.state
                        ? this.props.location.state.referrer
                        : false) ||
                        (this.props.userDetails.role === UserRole.PUBLISHER
                          ? routes.HOME
                          : routes.ADMIN_ALL_USERS),
                    )
                  }
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    ) : (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        className="mr-2"
        role="status"
        aria-hidden="true"
      />
    );
  }
}

const EditUserConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUser);

export { EditUserConnected as EditUser };
