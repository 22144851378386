import React, { useState, useEffect, useCallback } from 'react';
import { AssetMetadata } from 'nvzn-models';
import Moment from 'moment';
import {
  Spinner,
  Button,
  Row,
  Col,
  Container,
  ColProps,
} from 'react-bootstrap';
import classNames from 'classnames';
import { config } from '../../util';
import noThumbnail from '../../assets/no-thumbnail.jpg';

interface ModelListingProps {
  assetData: AssetMetadata;
  onDelete(): void;
  onReassign?(): void;
  onView(): void;
  onEdit(): void;
  onUse(): void;
  onDuplicate?(): void;
  showUsername?: boolean;
  showReassign?: boolean;
}

export function ModelListing({
  assetData,
  onDelete,
  onReassign,
  onDuplicate,
  onView,
  onEdit,
  onUse,
  showUsername,
  showReassign,
}: ModelListingProps) {
  const {
    uuid,
    uploadDate,
    lastEditedDate,
    name,
    isUploadComplete,
    username,
  } = assetData;

  const generateImageLink = useCallback(() => {
    return `${config.cdnUrl}/${uuid}/thumbnail.png?v=${
      lastEditedDate - uploadDate
    }`;
  }, [lastEditedDate, uploadDate, uuid]);

  const [imgSrc, setImgSrc] = useState(generateImageLink());

  useEffect(() => {
    if (!isUploadComplete) setImgSrc(noThumbnail);
    else setImgSrc(generateImageLink());
  }, [generateImageLink, isUploadComplete]);

  function renderImage() {
    return (
      <img
        style={{ height: '100px' }}
        src={imgSrc}
        onError={() => setImgSrc(noThumbnail)}
        alt="No Thumbnail"
      />
    );
  }
  function formatDate(unix: number): string {
    return Moment(unix).format('M/D/YYYY h:mm A');
  }

  function renderReassignButton() {
    let rv;
    if (showReassign) {
      rv = (
        <Col>
          <Button
            className="searchable-table-row__asset-action searchable-table-row__edit-model"
            onClick={onReassign}
            title={'Reassign'}
          >
            <span className="material-icons">manage_accounts</span>
          </Button>
        </Col>
      );
    }
    return rv;
  }

  function renderDuplicateButton() {
    let rv;
    if (showReassign) {
      rv = (
        <Col>
          <Button
            className="searchable-table-row__asset-action searchable-table-row__edit-model"
            onClick={onDuplicate}
            title="Duplicate"
          >
            <span className="material-icons">add_to_photos</span>
          </Button>
        </Col>
      );
    }
    return rv;
  }

  function renderProcessingModelOptions() {
    let spinnerColSpan: ColProps['xs'] = '8';
    let cancelColSpan: ColProps['xs'] = '4';
    if (showReassign) {
      spinnerColSpan = '9';
      cancelColSpan = '3';
    }
    return (
      <td className="pl-0 pr-0">
        <Container
          className={classNames(
            'searchable-table-row__asset-actions searchable-table-row__asset-actions--processing',
            {
              'searchable-table-row__asset-actions--extended': showReassign,
            },
          )}
        >
          <Row noGutters>
            <Col xs={spinnerColSpan} className="d-flex align-items-center">
              <Spinner
                animation="border"
                className="searchable-table-row__asset-action searchable-table-row__asset-processing-spinner mr-2"
                as="span"
              />
              <div>Processing Files</div>
            </Col>
            <Col xs={cancelColSpan}>
              <Button
                className="searchable-table-row__asset-action searchable-table-row__delete-model"
                variant="danger"
                onClick={onDelete}
                size="sm"
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </td>
    );
  }

  function renderProcessedModelOptions() {
    return (
      <td className="pl-0 pr-0">
        <Container
          className={classNames(
            'searchable-table-row__asset-actions searchable-table-row__asset-actions--processed',
            {
              'searchable-table-row__asset-actions--extended': showReassign,
            },
          )}
        >
          <Row noGutters>
            <Col className="pr-1">
              <Button
                className="searchable-table-row__asset-action searchable-table-row__use-model"
                onClick={onUse}
                title="Integrate"
              >
                <span className="material-icons">insert_link</span>
              </Button>
            </Col>
            <Col className="pr-1">
              <Button
                className="searchable-table-row__asset-action searchable-table-row__edit-model"
                onClick={onEdit}
              >
                <span className="material-icons" title="Edit">
                  tune
                </span>
              </Button>
            </Col>
            {renderReassignButton()}
            {renderDuplicateButton()}
            <Col className="pl-1">
              <Button
                className="searchable-table-row__asset-action searchable-table-row__delete-model"
                variant="danger"
                onClick={onDelete}
                title="Delete"
              >
                <span className="material-icons">delete</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </td>
    );
  }

  function renderOptions() {
    const renderFunc = isUploadComplete
      ? renderProcessedModelOptions
      : renderProcessingModelOptions;
    return renderFunc();
  }

  function renderUsername() {
    let usernameCell;
    if (showUsername) {
      usernameCell = (
        <td className="searchable-table-row__username">{username}</td>
      );
    }
    return usernameCell;
  }

  return (
    <tr key={uuid} id={uuid} className="searchable-table-row">
      <td>{renderImage()}</td>
      <td className="searchable-table-row__asset-name">
        <Button variant="link" onClick={onView} className="link-button">
          {name}
        </Button>
      </td>
      {renderUsername()}
      <td className="searchable-table-row__upload-date text-nowrap">
        {formatDate(uploadDate)}
      </td>
      <td className="searchable-table-row__edited-date text-nowrap">
        {formatDate(lastEditedDate)}
      </td>
      {renderOptions()}
    </tr>
  );
}
