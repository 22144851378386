import React, { Component } from 'react';
import { Button, Spinner, Modal, Form, Row, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AssetData, AssetReassignPayload, UserInfo } from 'nvzn-models';
import { ModalProps } from './BaseModal';
import { State } from '../../state/reducer';
import { getAsset, getAllUsers } from '../../state/actions';

interface ReassignModalProps extends ModalProps {
  uuid: string;
  onConfirmReassign(payload: AssetReassignPayload): void;
  getAllUsers(): void;
  fetchAssetData(uuid: string): void;
  users: UserInfo[];
  asset: AssetData;
  loading: boolean;
  submitting: boolean;
}

interface ReassignModalState {
  newUserId: string;
}

const mapStateToProps = (state: State) => ({
  asset: state.GET_ASSET.data,
  users: state.GET_ALL_USERS.data,
  loading: state.GET_ALL_USERS.loading || state.GET_ASSET.loading,
  submitting: state.ADMIN_REASSIGN_MODEL.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
  getAllUsers: () => dispatch(getAllUsers()),
});

class ReassignModal extends Component<ReassignModalProps, ReassignModalState> {
  public state = {
    newUserId: undefined,
  };

  public componentDidUpdate(prevProps) {
    const { getAllUsers, fetchAssetData, uuid, asset } = this.props;
    if (uuid && uuid !== prevProps.uuid) {
      getAllUsers();
      fetchAssetData(uuid);
    }
    if (asset && asset !== prevProps.asset) {
      this.setState({
        newUserId: asset.userId,
      });
    }
  }
  private handleSelectUser = (event): void => {
    const newUserId = event.target.value;
    this.setState({ newUserId });
  };
  private handleConfirmReassign = (): void => {
    const { newUserId } = this.state;
    const { asset, onConfirmReassign } = this.props;
    onConfirmReassign({
      uuid: asset.uuid,
      newUserId,
    });
  };

  private renderUsersList() {
    const { loading, users, asset } = this.props;
    let rv;
    if (users && !loading) {
      //TODO: Sort it at api side
      const options = users
        .sort((a, b) => {
          return a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1;
        })
        .map((userInfo) => (
          <option key={userInfo.uuid} value={userInfo.uuid}>
            {userInfo.username}
          </option>
        ));
      rv = (
        <Form.Group controlId="model-reassign-dropdown">
          <Form.Label>
            Chose a new owner for <b>{asset?.config?.name}</b>
          </Form.Label>
          <Form.Control
            as="select"
            className="model-reassign-dropdown"
            name="Select User"
            onChange={this.handleSelectUser}
            value={this.state.newUserId}
          >
            {options}
          </Form.Control>
        </Form.Group>
      );
    }
    return rv;
  }

  public render() {
    const { show, onClose, submitting, asset } = this.props;
    const { newUserId } = this.state;
    const canSubmit = newUserId && newUserId !== asset.userId;
    return (
      <Modal show={show} onHide={onClose} className="reassign-model-modal">
        <Modal.Body>
          <Container>
            <Row className="d-flex flex-column align-content-left">
              <h3>Reassign Model</h3>
              {this.renderUsersList()}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!canSubmit}
            className="confirm-reassign-modal__confirm-button"
            onClick={this.handleConfirmReassign}
          >
            {submitting && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="mr-2"
                role="status"
                aria-hidden="true"
              />
            )}
            <span>Reassign</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const ConnectReassignModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReassignModal);
