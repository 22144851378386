import React from 'react';
import classNames from 'classnames';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Button, Table, Container } from 'react-bootstrap';
import { SearchableContent } from './SearchableContent';
import { SortTypeHeaders } from './types';

interface SearchableTableProps<SortTypeEnum extends string> {
  sortableTableHeaders: SortTypeHeaders<SortTypeEnum>;
}

export class SearchableTable<
  SortTypeEnum extends string,
  ItemType
> extends SearchableContent<
  SortTypeEnum,
  ItemType,
  SearchableTableProps<SortTypeEnum>
> {
  public constructor(props) {
    super(props);
    const { sortableTableHeaders, initialSort, intialSeed } = props;
    const [{ descSortType }] = Object.values(sortableTableHeaders);
    this.state = {
      searchText: '',
      sortBy: initialSort ?? descSortType,
      seed: intialSeed || Math.random(),
      pageNumber: 0,
    };
  }

  private renderHeaders() {
    const { sortableTableHeaders } = this.props;
    const { sortBy } = this.state;
    return Object.entries(sortableTableHeaders).map(
      ([headerLabel, { ascSortType, descSortType }]) => {
        const hasContent = !!headerLabel;
        let headerContent;
        if (hasContent) {
          const isAscSort = sortBy === ascSortType;
          const isDescSort = sortBy === descSortType;
          const isActive = isAscSort || isDescSort;
          let SortIcon = FaArrowDown;
          let onClick = () => this.setSort(descSortType);
          if (isAscSort) {
            SortIcon = FaArrowUp;
            onClick = () => this.setSort(descSortType);
          } else if (isDescSort) {
            onClick = () => this.setSort(ascSortType);
          }
          headerContent = (
            <Button
              variant="link"
              className={classNames(
                'searchable-table__header',
                'p-3',
                'text-dark',
                {
                  'font-weight-bold': isActive,
                  'searchable-table__header--active': isActive,
                },
              )}
              onClick={onClick}
              block
            >
              <div className="d-flex align-items-center">
                <div className="mr-2">{headerLabel}</div>
                <SortIcon className="searchable-table__header-sort" />
              </div>
            </Button>
          );
        }
        return (
          <th key={headerLabel} className="p-0">
            {headerContent}
          </th>
        );
      },
    );
  }

  private renderTable() {
    let content = this.renderItems();
    if (content.length !== undefined && typeof content === 'object') {
      content = (
        <Table striped bordered hover className="searchable-table__table">
          <thead>
            <tr>
              {this.props.isModelList && <td></td>}
              {this.renderHeaders()}
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </Table>
      );
    }
    return content;
  }

  public render() {
    return (
      <Container className="searchable-content searchable-table">
        {this.renderHeader()}
        <div className="searchable-content__items">{this.renderTable()}</div>
        {this.renderPaginator()}
      </Container>
    );
  }
}
