import React, { Component } from 'react';
import { UserInfo } from 'nvzn-models';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Routes } from './pages';
import { verifyToken, getUser } from './state/actions';
import { State } from './state/reducer';
import { routes } from './util';

interface IAppProps extends RouteComponentProps {
  verifyToken(): void;
  getUser(): void;
  isLoggedIn: boolean;
  userInfo: UserInfo;
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
  userInfo: state.GET_USER.data,
});

const mapDispatchToProps = (dispatch) => ({
  verifyToken: () =>
    dispatch(verifyToken({ token: localStorage.getItem('token') || '' })),
  getUser: () => dispatch(getUser()),
});

class AppComponent extends Component<IAppProps> {
  public componentDidMount() {
    this.props.verifyToken();
  }

  public componentDidUpdate(prevProps: IAppProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.getUser();
    } else if (prevProps.isLoggedIn && !this.props.isLoggedIn) {
      this.props.history.replace(routes.HOME);
    }
  }

  public render() {
    return (
      <div className="app">
        <Routes />
      </div>
    );
  }
}

export const App = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppComponent));
