import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import { BaseModal } from './BaseModal';
import { config } from '../../util';

interface ViewInARModalProps {
  show: boolean;
  uuid: string;
  onClose: () => void;
}

export function ViewInARModal({ uuid, onClose, show }: ViewInARModalProps) {
  function renderContent({ onCopy }) {
    const shareLink = `${config.frontEndUrl}/models/view/${uuid}`;
    return (
      <Fragment>
        <h6>
          To view in Aumented Reality, scan this QR Code on your mobile device.
        </h6>
        <span className="d-flex mb-4">
          <code className="flex-grow-1 border bg-light rounded p-2 text-center">
            <img
              src={`${config.backEndUrl}/assets/qr/${uuid}`}
              width={250}
              height={250}
              alt="QR Code"
              className="border"
            />
          </code>
        </span>
        <h6>Or copy this link</h6>
        <span className="d-flex mb-2 view-in-ar-modal__share-link">
          <code className="flex-grow-1 border bg-light rounded p-2 mr-4 share-model-link">
            {shareLink}
          </code>
          <Button onClick={() => onCopy(shareLink)}>
            <FaRegCopy size="24px" />
          </Button>
        </span>
      </Fragment>
    );
  }

  return (
    <BaseModal
      show={show}
      onClose={onClose}
      className="view-in-ar-modal"
      header="View in your space"
      renderContent={renderContent}
    />
  );
}
