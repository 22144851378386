interface IConfig {
  maxFileSize: {
    glb: number;
    usdz: number;
    environmentalImage: number;
    backgroundImage: number;
  };
  validFileTypes: {
    glb: string[];
    usdz: string[];
    environmentalImage: string[];
    backgroundImage: string[];
  };
  frontEndUrl: string;
  backEndUrl: string;
  cdnUrl: string;
  ftpUrl: string;
  dataStudioURL: string;
}

export enum Environment {
  development = 'development',
  preproduction = 'preproduction',
  production = 'production',
}

export type IEnvConfigs = {
  [env in Environment]: Partial<IConfig>;
} & {
  shared: Partial<IConfig>;
};

const MB = 1024 * 1024;

const configs: IEnvConfigs = {
  shared: {
    maxFileSize: {
      glb: MB * 20, // 10 MB
      usdz: MB * 20, // 10 MB
      environmentalImage: MB * 20, // 10 MB
      backgroundImage: MB * 20, // 10 MB
    },
    validFileTypes: {
      glb: ['glb'],
      usdz: ['usdz'],
      //TODO: Reconfigure nvzn-api for renaming octet-stream to hdr
      environmentalImage: ['hdr', 'png', 'jpg', 'jpeg', 'octet-stream'],
      backgroundImage: ['hdr', 'png', 'jpg', 'jpeg', 'octet-stream'],
    },
  },
  [Environment.development]: {
    frontEndUrl: 'http://localhost:3000',
    backEndUrl: 'https://api-pp.nvzn.io',
    cdnUrl: 'https://cdn-pp.nvzn.io',
    ftpUrl: 'ftp://ftp.pp.nvzn.io',
    dataStudioURL:
      'https://datastudio.google.com/embed/reporting/adac50a8-03ec-4ccd-b405-6d4008f3a369/page/dAkfC',
  },
  [Environment.preproduction]: {
    frontEndUrl: 'https://pp.nvzn.io',
    backEndUrl: 'https://api-pp.nvzn.io',
    cdnUrl: 'https://cdn-pp.nvzn.io',
    ftpUrl: 'ftp://ftp.pp.nvzn.io',
    dataStudioURL:
      'https://datastudio.google.com/embed/reporting/adac50a8-03ec-4ccd-b405-6d4008f3a369/page/dAkfC',
  },
  [Environment.production]: {
    frontEndUrl: 'https://nvzn.io',
    backEndUrl: 'https://api.nvzn.io/',
    cdnUrl: 'https://cdn.nvzn.io',
    ftpUrl: 'ftp://ftp.nvzn.io',
    dataStudioURL:
      'https://datastudio.google.com/embed/reporting/adac50a8-03ec-4ccd-b405-6d4008f3a369/page/dAkfC',
  },
};

const config: IConfig = {
  ...configs.shared,
  ...configs[process.env.REACT_APP_BUILD_VARIANT || Environment.development],
};

if (process.env.REACT_APP_USE_LOCAL_BE === 'true') {
  config.backEndUrl = 'http://localhost:3001';
}

export const environment =
  process.env.REACT_APP_BUILD_VARIANT || Environment.development;

export { config };
