import classNames from 'classnames';
//import { data } from 'cypress/types/jquery';
import React, { Component, createRef, RefObject } from 'react';
import { Form, Modal, Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Logo,
  Cube,
  FullScreen,
  ExtFullScreen,
  CC,
  IconHand,
  ProfileImagePlaceholder,
} from '../../assets';
import { PageLoader, ViewInARModal, ModelViewerHelmet } from '../../components';
import { AssetDataModel } from '../../models';
import { getAsset, getUser, fetchBannerConfig } from '../../state/actions';
import { State } from '../../state/reducer';
import { config as appConfig, PageName, routes } from '../../util';

const creativeCommonsIcons = {
  [`Attribution CC BY`]: {
    img: CC.BY,
    src: 'https://creativecommons.org/licenses/by/4.0/',
  },
  [`Attribution-ShareAlike CC BY-SA`]: {
    img: CC.BY_SA,
    src: 'https://creativecommons.org/licenses/by-sa/4.0/',
  },
  [`Attribution-NoDerivs CC BY-ND`]: {
    img: CC.BY_ND,
    src: 'https://creativecommons.org/licenses/by-nd/4.0/',
  },
  [`Attribution-NonCommercial CC BY-NC`]: {
    img: CC.BY_NC,
    src: 'https://creativecommons.org/licenses/by-nc/4.0/',
  },
  [`Attribution-NonCommercial-ShareAlike CC BY-NC-SA`]: {
    img: CC.BY_NC_SA,
    src: 'https://creativecommons.org/licenses/by-nc-sa/4.0/',
  },
  [`Attribution-NonCommercial-NoDerivs CC BY-NC-ND`]: {
    img: CC.BY_NC_ND,
    src: 'https://creativecommons.org/licenses/by-nc-nd/4.0/',
  },
};

interface bannerData {
  desc: string;
  title: string;
  icon: string;
}

interface bannerConfigData {
  addToBasket: bannerData;
  addToCart: bannerData;
  addToFoodOrder: bannerData;
  addToWishList: bannerData;
  buyNow: bannerData;
}

interface IModelViewerProps extends RouteChildrenProps {
  asset: AssetDataModel;
  loading: boolean;
  bannerConfig: bannerConfigData;
  fetchAssetData(uuid: string): void;
  fetchBannerData(): void;
}

enum ModalName {
  SHARE = 'SHARE',
  PROFILE = 'PROFILE',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO',
  LEGAL = 'LEGAL',
  VIEW_IN_AR = 'VIEW_IN_AR',
  ARERROR = 'ARERROR',
}

interface IModelViewerState {
  openModal: ModalName;
  isFullScreen: boolean;
  isMobile: boolean;
  loadProgress: number;
  arStatus: string;
}

const mapStateToProps = (state: State) => ({
  asset: state.GET_ASSET.data,
  loading: state.GET_ASSET.loading,
  userInfo: state.GET_USER.data,
  bannerConfig: state.GET_BANNER_CONFIG.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
  fetchBannerData: () => dispatch(fetchBannerConfig()),
  getUser: () => dispatch(getUser()),
});

class PublicModelViewer extends Component<
  IModelViewerProps,
  IModelViewerState
> {
  public state = {
    openModal: undefined,
    isFullScreen: false,
    isMobile: false,
    loadProgress: 0,
    bannerConfig: {},
    arStatus: '0',
  };
  private readonly modelViewerWrapper: RefObject<HTMLDivElement>;
  private isWebView: Boolean;
  private modelViewer: HTMLElement;
  private arButton: RefObject<HTMLButtonElement>;

  public constructor(props) {
    super(props);
    this.arButton = createRef<HTMLButtonElement>();
    this.modelViewerWrapper = createRef<HTMLDivElement>();
    this.isWebView = false;
  }

  public componentDidMount() {
    this.props.fetchAssetData(this.getUuid());
    this.props.fetchBannerData();
    document.onfullscreenchange = () => {
      this.setState({
        isFullScreen: !!document.fullscreenElement,
      });
    };
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) ||
      (navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2)
    ) {
      this.setState({ isMobile: true });
    }
    const standalone = window.navigator['standalone'];
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = userAgent.includes('safari');
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios && !standalone && !safari) {
      this.isWebView = true;
    }
  }

  private onARActivate = () => {
    if (this.isWebView)
      window.location.href = `${appConfig.ftpUrl}/index.html?location=${window.location.href}`;
    else {
      const arButton = this.modelViewer.shadowRoot.querySelector(
        '#default-ar-button',
      ) as HTMLElement;
      arButton.click();
    }
  };
  private onARStatusChange = (e) => {
    if (e.detail.status === 'failed') {
      this.handleOpenModal(ModalName.ARERROR);
    }
  };

  public componentWillUnmount() {
    document.onfullscreenchange = undefined;
  }

  private getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
  }

  private getUuid = (): string => {
    const { uuid } = this.props.match.params as { uuid: string };
    return uuid;
  };

  private handleToggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      this.modelViewerWrapper.current.requestFullscreen();
    }
  };
  private handleOpenModal = (openModal: ModalName) => {
    this.setState({ openModal });
  };

  private handleCloseModal = () => {
    this.setState({ openModal: undefined });
  };

  private handleRenderModelViewer = (modelViewer: HTMLElement) => {
    if (this.modelViewer && modelViewer) {
      this.modelViewer.removeEventListener('progress', this.onProgress);
    }
    if (modelViewer) {
      this.modelViewer = modelViewer;
      this.modelViewer.addEventListener('progress', this.onProgress);
      modelViewer.addEventListener('ar-status', this.onARStatusChange);
    }
  };

  //private exitWebXR = (modelViewer: HTMLElement) => {};

  private onProgress = (e) => {
    this.setState({ loadProgress: e.detail.totalProgress });
  };

  private renderModelViewer() {
    const { asset, bannerConfig } = this.props;
    const { isFullScreen, loadProgress } = this.state;
    let ctaHTML;
    let usdzPath;
    // Checks if the ctaBannerData config is empty
    const hasCtaBannerData =
      !!Object.keys(asset.ctaBannerData ?? {}).length &&
      asset.ctaBannerData.ctaSwitch;

    if (hasCtaBannerData) {
      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      };

      if (this.modelViewer) {
        this.modelViewer.addEventListener(
          'quick-look-button-tapped',
          (event) => {
            openInNewTab(asset.ctaBannerData.ctaUrl);
          },
        );
      }

      // path to iOS QuickLook banner
      const customBannerURL = `${appConfig.frontEndUrl}/cta-banner/index.html`;
      const type = asset.ctaBannerData.ctaType;
      const bannerConfigObj = bannerConfig && bannerConfig[0];

      if (bannerConfigObj) {
        /*
          we receive icon like 'fas fa-shopping-bag'
          we need final string like  'shopping-bag'
        */
        const data = {
          title: bannerConfigObj[type].title
            ? bannerConfigObj[type].title
            : '-',
          body: bannerConfigObj[type].desc ? bannerConfigObj[type].desc : '-',
          icon: bannerConfigObj[type].icon ? bannerConfigObj[type].icon : '-',
          iconfa: bannerConfigObj[type].icon
            ? bannerConfigObj[type].icon
                .replaceAll('fas', '')
                .replaceAll(' fa-', '')
            : '-',
        };

        let quickLookParams = JSON.stringify(data);
        quickLookParams = encodeURIComponent(quickLookParams);
        quickLookParams = customBannerURL + '?data=' + quickLookParams;
        quickLookParams = encodeURIComponent(quickLookParams);
        quickLookParams = 'customHeight=small&custom=' + quickLookParams;
        if (asset.usdzSrc) {
          usdzPath = new URL(asset.usdzSrc);
          usdzPath.hash = quickLookParams;
        }

        ctaHTML = (
          <div id="cta-banner-container" className="cta-banner-container hide">
            <div
              className="cta-banner"
              onClick={() => {
                //Exit WebXR session - Will do nothing if no WebXR session is active
                const exitButton = this.modelViewer.shadowRoot.querySelector(
                  '#default-exit-webxr-ar-button',
                ) as HTMLElement;
                exitButton.click();
                //Open url in new window
                openInNewTab(asset.ctaBannerData.ctaUrl);
              }}
            >
              <div className="cta-banner-labels">
                <h3 id="cta-banner-title">{data.title}</h3>
                <p id="cta-banner-description">{data.body}</p>
              </div>
              <button
                id="cta-banner-button"
                className="cta-banner-button"
                onClick={(event) => {
                  //Exit WebXR session - Will do nothing if no WebXR session is active
                  const exitButton = this.modelViewer.shadowRoot.querySelector(
                    '#default-exit-webxr-ar-button',
                  ) as HTMLElement;
                  exitButton.click();
                  //Open url in new window
                  openInNewTab(asset.ctaBannerData.ctaUrl);
                }}
                style={{ fontSize: '40' }}
              >
                <FontAwesomeIcon icon={data.iconfa} />
              </button>
            </div>
          </div>
        );
      }
    }

    let _ar_modes = 'webxr scene-viewer quick-look';
    var ua = navigator.userAgent;
    var chromeVersion = this.getChromeVersion();
    //var androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8));
    if (ua.includes('Android')) {
      if (chromeVersion >= 88) {
        _ar_modes = 'webxr scene-viewer quick-look';
      }
    }

    return (
      <model-viewer
        id="model-viewer"
        class={classNames('model-viewer__model', {
          'model-viewer__model--fullscreen': isFullScreen,
          'model-viewer__loaded': loadProgress === 1,
        })}
        style={{
          backgroundColor: asset.backgroundColor,
          '--poster-color': 'transparent',
        }}
        src={asset.glbSrc}
        ios-src={hasCtaBannerData ? usdzPath : asset.usdzSrc}
        auto-rotate={asset.autorotate ? asset.autorotate : null}
        camera-controls={true}
        alt={asset.name}
        autoplay={asset.autoplay}
        camera-target={asset.cameraTarget}
        skybox-image={asset.backgroundImageSrc}
        environment-image={asset.environmentalImageSrc}
        shadow-intensity={asset.shadowIntensity}
        shadow-softness={asset.shadowSoftness}
        camera-orbit={asset.cameraOrbit}
        min-camera-orbit={asset.minCameraOrbit}
        max-camera-orbit={asset.maxCameraOrbit}
        field-of-view={asset.fov}
        exposure={asset.exposure}
        ar
        ar-modes={_ar_modes}
        poster={asset.posterImageSrc}
        interaction-prompt="auto"
        ar-status="not-presenting"
        ar-scale={asset.arScale}
        ar-placement={asset.arPlacement}
        ref={this.handleRenderModelViewer}
      >
        <div slot="interaction-prompt" className="interaction-prompt-container">
          <img src={IconHand} alt="Prompt" width={60} height={60} />
        </div>
        <div className="progress-bar-container" slot="progress-bar">
          {this.renderProgressBar()}
        </div>
        <div slot="ar-button" className="ar-button-container">
          <button onClick={this.onARActivate} className="ar-button">
            <div className="model-viewer__view-in-ar-text">
              View in your space
            </div>
            <img
              src={Cube}
              alt="view in augmented reality"
              className="model-viewer__view-in-ar-icon"
            />
          </button>
        </div>

        {ctaHTML}

        {this.renderToggleFullScreen()}
      </model-viewer>
    );
  }

  private renderProgressBar() {
    const { loadProgress } = this.state;
    let progressBar;
    if (loadProgress < 1) {
      progressBar = (
        <div className="progress-bar">
          <div
            className="update-bar"
            style={{
              transform: `scaleX(${loadProgress})`,
            }}
          ></div>
        </div>
      );
    }
    return progressBar;
  }

  private renderToggleFullScreen() {
    const { isFullScreen, isMobile } = this.state;
    let rv;
    if (document.fullscreenEnabled && !isMobile) {
      rv = (
        <div className="tooltip-container">
          <img
            className="model-viewer__full-screen-button"
            onClick={this.handleToggleFullScreen}
            src={isFullScreen ? ExtFullScreen : FullScreen}
            alt="view in full screen"
          />
          <span id="tooltiptext" className="tooltiptext">
            {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
          </span>
        </div>
      );
    }

    return rv;
  }

  private renderViewInArButton() {
    const { isMobile, isFullScreen } = this.state;
    if (!isMobile && !isFullScreen) {
      return (
        <Button
          variant="light"
          className="model-viewer__view-in-ar"
          onClick={() => this.handleOpenModal(ModalName.VIEW_IN_AR)}
        >
          <div className="model-viewer__view-in-ar-text">
            View in Your Space
          </div>
          <img
            src={Cube}
            className="model-viewer__view-in-ar-icon"
            alt="view in augmented reality"
          />
        </Button>
      );
    }
  }

  private renderModelViewerWrapper() {
    const { asset, loading } = this.props;
    let rv = <PageLoader />;
    if (asset && !loading) {
      rv = (
        <div className="model-viewer__model" ref={this.modelViewerWrapper}>
          {this.renderModelViewer()}
          {this.renderViewInArButton()}
          {this.renderCloseButton()}
        </div>
      );
    }
    //TODO: In future requirements, this has to be removed as it may cause infinite redirections +
    //probably would have to use reverse proxy or server side redirection instead of this

    if (asset && asset.playerSrc) {
      //Using window.location as source could be outside react app
      window.location.replace(asset.playerSrc);
    } else return rv;
  }

  private renderInfo() {
    const { asset, loading } = this.props;
    let rv = null;
    //hasTag to identifiy session for tagging location for SSR
    const url = window.location.href;
    const hasTag = url.includes('/view/tag');
    const posterImg = `${appConfig.cdnUrl}/${
      asset.uuid
    }/preview.png?v=${Date.now()}`;

    if (hasTag || (asset?.config && !loading)) {
      const {
        config: {
          name,
          desc,
          showSharing,
          authorName,
          additionalInfo,
          linkOut,
          nvznPortal,
          modelInfo,
        },
      } = !hasTag
        ? asset
        : {
            config: {
              name: '{tag_name}',
              desc: '{tag_desc}',
              showSharing: false,
              authorName: '{tag_authorName}',
              additionalInfo: null,
              linkOut: null,
              nvznPortal: null,
              modelInfo: {
                authorWork: true,
                licenseType: '',
                modelNames: 'tag_modelNames',
              },
            },
          };
      rv = (
        <Card
          className="model-viewer__info"
          style={{ height: `calc(var(--vh, 1vh) * 15)`, border: 'none' }}
        >
          <Card.Body>
            <div className="renderinfo">
              <div className="row">
                <div className="col-lg-12">
                  <div className="renderinfo-title">
                    <Card.Title>{name}</Card.Title>
                    <div>
                      <Card.Text>{desc}</Card.Text>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="col-lg-6 col-md-6">
                    <div className="renderinfo-logo">
                      {nvznPortal && (
                        <a
                          href={'http://www.nvzn.net/'}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#000000', cursor: 'pointer' }}
                        >
                          <img src={Logo} alt="" />
                        </a>
                      )}
                    </div>
                  </div>
                  <div style={{ width: 'max-content', padding: '10px' }}>
                    <div
                      className="renderinfo-title"
                      style={{ width: 'max-content', padding: 0, margin: 0 }}
                    >
                      {showSharing && (
                        <i
                          style={{
                            fontSize: 40,
                            float: 'right',
                            cursor: 'pointer',
                          }}
                          className="material-icons md-18"
                          onClick={() => this.handleOpenModal(ModalName.SHARE)}
                        >
                          share
                        </i>
                      )}
                      {authorName && (
                        <i
                          style={{
                            fontSize: 40,
                            float: 'right',
                            cursor: 'pointer',
                          }}
                          className="material-icons"
                          onClick={() =>
                            this.handleOpenModal(ModalName.PROFILE)
                          }
                        >
                          account_circle
                        </i>
                      )}
                      {additionalInfo && (
                        <i
                          style={{
                            fontSize: 40,
                            float: 'right',
                            cursor: 'pointer',
                          }}
                          className="material-icons md-18"
                          onClick={() =>
                            this.handleOpenModal(ModalName.ADDITIONAL_INFO)
                          }
                        >
                          info
                        </i>
                      )}
                      {Object.keys(modelInfo.licenseType).length > 0 && (
                        <i
                          style={{
                            fontSize: 40,
                            float: 'right',
                            cursor: 'pointer',
                          }}
                          className="material-icons md-18"
                          onClick={() => this.handleOpenModal(ModalName.LEGAL)}
                        >
                          policy
                        </i>
                      )}
                      {linkOut && (
                        <a
                          href={linkOut}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#000000' }}
                        >
                          <i
                            style={{
                              fontSize: 40,
                              float: 'right',
                              cursor: 'pointer',
                            }}
                            className="material-icons md-18"
                          >
                            link
                          </i>{' '}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Modal
                id="modalForARError"
                show={this.state.openModal === ModalName.ARERROR}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Close</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Your browser does not support AR.</p>
                </Modal.Body>
              </Modal>
            }
            {
              <Modal
                id="modalForShare"
                show={this.state.openModal === ModalName.SHARE}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Share This</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <meta property="og:image" content={posterImg} />
                  <meta property="twitter:image" content={posterImg} />
                  <div className="addthis_inline_share_toolbox"></div>
                </Modal.Body>
              </Modal>
            }
            {
              <Modal
                show={this.state.openModal === ModalName.PROFILE}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Publisher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="profile-detail">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="user-img">
                          <img src={ProfileImagePlaceholder} alt="alter" />
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="user-name">
                          <h1> {asset.username} </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {/* {additional info -------------------modal} */}
            {
              <Modal
                show={this.state.openModal === ModalName.ADDITIONAL_INFO}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{asset.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h3>Additional Info</h3>
                  <Form.Group controlId="config.additionalInfo">
                    <Form.Label>Additional Info</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="config.additionalInfo"
                      rows={10}
                      placeholder="additionalInfo"
                      value={additionalInfo}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            }

            {/* // ModalInfo ------------------------->>>> */}
            {
              <Modal
                show={this.state.openModal === ModalName.LEGAL}
                onHide={this.handleCloseModal}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Model Artwork Created by {modelInfo.modelNames}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-artwork">
                    {modelInfo.licenseType && (
                      <div>
                        <h3>Used Under Creative Common License</h3>
                        <div className="attributionlink">
                          <a
                            href={`${
                              creativeCommonsIcons[modelInfo.licenseType].src
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            {modelInfo.licenseType}{' '}
                          </a>
                        </div>
                        <div className="attributionimg">
                          <a
                            href={`${
                              creativeCommonsIcons[modelInfo.licenseType].src
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                creativeCommonsIcons[modelInfo.licenseType].img
                              }
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="attribution-detail">
                      {modelInfo.authorWork ? (
                        <p>The artwork has been altered from original</p>
                      ) : (
                        <p>The artwork has not been altered from original</p>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
          </Card.Body>
        </Card>
      );
    }
    //TODO: In future requirements, this has to be removed as it may cause infinite redirections +
    //probably would have to use reverse proxy or server side redirection instead of this

    if (asset && !asset.playerSrc) return rv;
  }

  private renderCloseButton() {
    const { history } = this.props;
    return (
      <div
        className="model-viewer__close"
        onClick={() => {
          if (history.location.state)
            history.push(history.location.state.referrer, {
              referrer: `${window.location.pathname}${window.location.search}`,
            });
          else
            history.push(routes[PageName.HOME], {
              referrer: `${window.location.pathname}${window.location.search}`,
            });
        }}
      >
        ✖
      </div>
    );
  }

  public render() {
    const { openModal } = this.state;
    const { asset, loading } = this.props;
    return (
      <div className="model-viewer">
        <ModelViewerHelmet asset={asset} loading={loading} />
        <ViewInARModal
          show={openModal === ModalName.VIEW_IN_AR}
          uuid={asset.uuid}
          onClose={this.handleCloseModal}
        />
        {this.renderModelViewerWrapper()}
        <div style={{ height: '100%', maxHeight: `calc(var(--vh, 1vh) * 15)` }}>
          {this.renderInfo()}
        </div>
      </div>
    );
  }
}

const PublicModelViewerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicModelViewer);

export { PublicModelViewerConnected as PublicModelViewer };
