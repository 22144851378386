import { AssetMetadata } from 'nvzn-models';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { RouteChildrenProps } from 'react-router';
import md5 from 'md5';
import { fetchAllPulicAssets, getUploadStatus } from '../state/actions';
import { State } from '../state/reducer';
import { ModelCard, SearchableContent } from '../components';

interface AssetsProps extends RouteChildrenProps {
  assets: AssetMetadata[];
  fetchAssets(): void;
  fetchAssetUploadStatus(uuid: string): void;
  deleteAsset(uuid: string): void;
  loading: boolean;
  deleting: boolean;
}

enum ModelSort {
  RANDOM = 'random',
  RECENT = 'recent',
  ALPHABETICAL = 'alphabetical',
  REV_ALPHABETICAL = 'rev_alphabetical',
}

const SortTypeMap = {
  [ModelSort.RANDOM]: 'Random',
  [ModelSort.RECENT]: 'Most Recent',
  [ModelSort.ALPHABETICAL]: 'A to Z',
  [ModelSort.REV_ALPHABETICAL]: 'Z to A',
};

const sortFunctions = {
  [ModelSort.RECENT]: (modelA, modelB) => modelB.uploadDate - modelA.uploadDate,
  [ModelSort.ALPHABETICAL]: (modelA, modelB) =>
    modelA.name.localeCompare(modelB.name),
  [ModelSort.REV_ALPHABETICAL]: (modelA, modelB) =>
    modelB.name.localeCompare(modelA.name),
  //It might add a bias, TODO: use some standarad algo
  [ModelSort.RANDOM]: (modelA, modelB, random: number) =>
    parseInt(md5(modelA.uploadDate * random), 16) -
    parseInt(md5(modelB.uploadDate * random), 16),
};

const MAX_MODELS_PER_PAGE = 16;

const mapStateToProps = (state: State) => ({
  assets: state.FETCH_ALL_PUBLIC_ASSETS.data,
  loading: state.FETCH_ALL_PUBLIC_ASSETS.loading,
  deleting: state.DELETE_ASSET.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssets: () => dispatch(fetchAllPulicAssets()),
  fetchAssetUploadStatus: (uuid) =>
    dispatch(getUploadStatus(undefined, { uuid })),
});
class Home extends Component<AssetsProps, never> {
  public componentDidMount() {
    this.props.fetchAssets();
  }

  private renderCard = (assetData: AssetMetadata) => {
    return (
      <Col xs="12" sm="6" lg="4" xl="3" key={assetData.uuid}>
        <ModelCard assetData={assetData} />
      </Col>
    );
  };

  private renderHelmet() {
    return (
      <Helmet>
        <title>Nvzn Augmented Reality | 3D Commerce Hub</title>
        <meta name="title" content="Nvzn Augmented Reality | 3D Commerce Hub" />
        <meta
          name="description"
          content="Nvzn is a SaaS platform for augmented reality virtual product visualization and previews for e-commerce on the web."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
    );
  }

  public render() {
    const { loading, assets } = this.props;
    return (
      <div className="home">
        {this.renderHelmet()}
        <SearchableContent<ModelSort, AssetMetadata>
          headerText="3D Commerce Hub"
          loading={loading}
          items={assets}
          maxItemsPerPage={MAX_MODELS_PER_PAGE}
          renderItem={this.renderCard}
          searchableProperties={['name', 'desc']}
          sortFunctonMap={sortFunctions}
          sortTypeEnum={SortTypeMap}
          history={this.props.history}
          initialSort={ModelSort.RANDOM}
          initialSeed={Math.random()}
        />
      </div>
    );
  }
}

const HomeConnected = connect(mapStateToProps, mapDispatchToProps)(Home);

export { HomeConnected as Home };
