import React from 'react';

export function PageNotFound() {
  return (
    <div className="page-not-found">
      <h3 className="page-not-found__title">
        Looks like you're trying to access a page that does not exist!
      </h3>
      <pre className="page-not-found__error">Error Code: 404</pre>
    </div>
  );
}
