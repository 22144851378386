import {
  ANGLE,
  DISTANCE,
  AssetUploadPayload,
  MODEL_ALIGNMENT,
  IMAGE_TYPE,
  AR_SCALE,
  CAM_ANGLE,
} from 'nvzn-models';
import * as Yup from 'yup';
import { getFileType } from '../../util';
import { config } from '../../util/config';

enum BANNER_TYPE {
  buyNow = 'buyNow',
  addToCart = 'addToCart',
  addToBasket = 'addToBasket',
  addToWishList = 'addToWishList',
  addToFoodOrder = 'addToFoodOrder',
}

const hexRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
export const validationSchema = Yup.object().shape({
  files: Yup.object().shape({
    glb: Yup.mixed()
      .test(
        'fileSize',
        'Model must be less than 20 MB',
        (file: File) => !file || file.size < config.maxFileSize.glb,
      )
      .test(
        'fileType',
        'This must be a .glb file',
        (file: File) =>
          !file || config.validFileTypes.glb.includes(getFileType(file)),
      )
      .required(),
    usdz: Yup.mixed()
      .test(
        'fileSize',
        'Model must be less than 20 MB',
        (file: File) => !file || file.size < config.maxFileSize.usdz,
      )
      .test(
        'fileType',
        'This must be a .usdz file',
        (file: File) => !file || config.validFileTypes.usdz.includes('usdz'),
      )
      .required(),
    backgroundImage: Yup.mixed()
      .test(
        'fileSize',
        'Model must be less than 20 MB',
        (file: File) => !file || file.size < config.maxFileSize.backgroundImage,
      )
      .test(
        'fileType',
        'This must be a .png, .jpg or .hdr file',
        (file: File) =>
          !file ||
          config.validFileTypes.backgroundImage.includes(getFileType(file)),
      ),
    environmentalImage: Yup.mixed()
      .test(
        'fileSize',
        'Model must be less than 20 MB',
        (file: File) =>
          !file || file.size < config.maxFileSize.environmentalImage,
      )
      .test(
        'fileType',
        'This must be a .png, .jpg or .hdr file',
        (file: File) =>
          !file ||
          config.validFileTypes.environmentalImage.includes(getFileType(file)),
      ),
  }),
  config: Yup.object().shape({
    name: Yup.string().max(100).required('You must specify a name'),
    desc: Yup.string().max(500),
    glbFileName: Yup.string(),
    usdzFileName: Yup.string(),
    autoRotate: Yup.boolean(),
    arScale: Yup.string().oneOf([AR_SCALE.fixed, AR_SCALE.auto]),
    backgroundColor: Yup.string().matches(hexRegex),
    alignModel: Yup.object().shape({
      x: Yup.string().oneOf([MODEL_ALIGNMENT.center, MODEL_ALIGNMENT.origin]),
      y: Yup.string().oneOf([MODEL_ALIGNMENT.center, MODEL_ALIGNMENT.origin]),
      z: Yup.string().oneOf([MODEL_ALIGNMENT.center, MODEL_ALIGNMENT.origin]),
    }),
    cameraOrbit: Yup.object().shape({
      azimuthal: Yup.number()
        .min(-180)
        .max(360)
        .when('azimuthalUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        }),
      azimuthalUnit: Yup.string().oneOf([ANGLE.degrees, ANGLE.radian]),
      polar: Yup.number()
        .min(0)
        .when('polarUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        }),
      polarUnit: Yup.string().oneOf([ANGLE.degrees, ANGLE.radian]),
      distance: Yup.number().min(0).max(999.9),
      distanceUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
    }),
    minCameraOrbit: Yup.object().shape({
      azimuthal: Yup.number()
        .min(-180)
        .max(360)
        .when('azimuthalUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        })
        .when('azimuthalUnit', {
          is: 'auto',
          then: Yup.number().notRequired(),
        }),
      azimuthalUnit: Yup.string().oneOf([
        DISTANCE.auto,
        ANGLE.degrees,
        ANGLE.radian,
      ]),
      polar: Yup.number()
        .min(0)
        .when('polarUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        })
        .when('polarUnit', {
          is: 'auto',
          then: Yup.number().notRequired(),
        }),
      polarUnit: Yup.string().oneOf([
        DISTANCE.auto,
        ANGLE.degrees,
        ANGLE.radian,
      ]),
      distance: Yup.number().min(0).max(999.9),
      distanceUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
    }),
    maxCameraOrbit: Yup.object().shape({
      azimuthal: Yup.number()
        .min(-180)
        .when('azimuthalUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        })
        .when('azimuthalUnit', {
          is: 'auto',
          then: Yup.number().notRequired(),
        }),
      azimuthalUnit: Yup.string().oneOf([
        DISTANCE.auto,
        ANGLE.degrees,
        ANGLE.radian,
      ]),
      polar: Yup.number()
        .min(0)
        .when('polarUnit', {
          is: 'deg',
          then: Yup.number().max(360),
          otherwise: Yup.number().max(2 * Math.PI),
        })
        .when('polarUnit', {
          is: 'auto',
          then: Yup.number().notRequired(),
        }),
      polarUnit: Yup.string().oneOf([
        DISTANCE.auto,
        ANGLE.degrees,
        ANGLE.radian,
      ]),
      distance: Yup.number().min(0).max(999.9).when('distanceUnit', {
        is: 'auto',
        then: Yup.number().notRequired(),
      }),
      distanceUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
    }),
    cameraTarget: Yup.object().shape({
      x: Yup.number().min(0).max(999.9),
      xUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
      y: Yup.number().min(0).max(999.9),
      yUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
      z: Yup.number().min(0).max(999.9),
      zUnit: Yup.string().oneOf([
        DISTANCE.auto,
        DISTANCE.milimeters,
        DISTANCE.centimeters,
        DISTANCE.meters,
      ]),
    }),
    fieldOfView: Yup.object().shape({
      angle: Yup.number()
        .min(0)
        .when('unit', {
          is: 'deg',
          then: Yup.number().max(360),
        })
        .when('unit', {
          is: 'rad',
          then: Yup.number().max(2 * Math.PI),
        })
        .when('unit', {
          is: 'auto',
          then: Yup.number().notRequired(),
        }),
      // unit: Yup.string().oneOf([ANGLE.degrees, ANGLE.radian, 'auto']),
    }),
    exposure: Yup.number().min(0).max(100),
    shadowIntensity: Yup.number().min(0).max(10),
    shadowSoftness: Yup.number().min(0).max(10),

    backgroundImageType: Yup.string(),
    environmentalImageType: Yup.string(),
    showSharing: Yup.boolean(),
    authorName: Yup.boolean(),
    additionalInfo: Yup.string().max(5000),
    linkOut: Yup.string().url(),
    playerSrc: Yup.string().url(),
    modelInfo: Yup.object().shape({
      modelNames: Yup.string().when('licenseType', {
        is: '',
        then: Yup.string().max(64),
        otherwise: Yup.string()
          .required('Author Attribution is a required field')
          .max(64, 'Author Attribution length must be 64 characters max'),
      }),
      licenseType: Yup.string(),
      authorWork: Yup.boolean(),
    }),
    privateCheck: Yup.boolean(),
    nvznPortal: Yup.boolean(),
    ctaBanner: Yup.object().shape({
      ctaSwitch: Yup.boolean(),
      ctaType: Yup.string()
        .when('ctaSwitch', {
          is: false,
          then: Yup.string().notRequired(),
        })
        .when('ctaSwitch', {
          is: true,
          then: Yup.string().oneOf([
            BANNER_TYPE.buyNow,
            BANNER_TYPE.addToCart,
            BANNER_TYPE.addToBasket,
            BANNER_TYPE.addToWishList,
            BANNER_TYPE.addToFoodOrder,
          ]),
        }),
      ctaUrl: Yup.string()
        .when('ctaSwitch', {
          is: false,
          then: Yup.string().notRequired(),
        })
        .when('ctaSwitch', {
          is: true,
          then: Yup.string().url().required(),
        }),
    }),
  }),
});

export const initialValues: AssetUploadPayload = {
  files: {
    glb: undefined,
    usdz: undefined,
    backgroundImage: undefined,
    environmentalImage: undefined,
  },
  config: {
    name: '',
    desc: '',
    glbFileName: '',
    usdzFileName: '',
    autoRotate: false,
    arScale: AR_SCALE.auto,
    backgroundColor: 'FFFFFF',
    autoplay: true,
    alignModel: {
      x: MODEL_ALIGNMENT.center,
      y: MODEL_ALIGNMENT.center,
      z: MODEL_ALIGNMENT.center,
    },
    cameraOrbit: {
      azimuthal: 0,
      azimuthalUnit: ANGLE.degrees,
      polar: 75,
      polarUnit: ANGLE.degrees,
      distance: 0,
      distanceUnit: DISTANCE.auto,
    },
    minCameraOrbit: {
      azimuthal: 0,
      azimuthalUnit: CAM_ANGLE.auto,
      polar: 0,
      polarUnit: CAM_ANGLE.auto,
      distance: 0,
      distanceUnit: DISTANCE.auto,
    },
    maxCameraOrbit: {
      azimuthal: 0,
      azimuthalUnit: CAM_ANGLE.auto,
      polar: 0,
      polarUnit: CAM_ANGLE.auto,
      distance: 0,
      distanceUnit: DISTANCE.auto,
    },
    cameraTarget: {
      x: 0,
      xUnit: DISTANCE.auto,
      y: 0,
      yUnit: DISTANCE.auto,
      z: 0,
      zUnit: DISTANCE.auto,
    },
    //TODO Set auto value in ANGLE enum in model repo
    fieldOfView: {
      angle: 45,
      unit: 'auto',
    },
    exposure: 1,
    shadowIntensity: 1,
    shadowSoftness: 1,
    backgroundImageType: IMAGE_TYPE.none,
    environmentalImageType: IMAGE_TYPE.none,
    showSharing: true,
    authorName: false,
    additionalInfo: '',
    modelInfo: {
      modelNames: '',
      licenseType: '',
      authorWork: false,
    },
    linkOut: '',
    privateCheck: false,
    nvznPortal: true,
    ctaBanner: {
      ctaSwitch: false,
      ctaUrl: '',
      ctaType: '',
    },
  },
};
