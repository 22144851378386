import React, { ComponentProps } from 'react';
import { UserInfo, UserRole } from 'nvzn-models';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { PageLoader } from './index';
import { State } from '../state/reducer';
import { routePermissions, routes } from '../util';

const userRoleHierarchy: UserRole[] = [
  UserRole.PUBLISHER,
  UserRole.ADMIN,
  UserRole.SUPERADMIN,
];

function isRoleSufficient(
  actualRole: UserRole,
  requiredRole: UserRole,
): boolean {
  const actualRoleRelativeIndex = userRoleHierarchy.indexOf(actualRole);
  const requiredRoleRelativeIndex = userRoleHierarchy.indexOf(requiredRole);

  return actualRoleRelativeIndex >= requiredRoleRelativeIndex;
}

const mapStateToProps = (state: State) => {
  const isLoggedIn = !!state.VERIFY_TOKEN.data.token;
  const userInfo = state.GET_USER.data;
  const { role } = userInfo || {};
  const isWaitingForUserInfo = isLoggedIn && !role;
  const isLoading =
    state.VERIFY_TOKEN.loading ||
    state.GET_USER.loading ||
    isWaitingForUserInfo;
  return {
    isLoading,
    isLoggedIn,
    assets: state.FETCH_ASSETS.data,
    userInfo,
  };
};

interface PrivateRouteProps extends ComponentProps<typeof Route> {
  isLoading: boolean;
  isLoggedIn: boolean;
  userInfo: UserInfo;
  path: string;
}

function PrivateRouteComponent({
  component: WrappedComponent,
  isLoading,
  isLoggedIn,
  userInfo,
  path,
  ...rest
}: PrivateRouteProps) {
  function renderComponent(props: ComponentProps<typeof WrappedComponent>) {
    let rv;
    const { role } = userInfo || {};
    const requiredRole = routePermissions[path];
    const hasRequiredPermissions = isRoleSufficient(role, requiredRole);
    if (isLoading) {
      rv = <PageLoader />;
    } else if (!isLoggedIn) {
      rv = (
        <Redirect
          to={{
            pathname: routes.LOG_IN,
            state: {
              referrer: path,
            },
          }}
        />
      );
    } else if (!hasRequiredPermissions) {
      // if (path === "/admin/users" && role === "publisher") {
      //   rv = (<Redirect
      //     to={{
      //       pathname: routes.HOME,
      //       state: {
      //         referrer: routes.HOME,
      //       },
      //     }}
      //   />
      //   )
      // } else {
      rv = (
        <Redirect
          to={{
            pathname: routes.PAGE_NOT_FOUND,
            state: {
              referrer: path,
            },
          }}
        />
      );
      // }
    } else {
      rv = <WrappedComponent {...props} />;
    }
    return rv;
  }

  return <Route {...rest} render={renderComponent} />;
}

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
