import React from 'react';
import { Button, Spinner, Modal, Alert } from 'react-bootstrap';
import { ModalProps } from './BaseModal';

interface DeleteModalProps extends ModalProps {
  itemName: string;
  onConfirmDelete(): void;
  deleting: boolean;
}

export function DeleteModal({
  show,
  onClose,
  itemName,
  onConfirmDelete,
  deleting,
}: DeleteModalProps) {
  return (
    <Modal show={show} onHide={onClose} className="confirm-delete-modal">
      <Modal.Body>
        <Alert variant="danger">
          Are you sure you want to delete <b>{itemName}</b>?
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          className="confirm-delete-modal__confirm-button"
          onClick={onConfirmDelete}
        >
          {deleting && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              className="mr-2"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Delete</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
