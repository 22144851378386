import { FormikErrors, FormikTouched, Field } from 'formik';
import { AssetUploadPayload, AR_SCALE } from 'nvzn-models';
import React, { Fragment, useState } from 'react';
import { UserInfo, UserRole } from 'nvzn-models';
import { connect } from 'react-redux';
import {
  Col,
  Button,
  Form,
  InputGroup,
  Card,
  Popover,
  OverlayTrigger,
  Accordion,
  FormLabel,
} from 'react-bootstrap';
import { SwatchesPicker } from 'react-color';
import { Scrollbars } from 'react-custom-scrollbars';
import { FieldInfo } from './FieldInfo';
import { initialValues } from './validationSchema';
import { FileUpload } from '../../components';
import { State } from '../../state/reducer';

export interface IFormProps {
  handleSubmit(): void;

  handleCancel(): void;

  handleChange(e): void;

  handleBlur(e): void;

  values: AssetUploadPayload;
  touched: FormikTouched<AssetUploadPayload>;
  isValid: boolean;
  isSubmitting: boolean;
  errors: FormikErrors<AssetUploadPayload>;
  editMode: boolean;
  userInfo?: UserInfo;
}

const mapStateToProps = (state: State) => ({
  userInfo: state.GET_USER.data,
});

function UploadFormComponent({
  handleSubmit,
  handleCancel,
  handleChange,
  handleBlur,
  values,
  touched,
  isValid,
  errors,
  isSubmitting,
  editMode,
  userInfo,
}: IFormProps) {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showAdminOptions, setShowAdminOptions] = useState(false);

  const hasModels =
    editMode ||
    (values.files.glb &&
      !errors.files.glb &&
      values.files.usdz &&
      !errors.files.usdz);

  function renderColorPicker() {
    return (
      <Popover id="colorPicker" className="p-2">
        <SwatchesPicker
          onChange={({ hex }) =>
            handleChange({
              target: { name: 'config.backgroundColor', value: hex.substr(1) },
            })
          }
        />
      </Popover>
    );
  }
  function renderUploadSection() {
    return (
      <Fragment>
        <Card.Header>Upload Model Files</Card.Header>
        <Card.Body>
          <Form.Row>
            <Col xs={6}>
              <Form.Group controlId="files.glb">
                <div className="btn2">
                  <Field
                    component={FileUpload}
                    text="Upload.glb"
                    name="files.glb"
                    id="files.glb"
                    value={values.files.glb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.files.glb && !!errors.files.glb}
                    accept=".glb"
                  ></Field>
                </div>
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
                {touched.files.glb && !!errors.files.glb && (
                  <span className="editor__invalid-feedback">
                    {errors.files.glb}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="files.usdz">
                <div className="btn2">
                  <Field
                    component={FileUpload}
                    text="Upload .usdz"
                    name="files.usdz"
                    id="files.usdz"
                    value={values.files.usdz}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.files.usdz && !!errors.files.usdz}
                    accept=".usdz"
                  />
                </div>
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
                {touched.files.usdz && !!errors.files.usdz && (
                  <span className="editor__invalid-feedback">
                    {errors.files.usdz}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Form.Row>
        </Card.Body>
      </Fragment>
    );
  }

  function renderInputName() {
    return (
      <Form.Group controlId="config.name">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Model Name (Required)
        </Form.Label>
        <Form.Control
          type="text"
          name="config.name"
          placeholder="Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.config.name}
          isInvalid={touched.config.name && !!errors.config.name}
          disabled={!hasModels}
        />
        <Form.Control.Feedback type="invalid">
          {errors.config.name}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderPlayerSrc() {
    return (
      <Form.Group controlId="config.playerSrc">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Custom Player URL
          <FieldInfo fieldName="playerSrc" />
        </Form.Label>
        <Form.Control
          type="text"
          name="config.playerSrc"
          placeholder="URL"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.config.playerSrc}
          isInvalid={touched.config.playerSrc && !!errors.config.playerSrc}
          disabled={!hasModels}
        />
        <Form.Control.Feedback type="invalid">
          url is not valid
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  function renderInputDesc() {
    return (
      <Form.Group controlId="config.desc">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Description
        </Form.Label>
        <Form.Control
          as="textarea"
          name="config.desc"
          rows={3}
          placeholder="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.config.desc}
          isInvalid={touched.config.desc && !!errors.config.desc}
          disabled={!hasModels}
        />
        <Form.Control.Feedback type="invalid">
          Description must be at most 500 characters
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderInputArScale() {
    const arScaleValue = values.config.arScale
      ? values.config.arScale
      : AR_SCALE.auto;
    return (
      <Form.Group controlId="config.arScale">
        <Form.Check
          type="checkbox"
          label={
            <span>
              AR Fixed Scale
              <FieldInfo fieldName="arScale" />
            </span>
          }
          name="config.arScale"
          id="config.arScale"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={arScaleValue === AR_SCALE.fixed ? true : false}
        />
      </Form.Group>
    );
  }

  function renderInputAutoRotate() {
    return (
      <Form.Group controlId="config.autoRotate">
        <Form.Check
          type="checkbox"
          label={
            <span>
              Auto Rotate
              <FieldInfo fieldName="autoRotate" />
            </span>
          }
          name="config.autoRotate"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={values.config.autoRotate}
        />
      </Form.Group>
    );
  }

  function renderInputBackgroundColor() {
    return (
      <Form.Group controlId="config.backgroundColor">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Background
          <FieldInfo fieldName="backgroundColor" />
        </Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPrepend">#</InputGroup.Text>
          </InputGroup.Prepend>
          <OverlayTrigger overlay={renderColorPicker()} trigger="focus">
            <Form.Control
              name="config.backgroundColor"
              type="text"
              placeholder="hex color"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!hasModels}
              value={values.config.backgroundColor}
              isInvalid={
                touched.config.backgroundColor &&
                !!errors.config.backgroundColor
              }
            />
          </OverlayTrigger>
          <InputGroup.Append>
            <InputGroup.Text
              style={{
                width: '32px',
                backgroundColor: `#${values.config.backgroundColor}`,
              }}
            />
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
            Must be a valid hex color
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    );
  }

  function renderSectionBasic() {
    return (
      <Fragment>
        {renderInputName()}
        {renderInputDesc()}
        {renderInputArScale()}
        {renderInputARPlacement()}
        {renderInputAutoRotate()}
        {renderInputBackgroundColor()}
        {renderPrivateCheck()}
      </Fragment>
    );
  }

  function renderInputBackgroundImage() {
    return (
      <Form.Group>
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Skybox Image
          <FieldInfo fieldName="backgroundImage" />
        </Form.Label>
        <Form.Group controlId="files.backgroundImage">
          <Field
            component={FileUpload}
            text="Upload Skybox Image"
            name="files.backgroundImage"
            id="files.backgroundImage"
            accept=".jpg,.jpeg,.png,.hdr"
            value={values.files.backgroundImage}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!hasModels}
            isInvalid={
              touched.files.backgroundImage && !!errors.files.backgroundImage
            }
          />
          <Form.Control.Feedback type="valid">
            Looks good!
          </Form.Control.Feedback>
          {touched.files.backgroundImage && !!errors.files.backgroundImage && (
            <span className="editor__invalid-feedback">
              {errors.files.backgroundImage}
            </span>
          )}
        </Form.Group>
      </Form.Group>
    );
  }

  function renderInputCameraOrbit() {
    return (
      <Fragment>
        <Form.Label>
          Camera Orbit
          <FieldInfo fieldName="cameraOrbit" />
        </Form.Label>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Azimuthal</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.cameraOrbit.azimuthal"
                  name="config.cameraOrbit.azimuthal"
                  min="-180"
                  max="360"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={`${values.config.cameraOrbit.azimuthal}`}
                  isInvalid={
                    touched.config.cameraOrbit.azimuthal &&
                    !!errors.config.cameraOrbit.azimuthal
                  }
                />
                <Form.Control
                  as="select"
                  id="config.cameraOrbit.azimuthalUnit"
                  name="config.cameraOrbit.azimuthalUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraOrbit.azimuthalUnit}
                >
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Polar</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={!hasModels}
                  type="number"
                  id="config.cameraOrbit.polar"
                  name="config.cameraOrbit.polar"
                  min="0"
                  max="360"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={`${values.config.cameraOrbit.polar}`}
                  isInvalid={
                    touched.config.cameraOrbit.polar &&
                    !!errors.config.cameraOrbit.polar
                  }
                />
                <Form.Control
                  as="select"
                  id="config.cameraOrbit.polar"
                  name="config.cameraOrbit.polar"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraOrbit.polarUnit}
                >
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Distance</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.cameraOrbit.distance"
                  name="config.cameraOrbit.distance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min="0"
                  value={
                    values.config.cameraOrbit.distanceUnit === 'auto'
                      ? '0'
                      : `${values.config.cameraOrbit.distance}`
                  }
                  disabled={
                    !hasModels ||
                    values.config.cameraOrbit.distanceUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.cameraOrbit.distance &&
                    !!errors.config.cameraOrbit.distance
                  }
                />
                <Form.Control
                  as="select"
                  id="config.cameraOrbit.distanceUnit"
                  name="config.cameraOrbit.distanceUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraOrbit.distanceUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
      </Fragment>
    );
  }

  function renderInputMinCameraOrbit() {
    const minCamOrbitValues = !values.config.minCameraOrbit
      ? (values.config.minCameraOrbit = initialValues.config.minCameraOrbit)
      : values.config.minCameraOrbit;
    return (
      <Fragment>
        <Form.Label>
          Min Camera Orbit
          <FieldInfo fieldName="minCameraOrbit" />
        </Form.Label>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Azimuthal</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="-180"
                  max="360"
                  id="config.minCameraOrbit.azimuthal"
                  name="config.minCameraOrbit.azimuthal"
                  onChange={handleChange}
                  disabled={
                    !hasModels || minCamOrbitValues.azimuthalUnit === 'auto'
                  }
                  onBlur={handleBlur}
                  value={`${minCamOrbitValues.azimuthal}`}
                  isInvalid={
                    touched.config.minCameraOrbit.azimuthal &&
                    !!errors.config.minCameraOrbit.azimuthal
                  }
                />
                <Form.Control
                  as="select"
                  id="config.minCameraOrbit.azimuthalUnit"
                  name="config.minCameraOrbit.azimuthalUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={minCamOrbitValues.azimuthalUnit}
                >
                  <option>auto</option>
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Polar</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={
                    !hasModels || minCamOrbitValues.polarUnit === 'auto'
                  }
                  min="0"
                  type="number"
                  id="config.minCameraOrbit.polar"
                  name="config.minCameraOrbit.polar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={`${minCamOrbitValues.polar}`}
                  isInvalid={
                    minCamOrbitValues.polarUnit === 'auto'
                      ? null
                      : touched.config.minCameraOrbit.polar &&
                        !!errors.config.minCameraOrbit.polar
                  }
                />
                <Form.Control
                  as="select"
                  id="config.minCameraOrbit.polarUnit"
                  name="config.minCameraOrbit.polarUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={minCamOrbitValues.polarUnit}
                >
                  <option>auto</option>
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Distance</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.minCameraOrbit.distance"
                  name="config.minCameraOrbit.distance"
                  min="0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={`${minCamOrbitValues.distance}`}
                  disabled={
                    !hasModels || minCamOrbitValues.distanceUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.minCameraOrbit.distance &&
                    !!errors.config.minCameraOrbit.distance
                  }
                />
                <Form.Control
                  as="select"
                  id="config.minCameraOrbit.distanceUnit"
                  name="config.minCameraOrbit.distanceUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={minCamOrbitValues.distanceUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
      </Fragment>
    );
  }

  function renderInputMaxCameraOrbit() {
    const maxCamOrbitValues = !values.config.maxCameraOrbit
      ? (values.config.maxCameraOrbit = initialValues.config.maxCameraOrbit)
      : values.config.maxCameraOrbit;
    return (
      <Fragment>
        <Form.Label>
          Max Camera Orbit
          <FieldInfo fieldName="maxCameraOrbit" />
        </Form.Label>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Azimuthal</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.maxCameraOrbit.azimuthal"
                  name="config.maxCameraOrbit.azimuthal"
                  onChange={handleChange}
                  min="-180"
                  max="360"
                  disabled={
                    !hasModels || maxCamOrbitValues.azimuthalUnit === 'auto'
                  }
                  onBlur={handleBlur}
                  value={`${maxCamOrbitValues.azimuthal}`}
                  isInvalid={
                    touched.config.maxCameraOrbit.azimuthal &&
                    !!errors.config.maxCameraOrbit.azimuthal
                  }
                />
                <Form.Control
                  as="select"
                  id="config.maxCameraOrbit.azimuthalUnit"
                  name="config.maxCameraOrbit.azimuthalUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={maxCamOrbitValues.azimuthalUnit}
                >
                  <option>auto</option>
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Polar</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={
                    !hasModels || maxCamOrbitValues.polarUnit === 'auto'
                  }
                  min="0"
                  type="number"
                  id="config.maxCameraOrbit.polar"
                  name="config.maxCameraOrbit.polar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={`${maxCamOrbitValues.polar}`}
                  isInvalid={
                    touched.config.maxCameraOrbit.polar &&
                    !!errors.config.maxCameraOrbit.polar
                  }
                />
                <Form.Control
                  as="select"
                  id="config.maxCameraOrbit.polarUnit"
                  name="config.maxCameraOrbit.polarUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={maxCamOrbitValues.polarUnit}
                >
                  <option>auto</option>
                  <option>deg</option>
                  <option>rad</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Distance</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.maxCameraOrbit.distance"
                  name="config.maxCameraOrbit.distance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min="0"
                  value={`${maxCamOrbitValues.distance}`}
                  disabled={
                    !hasModels || maxCamOrbitValues.distanceUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.maxCameraOrbit.distance &&
                    !!errors.config.maxCameraOrbit.distance
                  }
                />
                <Form.Control
                  as="select"
                  id="config.maxCameraOrbit.distanceUnit"
                  name="config.maxCameraOrbit.distanceUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={maxCamOrbitValues.distanceUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
      </Fragment>
    );
  }

  function renderInputCameraTarget() {
    return (
      <Fragment>
        <Form.Label>
          Camera Target
          <FieldInfo fieldName="cameraTarget" />
        </Form.Label>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">X</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.cameraTarget.x"
                  name="config.cameraTarget.x"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min="0"
                  value={
                    values.config.cameraTarget.xUnit === 'auto'
                      ? '0'
                      : `${values.config.cameraTarget.x}`
                  }
                  disabled={
                    !hasModels || values.config.cameraTarget.xUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.cameraTarget.x &&
                    !!errors.config.cameraTarget.x
                  }
                />
                <Form.Control
                  as="select"
                  id="config.config.cameraTarget.xUnit"
                  name="config.cameraTarget.xUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraTarget.xUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Y</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.cameraTarget.y"
                  name="config.cameraTarget.y"
                  onChange={handleChange}
                  min="0"
                  onBlur={handleBlur}
                  value={
                    values.config.cameraTarget.yUnit === 'auto'
                      ? '0'
                      : `${values.config.cameraTarget.y}`
                  }
                  disabled={
                    !hasModels || values.config.cameraTarget.yUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.cameraTarget.y &&
                    !!errors.config.cameraTarget.y
                  }
                />
                <Form.Control
                  as="select"
                  id="config.config.cameraTarget.yUnit"
                  name="config.cameraTarget.yUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraTarget.yUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="text-secondary">Z</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  id="config.cameraTarget.z"
                  name="config.cameraTarget.z"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min="0"
                  value={
                    values.config.cameraTarget.zUnit === 'auto'
                      ? '0'
                      : `${values.config.cameraTarget.z}`
                  }
                  disabled={
                    !hasModels || values.config.cameraTarget.zUnit === 'auto'
                  }
                  isInvalid={
                    touched.config.cameraTarget.z &&
                    !!errors.config.cameraTarget.z
                  }
                />
                <Form.Control
                  as="select"
                  id="config.config.cameraTarget.zUnit"
                  name="config.cameraTarget.zUnit"
                  onChange={handleChange}
                  disabled={!hasModels}
                  onBlur={handleBlur}
                  value={values.config.cameraTarget.zUnit}
                >
                  <option>auto</option>
                  <option>m</option>
                  <option>cm</option>
                  <option>mm</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
      </Fragment>
    );
  }

  function renderInputFieldOfView() {
    return (
      <Form.Group>
        <Form.Label>
          Field of view
          <FieldInfo fieldName="fieldOfView" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            id="config.fieldOfView.angle"
            name="config.fieldOfView.angle"
            onChange={handleChange}
            disabled={!hasModels || values.config.fieldOfView.unit === 'auto'}
            onBlur={handleBlur}
            value={`${values.config.fieldOfView.angle}`}
            isInvalid={
              touched.config.fieldOfView.angle &&
              !!errors.config.fieldOfView.angle
            }
          />
          <Form.Control
            as="select"
            id="config.fieldOfView.unit"
            name="config.fieldOfView.unit"
            onChange={handleChange}
            disabled={!hasModels}
            onBlur={handleBlur}
            value={values.config.fieldOfView.unit}
          >
            <option>auto</option>
            <option>deg</option>
            <option>rad</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    );
  }

  function renderInputARPlacement() {
    return (
      <Form.Group>
        <Form.Label>
          AR Placement
          <FieldInfo fieldName="arPlacement" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            as="select"
            id="config.arPlacement"
            name="config.arPlacement"
            onChange={handleChange}
            disabled={!hasModels}
            onBlur={handleBlur}
            value={values.config.arPlacement}
          >
            <option>Floor</option>
            <option>Wall</option>
          </Form.Control>
        </InputGroup>
      </Form.Group>
    );
  }

  function renderInputEnvironmentalImage() {
    return (
      <Form.Group>
        <Form.Label>
          Environment Image
          <FieldInfo fieldName="environmentalImage" />
        </Form.Label>
        <Field
          component={FileUpload}
          text="Upload Environment Image"
          name="files.environmentalImage"
          id="files.environmentalImage"
          accept=".jpg,.jpeg,.png,.hdr"
          value={values.files.environmentalImage}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          isInvalid={
            touched.files.environmentalImage &&
            !!errors.files.environmentalImage
          }
        />
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
        {touched.files.environmentalImage &&
          !!errors.files.environmentalImage && (
            <span className="editor__invalid-feedback">
              {errors.files.environmentalImage}
            </span>
          )}
      </Form.Group>
    );
  }

  function renderInputExposure() {
    return (
      <Form.Group controlId="config.exposure">
        <Form.Label>
          Exposure
          <FieldInfo fieldName="exposure" />
        </Form.Label>
        <Form.Control
          type="number"
          name="config.exposure"
          onChange={handleChange}
          disabled={!hasModels}
          onBlur={handleBlur}
          value={`${values.config.exposure}`}
          isInvalid={touched.config.exposure && !!errors.config.exposure}
        />
        <Form.Control.Feedback type="invalid">
          Must be a number between 0 and 100
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderInputShadowIntensity() {
    return (
      <Form.Group controlId="config.shadowIntensity">
        <Form.Label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FormLabel>
            Shadow Intensity
            <FieldInfo fieldName="shadowIntensity" />
          </FormLabel>
          {values.config.shadowIntensity}
        </Form.Label>
        <Form.Control
          type="range"
          style={{ padding: 0 }}
          name="config.shadowIntensity"
          onChange={handleChange}
          disabled={!hasModels}
          onBlur={handleBlur}
          step={0.01}
          min={0}
          max={10}
          value={`${values.config.shadowIntensity}`}
          isInvalid={
            touched.config.shadowIntensity && !!errors.config.shadowIntensity
          }
        />
        <Form.Control.Feedback type="invalid">
          Must be a number between 0 and 10
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderInputShadowSoftness() {
    return (
      <Form.Group controlId="config.shadowSoftness">
        <Form.Label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FormLabel>
            Shadow Softness
            <FieldInfo fieldName="shadowSoftness" />
          </FormLabel>
          {values.config.shadowSoftness}
        </Form.Label>
        <Form.Control
          type="range"
          style={{ padding: 0 }}
          name="config.shadowSoftness"
          onChange={handleChange}
          disabled={!hasModels}
          onBlur={handleBlur}
          step={0.01}
          min={0}
          max={1}
          value={`${values.config.shadowSoftness}`}
          isInvalid={
            touched.config.shadowSoftness && !!errors.config.shadowSoftness
          }
        />
        <Form.Control.Feedback type="invalid">
          Must be a number between 0 and 1
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderShowAdvanced() {
    let rv = (
      <div>
        <Button variant="link" disabled>
          Show Advanced Options
        </Button>
        <Button variant="link" disabled>
          Show Page Elements
        </Button>
      </div>
    );
    let adminOptionsButton =
      userInfo.role === UserRole.SUPERADMIN ||
      userInfo.role === UserRole.ADMIN ? (
        <Button
          variant="link"
          onClick={() => setShowAdminOptions(!showAdminOptions)}
          id="toggleShowAdmin"
        >
          {showAdminOptions ? 'Hide' : 'Show'} Admin Options
        </Button>
      ) : null;
    if (hasModels) {
      rv = (
        <div className="advanced-controls-container">
          <Button
            variant="link"
            onClick={() => setShowBanner(!showBanner)}
            id="toggleShowBanner"
          >
            {showBanner ? 'Hide' : 'Show'} Banner Options
          </Button>
          <Button
            variant="link"
            onClick={() => setShowAdvanced(!showAdvanced)}
            id="toggleShowAdvanced"
          >
            {showAdvanced ? 'Hide' : 'Show'} Advanced Options
          </Button>
          <Button
            variant="link"
            onClick={() => setShowPage(!showPage)}
            id="toggleShowPage"
          >
            {showPage ? 'Hide' : 'Show'} Page Elements
          </Button>
          {adminOptionsButton}
        </div>
      );
    }
    return rv;
  }

  function renderShowSharing() {
    return (
      <Form.Group controlId="config.showSharing">
        <Form.Check
          type="checkbox"
          label={
            <span>
              Show Sharing
              <FieldInfo fieldName="showSharing" />
            </span>
          }
          name="config.showSharing"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={values.config.showSharing}
        />
      </Form.Group>
    );
  }

  function renderAuthorName() {
    return (
      <Form.Group controlId="config.authorName">
        <Form.Check
          type="checkbox"
          label={
            <span>
              Show UserName
              <FieldInfo fieldName="AuthorName" />
            </span>
          }
          name="config.authorName"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={values.config.authorName}
        />
      </Form.Group>
    );
  }

  function renderAdditionalInfo() {
    return (
      <Form.Group controlId="config.additionalInfo">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          <span>
            Additional Info
            <FieldInfo fieldName="AdditionalInfo" />
          </span>
        </Form.Label>
        <Form.Control
          as="textarea"
          name="config.additionalInfo"
          rows={10}
          placeholder="additionalInfo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.config.additionalInfo}
          isInvalid={
            touched.config.additionalInfo && !!errors.config.additionalInfo
          }
          disabled={!hasModels}
        />
        <Form.Control.Feedback type="invalid">
          {errors.config.additionalInfo}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderModelInfo() {
    return (
      <Fragment>
        <Form.Group>
          <Form.Label className={hasModels ? '' : 'text-secondary'}>
            Select license type
            <FieldInfo fieldName="license" />
          </Form.Label>
          <Form.Control
            as="select"
            id="config.config.modelInfo.licenseType"
            name="config.modelInfo.licenseType"
            onChange={handleChange}
            disabled={!hasModels}
            onBlur={handleBlur}
            value={values.config.modelInfo.licenseType}
          >
            <option></option>
            <option>Attribution CC BY</option>
            <option>Attribution-ShareAlike CC BY-SA</option>
            <option>Attribution-NoDerivs CC BY-ND</option>
            <option>Attribution-NonCommercial CC BY-NC</option>
            <option>Attribution-NonCommercial-ShareAlike CC BY-NC-SA</option>
            <option>Attribution-NonCommercial-NoDerivs CC BY-NC-ND </option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="config.modelInfo.modelNames">
          <Form.Label className={hasModels ? '' : 'text-secondary'}>
            <span>
              Author Attribution
              <FieldInfo fieldName="Attribution" />
            </span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="config.modelInfo.modelNames"
            rows={1}
            placeholder="Author Attribution"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.config.modelInfo.modelNames}
            isInvalid={
              touched.config.modelInfo.modelNames &&
              !!errors.config.modelInfo.modelNames
            }
            disabled={!hasModels || !values.config.modelInfo.licenseType}
          />
          <Form.Control.Feedback type="invalid">
            {errors.config.modelInfo.modelNames}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="config.modelInfo.authorWork">
          <Form.Check
            type="checkbox"
            label={
              <span>
                Changes were made to author’s work
                <FieldInfo fieldName="ModelInfo" />
              </span>
            }
            name="config.modelInfo.authorWork"
            custom
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!hasModels || !values.config.modelInfo.licenseType}
            checked={
              values.config.modelInfo.authorWork &&
              !!values.config.modelInfo.licenseType
            }
          />
        </Form.Group>
      </Fragment>
    );
  }

  function renderLinkOut() {
    return (
      <Form.Group controlId="config.linkOut">
        <Form.Label className={hasModels ? '' : 'text-secondary'}>
          Link Out
          <FieldInfo fieldName="LinkOut" />
        </Form.Label>
        <Form.Control
          as="textarea"
          name="config.linkOut"
          rows={1}
          placeholder="Link Out"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.config.linkOut}
          isInvalid={
            touched.config.linkOut &&
            !!errors.config.linkOut &&
            !!values.config.linkOut.length
          }
          disabled={!hasModels}
        />
        <Form.Control.Feedback type="invalid">
          url is not valid
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function renderPrivateCheck() {
    return (
      <Form.Group controlId="config.privateCheck">
        <Form.Check
          type="checkbox"
          label={
            <span>
              Make Private
              <FieldInfo fieldName="PrivateCheck" />
            </span>
          }
          name="config.privateCheck"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={values.config.privateCheck}
        />
      </Form.Group>
    );
  }

  function renderNvznPortal() {
    return (
      <Form.Group controlId="config.nvznPortal">
        <Form.Check
          type="checkbox"
          label={
            <span>
              Show Nvzn logo/link
              <FieldInfo fieldName="NvznPortal" />
            </span>
          }
          name="config.nvznPortal"
          custom
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!hasModels}
          checked={values.config.nvznPortal}
        />
      </Form.Group>
    );
  }

  function renderPageElements() {
    return (
      <div className="border-top pt-2">
        {renderShowSharing()}
        {renderAuthorName()}
        {renderAdditionalInfo()}
        {renderModelInfo()}
        {renderLinkOut()}
        {renderNvznPortal()}
      </div>
    );
  }

  function renderSectionBanner() {
    const bannerOptions = [
      'none',
      'buyNow',
      'addToCart',
      'addToBasket',
      'addToWishList',
      'addToFoodOrder',
    ];
    return (
      <div className="border-top pt-2">
        <div className="banner-switch-container pt-4 pb-3">
          <Form.Check
            type="switch"
            id="config.config.ctaBanner.ctaSwitch"
            name="config.ctaBanner.ctaSwitch"
            label="Call-to-Action Banner"
            defaultChecked={values.config.ctaBanner?.ctaSwitch}
            onChange={handleChange}
            value={values.config.ctaBanner?.ctaSwitch}
          />
          <FieldInfo fieldName="banner" />
        </div>
        <Form.Group controlId="config.nvznPortal">
          <Form.Group>
            <Form.Label className={hasModels ? '' : 'text-secondary'}>
              Banner Type
            </Form.Label>
            <Form.Control
              as="select"
              id="config.config.ctaBanner.ctaType"
              name="config.ctaBanner.ctaType"
              onChange={handleChange}
              disabled={!hasModels || !values.config.ctaBanner?.ctaSwitch}
              onBlur={handleBlur}
              value={values.config.ctaBanner?.ctaType}
            >
              <option value={bannerOptions[0]}>Select Banner Type...</option>
              <option value={bannerOptions[1]}>Buy Now</option>
              <option value={bannerOptions[2]}>Add To Cart</option>
              <option value={bannerOptions[3]}>Add To Basket</option>
              <option value={bannerOptions[4]}>Add To Wish List</option>
              <option value={bannerOptions[5]}>Add To Food Order</option>
            </Form.Control>
          </Form.Group>
        </Form.Group>
        <Form.Group controlId="config.linkOut">
          <Form.Label className={hasModels ? '' : 'text-secondary'}>
            Click-Through URL
          </Form.Label>
          <Form.Control
            as="textarea"
            name="config.ctaBanner.ctaUrl"
            rows={1}
            placeholder="Enter URL"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.config.ctaBanner?.ctaUrl}
            isInvalid={
              !!errors.config.ctaBanner?.ctaUrl &&
              !!values.config.ctaBanner.ctaUrl &&
              values.config.ctaBanner.ctaUrl.length
            }
            disabled={!hasModels || !values.config.ctaBanner?.ctaSwitch}
          />
          <Form.Control.Feedback type="invalid">
            Must enter a Click-Through URL when AR Click-Through Banner is
            active
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    );
  }

  function renderSectionAdvanced() {
    return (
      <div className="border-top pt-2">
        {renderInputBackgroundImage()}
        {renderInputCameraOrbit()}
        {renderInputMinCameraOrbit()}
        {renderInputMaxCameraOrbit()}
        {renderInputCameraTarget()}
        {renderInputFieldOfView()}
        {renderInputEnvironmentalImage()}
        {renderInputExposure()}
        {renderInputShadowIntensity()}
        {renderInputShadowSoftness()}
      </div>
    );
  }
  return (
    <Form noValidate onSubmit={() => {}} style={{ height: '100%' }}>
      <Card style={{ height: '100%' }}>
        {renderUploadSection()}
        <Card.Footer className="p-0" />
        <Card.Header className={hasModels ? '' : 'text-secondary'}>
          Configuration
        </Card.Header>
        <Scrollbars style={{ width: '100%', height: '100%' }}>
          <Card.Body>
            {renderSectionBasic()}
            <Accordion defaultActiveKey="0" activeKey={showBanner ? '1' : '0'}>
              <Accordion.Collapse eventKey="1">
                {renderSectionBanner()}
              </Accordion.Collapse>
            </Accordion>
            <Accordion
              defaultActiveKey="0"
              activeKey={showAdvanced ? '1' : '0'}
            >
              <Accordion.Collapse eventKey="1">
                {renderSectionAdvanced()}
              </Accordion.Collapse>
            </Accordion>

            <Accordion
              defaultActiveKey="0"
              activeKey={showAdminOptions ? '1' : '0'}
            >
              <Accordion.Collapse eventKey="1">
                {renderPlayerSrc()}
              </Accordion.Collapse>
            </Accordion>

            <Accordion defaultActiveKey="0" activeKey={showPage ? '1' : '0'}>
              <Accordion.Collapse eventKey="1">
                {renderPageElements()}
              </Accordion.Collapse>
              {renderShowAdvanced()}
            </Accordion>
          </Card.Body>
        </Scrollbars>
        <Card.Footer>
          <Button
            id="submit-form"
            // type="submit"
            onClick={() => handleSubmit()}
            disabled={!isValid}
            className="mr-2"
          >
            Submit
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
}

export const UploadForm = connect(mapStateToProps)(UploadFormComponent);
