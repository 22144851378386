import React, { Component } from 'react';
import {
  Form,
  Modal,
  Button,
  Toast,
  Tabs,
  Card,
  Tab,
  Container,
  Row,
  Col,
  ResponsiveEmbed,
  Popover,
  OverlayTrigger,
  InputGroup,
} from 'react-bootstrap';
import { SwatchesPicker } from 'react-color';
import { FaRegCopy, FaCheck, FaDownload } from 'react-icons/fa';
import { SelectedTab } from './index';
import { AssetDataModel } from '../../models';
import { config } from '../../util';

interface ShareModalProps {
  assetData: AssetDataModel;
  show: boolean;
  uuid: string;
  name: string;
  onClose: () => void;
}

interface ShareModalState {
  showToast: boolean;
  selectedTab: SelectedTab;
  showLogo: boolean;
  bgColor: string;
  width: number;
  height: number;
}

export class ShareModal extends Component<ShareModalProps, ShareModalState> {
  public state: ShareModalState = {
    showToast: false,
    selectedTab: SelectedTab.SHARE,
    showLogo: true,
    bgColor: '',
    width: 640,
    height: 480,
  };

  public componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        selectedTab: SelectedTab.SHARE,
        showLogo: true,
        bgColor: '',
        width: 640,
        height: 480,
      });
    }
  }

  private copyText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    this.setState(
      {
        showToast: true,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              showToast: false,
            }),
          2000,
        ),
    );
  };

  private changeTab = (tab: SelectedTab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  private renderColorPicker() {
    return (
      <Popover id="colorPicker" className="p-2">
        <SwatchesPicker
          onChange={({ hex }) => this.setState({ bgColor: hex.substr(1) })}
        />
      </Popover>
    );
  }

  private appendQueryString(url, queryVars) {
    const firstSeperator = url.indexOf('?') === -1 ? '?' : '&';
    let queryStringParts = [];
    for (let key in queryVars) {
      queryStringParts.push(key + '=' + queryVars[key]);
    }
    const queryString = queryStringParts.join('&');
    return url + firstSeperator + queryString;
  }

  private getBase64FromUrl = async (url, name) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        var a = document.createElement('a'); //Create <a>
        a.download = name; //File name Here
        a.href = base64data as string; //Image Base64 Goes here
        a.click(); //Down
      };
    });
  };

  public render() {
    const { uuid, onClose, show, assetData } = this.props;
    const { backgroundColor } = assetData;
    const { showToast, bgColor, showLogo, width, height } = this.state;
    const shareLink = `${config.frontEndUrl}/models/view/${uuid}`;
    const qrSrc = `${config.backEndUrl}/assets/qr/${uuid}`;
    const modelViewUrl = `${config.frontEndUrl}/models/view/${uuid}`;
    const iframeUrl = `${config.frontEndUrl}/player/${uuid}`;
    const params = {
      nvznlogo: showLogo ? '1' : '0',
    };
    if (bgColor) {
      params['bgcolor'] = bgColor ? bgColor : backgroundColor;
    }
    const urlWithParams = this.appendQueryString(iframeUrl, params);
    const iframeCode = `<iframe loading="lazy" width="${width}" height="${height}" src="${urlWithParams}" allow="xr-spatial-tracking *; fullscreen *;" style="border:none;overflow:hidden" frameborder="0" scrolling="no"  mozallowfullscreen="true" webkitallowfullscreen="true" allowfullscreen> </iframe>`;

    return (
      <Modal show={show} onHide={onClose} size="lg">
        <Modal.Header className="share-modal__header" closeButton />
        <Tabs
          id="controlled-tab"
          activeKey={this.state.selectedTab}
          onSelect={(k) => this.changeTab(k)}
        >
          <Tab eventKey="SHARE" title="Share">
            <Modal.Body>
              <h3>Link</h3>
              <span className="d-flex mb-3">
                <code className="flex-grow-1 border bg-light rounded p-2 mr-4 share-model-link">
                  {shareLink}
                </code>
                <span>
                  <Button onClick={() => this.copyText(shareLink)}>
                    <FaRegCopy />
                  </Button>
                </span>
              </span>
              <h3>QR Code</h3>
              <span className="d-flex mb-5">
                <code className="flex-grow-1 border bg-light rounded p-2 mr-4 text-center">
                  <img
                    id="qr_code"
                    src={qrSrc}
                    width={200}
                    height={200}
                    alt="QR Code"
                    className="border"
                  />
                </code>
                <span>
                  <Button
                    onClick={() =>
                      this.getBase64FromUrl(qrSrc, `qrcode_${uuid}_nvznar`)
                    }
                  >
                    <FaDownload />
                  </Button>
                </span>
              </span>
              <h3>Share This</h3>
              <div
                className="addthis_inline_share_toolbox"
                data-url={modelViewUrl}
              ></div>
            </Modal.Body>
            <div className="toast-container fixed-top mt-2">
              <Toast show={showToast}>
                <Toast.Header>
                  <strong className="mr-auto">
                    <FaCheck className="text-success mr-2" />
                    Copied to Clipboard
                  </strong>
                </Toast.Header>
              </Toast>
            </div>
          </Tab>
          <Tab eventKey="EMBED" title="Embed">
            <Container fluid className="m-2">
              <Row>
                <Col sm={8}>
                  <Row className="p-2">
                    <h3>Preview</h3>
                    <ResponsiveEmbed aspectRatio="16by9">
                      <iframe
                        title="embed"
                        src={urlWithParams}
                        style={{ border: '2px solid lightgrey' }}
                      />
                    </ResponsiveEmbed>
                  </Row>
                  <Row className="p-2">
                    <div>
                      <h3>Embed Code</h3>
                      <p>Copy and paste this code into your web page</p>
                    </div>
                    <Card className="border-0">
                      <span className="d-flex mb-2">
                        <code className="code-snippet border bg-light rounded p-2 mr-4 share-model-link">
                          {iframeCode}
                        </code>
                        <span>
                          <Button onClick={() => this.copyText(iframeCode)}>
                            <FaRegCopy />
                          </Button>
                        </span>
                      </span>
                    </Card>
                  </Row>
                </Col>
                <Col className="m-2">
                  <h3>Options</h3>
                  <p>Adjust the appearance of this embedded iFrame</p>
                  <Form.Group className="pt-2">
                    Size
                    <div className="d-flex align-items-baseline">
                      <Form.Control
                        id="iframeWidth"
                        type="text"
                        placeholder={width.toString()}
                        onChange={(e) =>
                          this.setState({ width: e.target.value })
                        }
                      />
                      <p className="p-1">x</p>
                      <Form.Control
                        id="iframeHeight"
                        type="text"
                        placeholder={height.toString()}
                        onChange={(e) =>
                          this.setState({ height: e.target.value })
                        }
                      />
                    </div>
                  </Form.Group>
                  <InputGroup>
                    Background color
                    <div className="d-flex">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                          #
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <OverlayTrigger
                        overlay={this.renderColorPicker()}
                        trigger="focus"
                      >
                        <Form.Control
                          name="config.backgroundColor"
                          type="text"
                          placeholder={backgroundColor}
                          onChange={(e) =>
                            this.setState({ bgColor: e.target.value })
                          }
                          value={this.state.bgColor}
                        />
                      </OverlayTrigger>
                    </div>
                  </InputGroup>
                  <Form.Check
                    className="pt-4"
                    type="switch"
                    defaultChecked
                    id="nvznlogo-switch"
                    label="Nvzn Logo"
                    onChange={() =>
                      this.setState({ showLogo: !this.state.showLogo })
                    }
                  />
                </Col>
              </Row>
            </Container>
            <div className="toast-container fixed-top mt-2">
              <Toast show={showToast}>
                <Toast.Header>
                  <strong className="mr-auto">
                    <FaCheck className="text-success mr-2" />
                    Copied to Clipboard
                  </strong>
                </Toast.Header>
              </Toast>
            </div>
          </Tab>
        </Tabs>
      </Modal>
    );
  }
}
