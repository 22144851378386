import React, { Component } from 'react';
import { Form, Card, Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { routes } from '../../util';
import { ActionError } from '../../state/actionHelpers';
import { login } from '../../state/actions';
import { State } from '../../state/reducer';

interface ILogInProps extends RouteChildrenProps<never, { referrer: string }> {
  isLoggedIn: boolean;
  login(email, password): void;
  loading: boolean;
  error: ActionError;
}
interface ILogInState {
  email: string;
  password: string;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const mapStateToProps = (state: State) => ({
  isLoggedIn: !!state.VERIFY_TOKEN.data.token,
  loading: state.LOGIN.loading,
  error: state.LOGIN.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(login({ email, password })),
});

class LogIn extends Component<ILogInProps, ILogInState> {
  public state: ILogInState = {
    email: '',
    password: '',
  };

  public componentDidMount() {
    this.redirectIfNeeded();
  }

  public componentDidUpdate() {
    this.redirectIfNeeded();
  }

  private redirectIfNeeded = () => {
    const { history, location, isLoggedIn } = this.props;
    if (isLoggedIn) {
      history.push(location.state?.referrer ?? routes.HOME);
    }
  };

  private handleChange = ({ target }) => {
    const { id, value } = target as { id: 'email' | 'password'; value: string };
    this.setState((state) => {
      return {
        ...state,
        [id]: value,
      };
    });
  };

  private isValid = (): boolean => {
    const { email, password } = this.state;
    return email.length && password.length && EMAIL_REGEX.test(email);
  };

  private handleSubmit = () => {
    const { login } = this.props;
    const { email, password } = this.state;
    if (this.isValid()) {
      login(email, password);
    }
  };

  private renderError = () => {
    const { error } = this.props;
    let rv;
    if (error) {
      rv = (
        <Alert variant="danger" id="login-error">
          {error.message}
        </Alert>
      );
    }
    return rv;
  };

  public render() {
    const { email, password } = this.state;
    const isFormValid = this.isValid();
    const { loading } = this.props;
    return (
      <Row>
        <Helmet>
          <title>Log in | Nvzn Augmented Reality</title>
          <meta name="title" content="Log in | Nvzn Augmented Reality" />
          <meta
            name="description"
            content="Log into Nvzn Augmented Reality to view your models, edit them and publish new ones. Share your experiences with others too via Social Media."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>

        <Col>
          <Card className="login">
            <Card.Header as="h5">Log In</Card.Header>
            <Card.Body>
              {this.renderError()}
              <Form>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.handleChange}
                    value={email}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    value={password}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  id="submit-login-form"
                  disabled={!isFormValid || loading}
                  onClick={isFormValid ? this.handleSubmit : undefined}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const LogInConnected = connect(mapStateToProps, mapDispatchToProps)(LogIn);

export { LogInConnected as LogIn };
