import React, { Component } from 'react';
import {
  AssetMetadata,
  AssetReassignPayload,
  AdminAssetsUuidPayload,
  UserInfo,
} from 'nvzn-models';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import {
  ShareModal,
  DeleteModal,
  ModalType,
  ModelListing,
  SearchableTable,
  ReassignModal,
  DuplicateModal,
} from '../../components';
import {
  getAllAssets,
  getUploadStatus,
  deleteAsset,
  reassignModel,
  getAsset,
  getAllUsers,
  duplicateModal,
} from '../../state/actions';
import { State } from '../../state/reducer';
import { AssetDataModel } from '../../models';
import { getInterpolatedPath, PageName } from '../../util';

interface AllAssetsProps extends RouteChildrenProps {
  assets: AssetMetadata[];
  asset: AssetDataModel;
  fetchAssets(): void;
  fetchAssetData(uuid: string): void;
  fetchAssetUploadStatus(uuid: string): void;
  deleteAsset(uuid: string): void;
  duplicateModal(values: AdminAssetsUuidPayload): void;
  reassignModel(values: AssetReassignPayload): void;
  getAllUsers(): void;
  loading: boolean;
  deleting: boolean;
  isLoggedIn: boolean;
  userDetails: UserInfo;
  duplicateLoading: boolean;
}

interface ModalState {
  type: ModalType;
  uuid: string;
  name: string;
}

interface AllAssetsState {
  modalState: ModalState;
  pollingTimeouts: { [uuid: string]: number };
}

const MAX_ROWS_PER_PAGE = 15;

enum ModelSort {
  CHANGED_DESC = 'recently_changed',
  CHANGED_ASC = 'changed_ASC',
  UPLOAD_DESC = 'recently_uploaded',
  UPLOADED_ASC = 'uploaded_ASC',
  ALPHABETICAL_NAME = 'alphabetical_name',
  NAME_ASC = 'name_ASC',
  ALPHABETICAL_USER = 'alphabetical_user',
  USER_ASC = 'user_ASC',
}

const SortTypeMap = {
  Name: {
    ascSortType: ModelSort.NAME_ASC,
    descSortType: ModelSort.ALPHABETICAL_NAME,
  },
  Username: {
    ascSortType: ModelSort.USER_ASC,
    descSortType: ModelSort.ALPHABETICAL_USER,
  },
  'Date Created': {
    ascSortType: ModelSort.UPLOADED_ASC,
    descSortType: ModelSort.UPLOAD_DESC,
  },
  'Last Edited': {
    ascSortType: ModelSort.CHANGED_ASC,
    descSortType: ModelSort.CHANGED_DESC,
  },
  '': {
    ascSortType: undefined,
    descSortType: undefined,
  },
};

const sortFunctions = {
  [ModelSort.CHANGED_DESC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.lastEditedDate - modelA.lastEditedDate,
  [ModelSort.UPLOAD_DESC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.uploadDate - modelA.uploadDate,
  [ModelSort.CHANGED_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelA.lastEditedDate - modelB.lastEditedDate,
  [ModelSort.UPLOADED_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelA.uploadDate - modelB.uploadDate,
  [ModelSort.ALPHABETICAL_NAME]: (
    modelA: AssetMetadata,
    modelB: AssetMetadata,
  ) => modelA.name.localeCompare(modelB.name),
  [ModelSort.NAME_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.name.localeCompare(modelA.name),
  [ModelSort.ALPHABETICAL_USER]: (
    modelA: AssetMetadata,
    modelB: AssetMetadata,
  ) => modelA.username.localeCompare(modelB.username ?? ''),
  [ModelSort.USER_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.username.localeCompare(modelA.username ?? ''),
};

const mapStateToProps = (state: State) => ({
  assets: state.FETCH_ASSETS.data,
  asset: state.GET_ASSET.data,
  loading: state.FETCH_ASSETS.loading,
  deleting: state.DELETE_ASSET.loading,
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
  userDetails: state.GET_USER.data,
  duplicateLoading: state.ADMIN_DUPLICATE_MODAL.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssets: () => dispatch(getAllAssets()),
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
  fetchAssetUploadStatus: (uuid) =>
    dispatch(getUploadStatus(undefined, { uuid })),
  deleteAsset: (uuid) => dispatch(deleteAsset(undefined, { uuid })),
  reassignModel: ({ uuid, newUserId }: AssetReassignPayload) =>
    dispatch(reassignModel({ uuid, newUserId }, { uuid })),
  getAllUsers: () => dispatch(getAllUsers()),
  duplicateModal: ({ uuid, userId }: AdminAssetsUuidPayload) =>
    dispatch(duplicateModal({ userId, uuid })),
});

class AllAssetsComponent extends Component<AllAssetsProps, AllAssetsState> {
  public state: AllAssetsState = {
    modalState: {
      type: undefined,
      uuid: undefined,
      name: undefined,
    },
    pollingTimeouts: {},
  };

  public componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.fetchAssets();
    }
  }

  public componentDidUpdate(prevProps: AllAssetsProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.fetchAssets();
    } else if (prevProps.loading && !this.props.loading && this.props.assets) {
      this.hydratePollingTimeouts();
    } else if (!this.props.loading && this.props.assets) {
      this.updatePollingTimeouts();
    }
    if (prevProps.deleting && !this.props.deleting) {
      const deletedAssetId = this.state.modalState.uuid;
      this.deletePollingTimeout(deletedAssetId);
      this.handleCloseModal();
    }

    if (prevProps.duplicateLoading && !this.props.duplicateLoading) {
      this.props.fetchAssets();
      this.handleCloseModal();
    }
  }

  public componentWillUnmount() {
    Object.values(this.state.pollingTimeouts).forEach(window.clearInterval);
  }

  private handleOpenModal = (modalState: ModalState) => {
    this.setState({
      modalState,
    });
  };

  private handleDuplicate = () => {
    const { userDetails } = this.props;
    const { uuid } = this.state.modalState;
    this.props.duplicateModal({ uuid, userId: userDetails.uuid });
  };

  private handleCloseModal = () =>
    this.setState({
      modalState: {
        type: undefined,
        uuid: undefined,
        name: undefined,
      },
    });

  private handleConfirmDelete = () => {
    const { uuid } = this.state.modalState;
    this.props.deleteAsset(uuid);
  };

  private handleConfirmReassign = (payload: AssetReassignPayload) => {
    this.props.reassignModel(payload);
    this.handleCloseModal();
  };

  private hydratePollingTimeouts = () => {
    const { assets, fetchAssetUploadStatus } = this.props;
    const pollingTimeouts = assets.reduce((pollingTimeoutsAccum, asset) => {
      if (!asset.isUploadComplete) {
        pollingTimeoutsAccum[asset.uuid] = window.setInterval(
          () => fetchAssetUploadStatus(asset.uuid),
          5000,
        );
      }
      return pollingTimeoutsAccum;
    }, {});
    this.setState({ pollingTimeouts });
  };

  private updatePollingTimeouts = () => {
    const { pollingTimeouts } = this.state;
    let didUpdate = false;
    this.props.assets.forEach((asset) => {
      if (asset.isUploadComplete && pollingTimeouts[asset.uuid] !== undefined) {
        window.clearInterval(pollingTimeouts[asset.uuid]);
        delete pollingTimeouts[asset.uuid];
        didUpdate = true;
      }
    });
    if (didUpdate) {
      this.setState({ pollingTimeouts: { ...pollingTimeouts } });
    }
  };

  private deletePollingTimeout = (deletedAssetId: string) => {
    const { pollingTimeouts } = this.state;
    if (pollingTimeouts[deletedAssetId]) {
      window.clearInterval(pollingTimeouts[deletedAssetId]);
      delete pollingTimeouts[deletedAssetId];
      this.setState({ pollingTimeouts: { ...pollingTimeouts } });
    }
  };

  private onView = ({ uuid }: AssetMetadata) => {
    this.props.history.push(
      getInterpolatedPath(PageName.MODEL_VIEWER, { uuid }),
      {
        referrer: `${this.props.location.pathname}${this.props.location.search}`,
      },
    );
  };
  private onEdit = ({ uuid }: AssetMetadata) => {
    this.props.history.push(
      getInterpolatedPath(PageName.MODEL_EDITOR, { uuid }),
      {
        referrer: `${this.props.location.pathname}${this.props.location.search}`,
      },
    );
  };
  private onUse = ({ uuid, name }: AssetMetadata) => {
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.SHARE,
    });
    this.props.fetchAssetData(uuid);
  };

  private onDelete = ({ uuid, name }: AssetMetadata) =>
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.DELETE,
    });

  private onReassign = ({ uuid, name }: AssetMetadata) =>
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.REASSIGN,
    });

  private onDuplicate = ({ uuid, name }: AssetMetadata) =>
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.DUPLICATE,
    });

  private renderHelmet() {
    return (
      <Helmet>
        <title>All Models | Nvzn Augmented Reality</title>
        <meta name="title" content="All Models | Nvzn Augmented Reality" />
        <meta
          name="description"
          content="Admin dashboard for Nvzn Augmented Reality Models. Manage the models of all of your users here."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }

  private renderRow = (assetData: AssetMetadata) => {
    return (
      <ModelListing
        key={assetData.uuid}
        assetData={assetData}
        onView={() => this.onView(assetData)}
        onEdit={() => this.onEdit(assetData)}
        onUse={() => this.onUse(assetData)}
        onDelete={() => this.onDelete(assetData)}
        onReassign={() => this.onReassign(assetData)}
        onDuplicate={() => this.onDuplicate(assetData)}
        showUsername
        showReassign
      />
    );
  };

  public render() {
    const {
      loading,
      assets,
      deleting,
      userDetails,
      duplicateLoading,
    } = this.props;
    const { modalState } = this.state;
    return (
      <div className="all-assets">
        {this.renderHelmet()}
        <ShareModal
          assetData={this.props.asset}
          show={modalState.type === ModalType.SHARE}
          uuid={modalState.uuid}
          name={modalState.name}
          onClose={this.handleCloseModal}
        />
        <DeleteModal
          show={modalState.type === ModalType.DELETE}
          onClose={this.handleCloseModal}
          itemName={modalState.name}
          onConfirmDelete={this.handleConfirmDelete}
          deleting={deleting}
        />
        <ReassignModal
          uuid={modalState.uuid}
          show={modalState.type === ModalType.REASSIGN}
          onClose={this.handleCloseModal}
          onConfirmReassign={this.handleConfirmReassign}
        />
        <DuplicateModal
          uuid={userDetails.uuid}
          show={modalState.type === ModalType.DUPLICATE}
          onClose={this.handleCloseModal}
          itemName={modalState.name}
          duplicateModal={this.handleDuplicate}
          loading={duplicateLoading}
        />
        <SearchableTable
          loading={loading}
          items={assets}
          headerText="All Models"
          maxItemsPerPage={MAX_ROWS_PER_PAGE}
          renderItem={this.renderRow}
          searchableProperties={['name', 'username']}
          sortFunctonMap={sortFunctions}
          sortableTableHeaders={SortTypeMap}
          initialSort={ModelSort.CHANGED_DESC}
          isModelList={true}
          history={this.props.history}
        />
      </div>
    );
  }
}

export const AllAssets = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllAssetsComponent);
