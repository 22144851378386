import React, { Fragment, Component } from 'react';
import '@google/model-viewer/dist/model-viewer';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Prompt } from 'react-router';
import { Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AssetData, AssetUploadPayload, AR_SCALE } from 'nvzn-models';
import { UploadForm } from './Form';
import { validationSchema } from './validationSchema';
import { PageLoader, ModelPreviewer } from '../../components';
import { ACTION, ActionError } from '../../state/actionHelpers';
import { clearError, editAsset, getAsset } from '../../state/actions';
import { State } from '../../state/reducer';
import { routes } from '../../util';

interface IEditorProps
  extends RouteChildrenProps<{ uuid: string }, { referrer: string }> {
  submit(assetData: AssetUploadPayload): void;
  loading: boolean;
  asset: AssetData;
  loadingAsset: boolean;
  fetchAssetData(uuid: string): void;
  clearError(): void;
  error: ActionError;
}

interface IEditorState {
  willLoseChanges: boolean;
}

const mapStateToProps = (state: State) => ({
  loading: state.EDIT_ASSET.loading,
  asset: state.GET_ASSET.data,
  loadingAsset: state.GET_ASSET.loading,
  error: state.EDIT_ASSET.error,
});

const mapDispatchToProps = (dispatch) => ({
  submit: (payload: AssetUploadPayload) => dispatch(editAsset(payload)),
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
  clearError: () => dispatch(clearError(ACTION.EDIT_ASSET)),
});

class Editor extends Component<IEditorProps, IEditorState> {
  public state = {
    willLoseChanges: false,
  };

  public componentDidMount() {
    this.props.fetchAssetData(this.getUuid());
  }

  private getUuid = (): string => {
    const { uuid } = this.props.match.params;
    return uuid;
  };

  public componentDidUpdate(prevProps: IEditorProps) {
    const { error, loading } = this.props;
    if (prevProps.loading && !loading) {
      if (!error) {
        this.props.history.push(
          this.props.location.state
            ? this.props.location.state.referrer
            : routes.USER_MODELS || routes.USER_MODELS,
        );
      }
    }
  }
  private normalizeProp<ToType>(obj: Partial<AssetUploadPayload>): ToType {
    return ({
      ...obj,
      files: {
        ...(obj.files || {}),
      },
      config: {
        ...(obj.config || {}),
        alignModel: {
          ...(obj.config?.alignModel || {}),
        },
        cameraOrbit: {
          ...(obj.config?.cameraOrbit || {}),
        },
        minCameraOrbit: {
          ...(obj.config?.minCameraOrbit || {}),
        },
        maxCameraOrbit: {
          ...(obj.config?.maxCameraOrbit || {}),
        },
        cameraTarget: {
          ...(obj.config?.cameraTarget || {}),
        },
        fieldOfView: {
          ...(obj.config?.fieldOfView || {}),
        },
        modelInfo: {
          ...(obj.config?.modelInfo || {}),
        },
      },
    } as unknown) as ToType;
  }
  private handleSubmit = (values: AssetUploadPayload): void => {
    this.setState(
      {
        willLoseChanges: false,
      },
      () => this.props.submit(values),
    );
  };
  private handleCancel = (): void => {
    this.props.history.push(
      this.props.location.state.referrer || routes.USER_MODELS,
    );
  };
  private updateDidChange = (): void => {
    const { willLoseChanges } = this.state;
    if (!willLoseChanges) {
      this.setState({
        willLoseChanges: true,
      });
    }
  };
  private getInitialValues = (): AssetUploadPayload => {
    const { asset } = this.props;
    const { config, uuid, lastEditedDate } = asset;
    return {
      uuid,
      lastEditedDate,
      files: {
        glb: {
          type: 'file/glb',
          name: `${config.glbFileName}`,
          size: 0,
        },

        usdz: {
          type: 'file/usdz',
          name: `${config.usdzFileName}`,
          size: 0,
        },
        backgroundImage: config.backgroundImageType
          ? {
              type: `file/${config.backgroundImageType}`,
              name: `Background.${config.backgroundImageType}`,
              size: 0,
            }
          : undefined,
        environmentalImage: config.environmentalImageType
          ? {
              type: `file/${config.environmentalImageType}`,
              name: `Environment.${config.environmentalImageType}`,
              size: 0,
            }
          : undefined,
      },
      config,
    };
  };
  private renderErrorModal() {
    const { error, clearError } = this.props;
    return (
      <Modal show={!!error}>
        <Modal.Body>{`${error?.message}`}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  private renderForm() {
    return (
      <Formik
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        initialValues={this.getInitialValues()}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
          setFieldValue,
        }: FormikProps<AssetUploadPayload>) => {
          return (
            <Row noGutters>
              <Col xs={6} lg={7} xl={8}>
                <ModelPreviewer
                  metadata={values}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />
              </Col>
              <Col xs={6} lg={5} xl={4} className="editor__side-pane">
                <UploadForm
                  editMode={true}
                  handleSubmit={handleSubmit}
                  handleCancel={this.handleCancel}
                  handleChange={(e) => {
                    handleChange(e);
                    this.updateDidChange();
                    if (
                      e.target.id === 'files.backgroundImage' &&
                      !e.target.value
                    ) {
                      setFieldValue('config.backgroundImageType', '');
                    } else if (
                      e.target.id === 'files.environmentalImage' &&
                      !e.target.value
                    ) {
                      setFieldValue('config.environmentalImageType', '');
                    } else if (e.target.id === 'config.arScale') {
                      setFieldValue(
                        'config.arScale',
                        e.target.checked ? AR_SCALE.fixed : AR_SCALE.auto,
                      );
                    }
                  }}
                  handleBlur={handleBlur}
                  values={values}
                  touched={this.normalizeProp<
                    FormikTouched<AssetUploadPayload>
                  >((touched as unknown) as Partial<AssetUploadPayload>)}
                  isValid={isValid}
                  errors={this.normalizeProp<FormikErrors<AssetUploadPayload>>(
                    (errors as unknown) as Partial<AssetUploadPayload>,
                  )}
                  isSubmitting={isSubmitting}
                />
              </Col>
            </Row>
          );
        }}
      </Formik>
    );
  }
  public render() {
    const { loadingAsset, asset, loading } = this.props;
    const { willLoseChanges } = this.state;
    return (
      <Fragment>
        {(loadingAsset || loading || !asset?.uuid) && <PageLoader />}
        {!loadingAsset && asset?.uuid && this.renderForm()}
        {this.renderErrorModal()}
        <Prompt
          message="Your changes will not be saved. Are you sure you want to cancel?"
          when={willLoseChanges}
        />
      </Fragment>
    );
  }
}

const EditorConnected = connect(mapStateToProps, mapDispatchToProps)(Editor);

export { EditorConnected as Editor };
