import React, { Component } from 'react';
import { AssetMetadata, UserInfo } from 'nvzn-models';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import {
  ShareModal,
  IntegrationModal,
  DeleteModal,
  ModalType,
  ModelListing,
  SearchableTable,
} from '../../components';
import {
  fetchAssetsForUser,
  getUploadStatus,
  deleteAsset,
  getAsset,
} from '../../state/actions';
import { State } from '../../state/reducer';
import { AssetDataModel } from '../../models';
import { routes, getInterpolatedPath, PageName } from '../../util';

interface UserAssetsProps extends RouteChildrenProps {
  assets: AssetMetadata[];
  asset: AssetDataModel;
  fetchAssets(): void;
  fetchAssetUploadStatus(uuid: string): void;
  deleteAsset(uuid: string): void;
  loading: boolean;
  deleting: boolean;
  isLoggedIn: boolean;
  userInfo: UserInfo;
  fetchAssetData(uuid: string): void;
}

interface ModalState {
  type: ModalType;
  uuid: string;
  name: string;
}

interface UserAssetsState {
  modalState: ModalState;
  pollingTimeouts: { [uuid: string]: number };
}

const MAX_ROWS_PER_PAGE = 15;

enum ModelSort {
  CHANGED_DESC = 'CHANGED_DESC',
  CHANGED_ASC = 'CHANGED_ASC',
  UPLOAD_DESC = 'UPLOAD_DESC',
  UPLOADED_ASC = 'UPLOADED_ASC',
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
}

const SortTypeMap = {
  Name: {
    ascSortType: ModelSort.NAME_ASC,
    descSortType: ModelSort.NAME_DESC,
  },
  'Date Created': {
    ascSortType: ModelSort.UPLOADED_ASC,
    descSortType: ModelSort.UPLOAD_DESC,
  },
  'Last Edited': {
    ascSortType: ModelSort.CHANGED_ASC,
    descSortType: ModelSort.CHANGED_DESC,
  },
  '': {
    ascSortType: undefined,
    descSortType: undefined,
  },
};

const sortFunctions = {
  [ModelSort.CHANGED_DESC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.lastEditedDate - modelA.lastEditedDate,
  [ModelSort.UPLOAD_DESC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.uploadDate - modelA.uploadDate,
  [ModelSort.CHANGED_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelA.lastEditedDate - modelB.lastEditedDate,
  [ModelSort.UPLOADED_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelA.uploadDate - modelB.uploadDate,
  [ModelSort.NAME_DESC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelA.name.localeCompare(modelB.name),
  [ModelSort.NAME_ASC]: (modelA: AssetMetadata, modelB: AssetMetadata) =>
    modelB.name.localeCompare(modelA.name),
};

const mapStateToProps = (state: State) => ({
  assets: state.FETCH_ASSETS.data,
  asset: state.GET_ASSET.data,
  loading: state.FETCH_ASSETS.loading,
  deleting: state.DELETE_ASSET.loading,
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
  userInfo: state.GET_USER.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssets: () => dispatch(fetchAssetsForUser()),
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
  fetchAssetUploadStatus: (uuid) =>
    dispatch(getUploadStatus(undefined, { uuid })),
  deleteAsset: (uuid) => dispatch(deleteAsset(undefined, { uuid })),
});

class UserAssets extends Component<UserAssetsProps, UserAssetsState> {
  public state: UserAssetsState = {
    modalState: {
      type: undefined,
      uuid: undefined,
      name: undefined,
    },
    pollingTimeouts: {},
  };

  public componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.fetchAssets();
    }
  }

  public componentDidUpdate(prevProps: UserAssetsProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.fetchAssets();
    } else if (prevProps.loading && !this.props.loading && this.props.assets) {
      this.hydratePollingTimeouts();
    } else if (!this.props.loading && this.props.assets) {
      this.updatePollingTimeouts();
    }
    if (prevProps.deleting && !this.props.deleting) {
      const deletedAssetId = this.state.modalState.uuid;
      this.deletePollingTimeout(deletedAssetId);
      this.handleCloseModal();
    }
  }

  public componentWillUnmount() {
    Object.values(this.state.pollingTimeouts).forEach(window.clearInterval);
  }

  private handleOpenModal = (modalState: ModalState) => {
    this.setState({
      modalState,
    });
  };

  private handleCloseModal = () =>
    this.setState({
      modalState: {
        type: undefined,
        uuid: undefined,
        name: undefined,
      },
    });

  private handleConfirmDelete = () => {
    const { uuid } = this.state.modalState;
    this.props.deleteAsset(uuid);
  };

  private hydratePollingTimeouts = () => {
    const { assets, userInfo, fetchAssetUploadStatus } = this.props;
    const pollingTimeouts = assets.reduce((pollingTimeoutsAccum, asset) => {
      if (!asset.isUploadComplete && asset.userId === userInfo.uuid) {
        pollingTimeoutsAccum[asset.uuid] = window.setInterval(
          () => fetchAssetUploadStatus(asset.uuid),
          5000,
        );
      }
      return pollingTimeoutsAccum;
    }, {});
    this.setState({ pollingTimeouts });
  };

  private updatePollingTimeouts = () => {
    const { pollingTimeouts } = this.state;
    let didUpdate = false;
    this.props.assets.forEach((asset) => {
      if (asset.isUploadComplete && pollingTimeouts[asset.uuid] !== undefined) {
        window.clearInterval(pollingTimeouts[asset.uuid]);
        delete pollingTimeouts[asset.uuid];
        didUpdate = true;
      }
    });
    if (didUpdate) {
      this.setState({ pollingTimeouts: { ...pollingTimeouts } });
    }
  };

  private deletePollingTimeout = (deletedAssetId: string) => {
    const { pollingTimeouts } = this.state;
    if (pollingTimeouts[deletedAssetId]) {
      window.clearInterval(pollingTimeouts[deletedAssetId]);
      delete pollingTimeouts[deletedAssetId];
      this.setState({ pollingTimeouts: { ...pollingTimeouts } });
    }
  };

  private onUpload = () =>
    this.props.history.push(routes.MODEL_UPLOADER, {
      referrer: `${this.props.location.pathname}${this.props.location.search}`,
    });
  private onView = ({ uuid }: AssetMetadata) =>
    this.props.history.push(
      getInterpolatedPath(PageName.MODEL_VIEWER, { uuid }),
      {
        referrer: `${this.props.location.pathname}${this.props.location.search}`,
      },
    );
  private onEdit = ({ uuid }: AssetMetadata) =>
    this.props.history.push(
      getInterpolatedPath(PageName.MODEL_EDITOR, { uuid }),
      {
        referrer: `${this.props.location.pathname}${this.props.location.search}`,
      },
    );
  private onUse = ({ uuid, name }: AssetMetadata) => {
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.SHARE,
    });
    this.props.fetchAssetData(uuid);
  };
  private onDelete = ({ uuid, name }: AssetMetadata) =>
    this.handleOpenModal({
      name,
      uuid,
      type: ModalType.DELETE,
    });
  private onIntegrationHelp = () =>
    this.handleOpenModal({
      name: undefined,
      uuid: undefined,
      type: ModalType.INTEGRATION,
    });

  private renderHelmet() {
    return (
      <Helmet>
        <title>My Models | Nvzn Augmented Reality</title>
        <meta name="title" content="Models | Nvzn Augmented Reality" />
        <meta
          name="description"
          content="Your personal dashboard for Nvzn Augmented Reality. Manage all of your published models here. Create new models or edit the ones already published."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }

  private renderRow = (assetData: AssetMetadata) => {
    return (
      <ModelListing
        key={assetData.uuid}
        assetData={assetData}
        onView={() => this.onView(assetData)}
        onEdit={() => this.onEdit(assetData)}
        onUse={() => this.onUse(assetData)}
        onDelete={() => this.onDelete(assetData)}
      />
    );
  };

  private renderActions = () => {
    return (
      <ButtonToolbar>
        <Button onClick={this.onUpload} id="upload-asset" className="mr-2">
          Upload
        </Button>
      </ButtonToolbar>
    );
  };

  public render() {
    const { loading, assets, deleting } = this.props;
    const { modalState } = this.state;
    return (
      <div className="user-assets">
        {this.renderHelmet()}
        <ShareModal
          assetData={this.props.asset}
          show={modalState.type === ModalType.SHARE}
          uuid={modalState.uuid}
          name={modalState.name}
          onClose={this.handleCloseModal}
        />
        <IntegrationModal
          show={modalState.type === ModalType.INTEGRATION}
          onClose={this.handleCloseModal}
        />
        <DeleteModal
          show={modalState.type === ModalType.DELETE}
          onClose={this.handleCloseModal}
          itemName={modalState.name}
          onConfirmDelete={this.handleConfirmDelete}
          deleting={deleting}
        />
        <SearchableTable
          loading={loading}
          items={assets}
          headerText="My Models"
          maxItemsPerPage={MAX_ROWS_PER_PAGE}
          renderItem={this.renderRow}
          searchableProperties={['name']}
          sortFunctonMap={sortFunctions}
          sortableTableHeaders={SortTypeMap}
          additionalActions={this.renderActions()}
          initialSort={ModelSort.CHANGED_DESC}
          isModelList={true}
          history={this.props.history}
        />
      </div>
    );
  }
}

const UserAssetsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAssets);

export { UserAssetsConnected as UserAssets };
