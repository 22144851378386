import React, { Component } from 'react';
import { Form, Col, Alert, ColProps, Container, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Paginator } from './Paginator';
import { PageLoader } from '../PageLoader';

type SortFunction<ItemType> = (
  itemA: ItemType,
  itemB: ItemType,
  seed?: number,
) => number;
type RenderFunction<ItemType> = (item: ItemType) => JSX.Element;
type SearchableProperty<ItemType> = Extract<keyof ItemType, string>;
function isSearchableProperty<ItemType>(
  key: keyof ItemType,
  value: unknown,
): key is SearchableProperty<ItemType> {
  return typeof value === 'string';
}

type SortFunctionMap<SortTypeEnum extends string, ItemType> = {
  [sortType in SortTypeEnum]: SortFunction<ItemType>;
};

type SortTypeMap<SortTypeEnum extends string> = {
  [sortType in SortTypeEnum]: string;
};

interface SearchableContentProps<SortTypeEnum extends string, ItemType> {
  headerText: string;
  sortTypeEnum?: SortTypeMap<SortTypeEnum>;
  sortFunctonMap: SortFunctionMap<SortTypeEnum, ItemType>;
  items: ItemType[];
  searchableProperties: SearchableProperty<ItemType>[];
  renderItem: RenderFunction<ItemType>;
  maxItemsPerPage: number;
  loading: boolean;
  additionalActions?: JSX.Element;
  initialSort?: SortTypeEnum;
  isModelList: boolean;
  history: RouteComponentProps['history'];
  initialSeed?: number;
}

interface SearchableContentState<SortTypeEnum extends string> {
  searchText: string;
  sortBy: SortTypeEnum;
  seed: number;
  pageNumber: number;
}

export class SearchableContent<
  SortTypeEnum extends string,
  ItemType,
  ExtraProps = {}
> extends Component<
  SearchableContentProps<SortTypeEnum, ItemType> & ExtraProps,
  SearchableContentState<SortTypeEnum>
> {
  private sortedList;
  public constructor(
    props: SearchableContentProps<SortTypeEnum, ItemType> & ExtraProps,
  ) {
    super(props);
    const { sortTypeEnum, initialSort, initialSeed } = props;
    const [firstSortType] = Object.keys(sortTypeEnum ?? {});
    const { searchText, pageNumber, sortBy, seed } = this.filtersData();
    this.addArgsToURL({
      ...this.state,
      searchText: searchText,
      pageNumber: pageNumber,
      sortBy: sortBy,
      seed: seed,
    });
    this.sortedList = null;
    this.state = {
      searchText: searchText || '',
      sortBy:
        (sortBy as SortTypeEnum) ||
        (initialSort ?? (firstSortType as SortTypeEnum)),
      pageNumber: pageNumber,
      seed: seed || initialSeed,
    };
  }
  public static defaultProps = {
    isModelList: false,
    history: {},
  };
  private onUpdateSearch = (event) => {
    this.addArgsToURL({
      ...this.state,
      searchText: event.target.value,
      pageNumber: 0,
    });
    //this.setState({ searchText: event.target.value, pageNumber: 0 });
  };
  public componentDidUpdate = () => {
    //TODO: Generalize for all filters

    const { searchText, pageNumber, sortBy } = this.filtersData();
    if (
      searchText !== this.state.searchText ||
      pageNumber !== this.state.pageNumber ||
      sortBy !== this.state.sortBy
    ) {
      this.setState({
        searchText: searchText,
        sortBy: sortBy,
        pageNumber: pageNumber,
      });
    }
  };

  private addArgsToURL({
    searchText = '',
    pageNumber = 0,
    sortBy = this.props.initialSort as SortTypeEnum,
    seed = this.props.initialSeed as number,
  }) {
    //TODO: Generalize to add more filters
    if (Object.entries(this.props.history).length !== 0)
      this.props.history.replace({
        search: `?search=${searchText}&page=${pageNumber}&sortBy=${sortBy}&seed=${seed}`,
        state: { isActive: true },
      });
  }
  private filtersData = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchText = urlParams.get('search') || '';
    const pageNumber = parseInt(urlParams.get('page')) || 0;
    const seed = parseFloat(urlParams.get('seed')) || Math.random();
    const sortBy =
      (urlParams.get('sortBy') as SortTypeEnum) || this.props.initialSort;
    return {
      searchText: searchText,
      pageNumber: pageNumber,
      sortBy: sortBy,
      seed: seed,
    };
  };
  protected setSort = (sortBy) => {
    const newSeed = Math.random();
    this.addArgsToURL({ ...this.state, sortBy, pageNumber: 0, seed: newSeed });
    this.setState({ sortBy, pageNumber: 0, seed: newSeed });
  };
  private onSelectSort = (event) => {
    this.setSort(event.target.value);
  };
  private onSelectPage = (pageNumber: number) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.addArgsToURL({ ...this.state, pageNumber });
    this.setState({ pageNumber });
  };

  private getFilteredItems = (): ItemType[] => {
    const { items, searchableProperties } = this.props;
    const { searchText } = this.state;
    const lowerCaseSearchText = searchText.toLowerCase();
    return items.filter((item) => {
      return Object.entries(item).some(([key, value]) => {
        let rv = false;
        if (
          isSearchableProperty(key, value) &&
          searchableProperties.includes(key as SearchableProperty<ItemType>)
        ) {
          const lowerCaseValue = value.toLocaleLowerCase();
          rv = lowerCaseValue.includes(lowerCaseSearchText);
        }
        return rv;
      });
    });
  };

  private getSortedItems = (): ItemType[] => {
    const { sortBy, seed } = this.state;
    const { sortFunctonMap } = this.props;
    this.sortedList =
      sortBy === 'random'
        ? this.getFilteredItems().sort((modelA, modelB): number =>
            sortFunctonMap[sortBy](modelA, modelB, seed),
          )
        : this.getFilteredItems().sort(sortFunctonMap[sortBy]);
    return this.sortedList;
  };

  private getPageItems = (): ItemType[] => {
    const { maxItemsPerPage } = this.props;
    const { pageNumber } = this.state;
    const startingIndex = pageNumber * maxItemsPerPage;
    const endingIndex = startingIndex + maxItemsPerPage;
    return this.getSortedItems().slice(startingIndex, endingIndex);
  };

  protected renderHeader() {
    const { headerText, additionalActions, sortTypeEnum } = this.props;
    let additionalActionsCol;
    if (additionalActions) {
      additionalActionsCol = (
        <Col xs="12" lg="auto">
          {additionalActions}
        </Col>
      );
    }
    let sortDropdownCol;
    let searchColWidthXS: ColProps['xs'] = '12';
    if (sortTypeEnum) {
      sortDropdownCol = (
        <Col xs="4" lg="auto">
          {this.renderSortDropdown()}
        </Col>
      );
      searchColWidthXS = '8';
    }
    return (
      <Form className="searchable-content__header mt-4 mb-4 p-0">
        <Form.Row>
          <Col xs="12" lg>
            <h3 className="text-dark text-center text-lg-left mb-3">
              {headerText}
            </h3>
          </Col>
          {sortDropdownCol}
          <Col xs={searchColWidthXS} lg="auto">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={this.onUpdateSearch}
              className="searchable-content__search"
              value={this.state.searchText}
            />
          </Col>
          {additionalActionsCol}
        </Form.Row>
      </Form>
    );
  }

  private renderSortDropdown() {
    const { sortTypeEnum } = this.props;
    const { sortBy } = this.state;
    const options = Object.entries(sortTypeEnum).map(
      ([sortTypeValue, sortTypeLabel]) => (
        <option value={sortTypeValue} key={sortTypeValue}>
          {sortTypeLabel}
        </option>
      ),
    );
    return (
      <Form.Control
        as="select"
        className="mr-3"
        onChange={this.onSelectSort}
        value={sortBy}
      >
        {options}
      </Form.Control>
    );
  }

  protected renderItems = () => {
    const { loading, renderItem } = this.props;
    const visibleItems = this.getPageItems();
    let rv = null;
    if (loading) {
      rv = <PageLoader />;
    } else if (visibleItems.length) {
      rv = visibleItems.map(renderItem);
    } else {
      rv = (
        <Alert variant="secondary" className="text-center">
          No items found
        </Alert>
      );
    }
    return rv;
  };

  protected renderPaginator() {
    const { maxItemsPerPage } = this.props;
    const { pageNumber } = this.state;
    const numPages = Math.ceil(
      this.getFilteredItems().length / maxItemsPerPage,
    );

    return (
      <Paginator
        numPages={numPages}
        currentPage={pageNumber}
        onSelectPage={this.onSelectPage}
      />
    );
  }
  public render() {
    return (
      <Container className="searchable-content">
        {this.renderHeader()}
        <Container className="searchable-content__items p-0">
          <Row>{this.renderItems()}</Row>
        </Container>
        {this.renderPaginator()}
      </Container>
    );
  }
}
