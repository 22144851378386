import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AssetMetadata } from 'nvzn-models';
import { Card } from 'react-bootstrap';
import { config, PageName, getInterpolatedPath } from '../../util';
import noThumbnail from '../../assets/no-thumbnail.jpg';

export interface ModelCardProps {
  assetData: AssetMetadata;
}

export function ModelCard({ assetData }: ModelCardProps) {
  const generateImageLink = useCallback(() => {
    return `${config.cdnUrl}/${assetData.uuid}/thumbnail.png?v=${assetData.lastEditedDate}`;
  }, [assetData.lastEditedDate, assetData.uuid]);

  const [imgSrc, setImgSrc] = useState(generateImageLink());

  useEffect(() => {
    if (!assetData.isUploadComplete) setImgSrc(noThumbnail);
    else setImgSrc(generateImageLink());
  }, [generateImageLink, assetData.isUploadComplete]);

  const { name, desc, uuid } = assetData;
  return (
    <Card
      className="model-card text-body mb-4"
      id={uuid}
      as={Link}
      to={{
        pathname: getInterpolatedPath(PageName.MODEL_VIEWER, { uuid }),
        state: {
          referrer: `${window.location.pathname}${window.location.search}`,
        },
      }}
    >
      <Card.Img className="model-card__image" variant="top" src={imgSrc} />
      <Card.Body className="d-flex flex-column">
        <Card.Title>{name}</Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
}
