// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { PageLoader } from '../../components';
import { AssetDataModel } from '../../models';
import { getAsset } from '../../state/actions';
import { State } from '../../state/reducer';

interface ModelPosterCaptureViewProps extends RouteChildrenProps {
  assetData: AssetDataModel;
  loading: boolean;
  fetchAssetData(uuid: string): void;
}

const mapStateToProps = (state: State) => ({
  assetData: state.GET_ASSET.data,
  loading: state.GET_ASSET.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssetData: (uuid: string) => dispatch(getAsset(undefined, { uuid })),
});

class ModelPosterCaptureViewClass extends Component<
  ModelPosterCaptureViewProps,
  never
> {
  public componentDidMount() {
    this.props.fetchAssetData(this.getUuid());
  }

  private getUuid = (): string => {
    const { uuid } = this.props.match.params as { uuid: string };
    return uuid;
  };

  private renderModelViewer() {
    const { assetData } = this.props;
    const environmentalImage = assetData.environmentalImageSrc;
    const skybox = assetData.backgroundImageSrc;
    return (
      <model-viewer
        id="model-viewer"
        class="model-viewer__model model-viewer__model--fullscreen"
        style={{
          backgroundColor: assetData.backgroundColor,
          '--poster-color': 'transparent',
        }}
        src={`${assetData.glbSrc}-${assetData.lastEditedDate}`}
        camera-target={assetData.cameraTarget}
        shadow-intensity={assetData.shadowIntensity}
        shadow-softness={assetData.shadowSoftness}
        camera-orbit={assetData.cameraOrbit}
        field-of-view={assetData.fov}
        exposure={assetData.exposure}
        min-camera-orbit={assetData.minCameraOrbit}
        max-camera-orbit={assetData.maxCameraOrbit}
        ar-placement={assetData.arPlacement}
        interaction-prompt="none"
        minimumRenderScale={1}
        skybox-image={skybox}
        environment-image={environmentalImage}
      ></model-viewer>
    );
  }
  public render() {
    const { loading, assetData } = this.props;
    let rv = <PageLoader />;
    if (!loading && assetData) {
      rv = this.renderModelViewer();
    }
    return rv;
  }
}

export const ModelPosterCaptureView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModelPosterCaptureViewClass);
