import React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';

interface PaginatorProps {
  numPages: number;
  currentPage: number;
  onSelectPage(newPage: number): void;
}

const NUM_ADJACENT_OPTIONS = 2;
const MAX_NUM_OPTIONS = NUM_ADJACENT_OPTIONS * 2 + 1;

export function Paginator({
  numPages,
  currentPage,
  onSelectPage,
}: PaginatorProps) {
  const shouldDispalyFirstAndLast = numPages > MAX_NUM_OPTIONS;
  function renderFirst() {
    let firstButton;
    const isOutOfRange = currentPage - NUM_ADJACENT_OPTIONS > 0;
    if (isOutOfRange && shouldDispalyFirstAndLast) {
      firstButton = (
        <ButtonGroup className="mr-2">
          <Button
            onClick={() => onSelectPage(0)}
            active={currentPage === 0}
            variant="dark"
          >
            1
          </Button>
        </ButtonGroup>
      );
    }
    return firstButton;
  }
  function renderLast() {
    let lastButton;
    const isOutOfRange =
      currentPage + NUM_ADJACENT_OPTIONS < numPages - NUM_ADJACENT_OPTIONS + 1;
    if (isOutOfRange && shouldDispalyFirstAndLast) {
      const lastPageIndex = numPages - 1;
      lastButton = (
        <ButtonGroup className="ml-2">
          <Button
            onClick={() => onSelectPage(lastPageIndex)}
            active={currentPage === lastPageIndex}
            variant="dark"
          >
            {numPages}
          </Button>
        </ButtonGroup>
      );
    }
    return lastButton;
  }
  function renderOptions() {
    const startingIndex = Math.max(currentPage - NUM_ADJACENT_OPTIONS, 0);
    const maximumEndingIndex = startingIndex + MAX_NUM_OPTIONS;
    const endingIndex = Math.min(maximumEndingIndex, numPages);
    const pagesToPrepend = maximumEndingIndex - endingIndex;
    const adjustedStartingIndex = Math.max(startingIndex - pagesToPrepend, 0);
    let options = [];
    for (
      let optionIndex = adjustedStartingIndex;
      optionIndex < endingIndex;
      optionIndex++
    ) {
      options.push(
        <Button
          key={optionIndex}
          onClick={() => onSelectPage(optionIndex)}
          active={currentPage === optionIndex}
          variant="dark"
        >
          {optionIndex + 1}
        </Button>,
      );
    }
    return <ButtonGroup>{options}</ButtonGroup>;
  }

  let paginator = null;
  if (numPages > 1) {
    paginator = (
      <ButtonToolbar className="justify-content-center mt-4 mb-4">
        {renderFirst()}
        {renderOptions()}
        {renderLast()}
      </ButtonToolbar>
    );
  }
  return paginator;
}
