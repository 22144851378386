import { UserRole, UserInfo } from 'nvzn-models';
import React, { Fragment, useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../state/actions';
import { State } from '../state/reducer';
import { routes } from '../util';
import { LogoHorizontal } from '../assets';
import './index.css';

interface AppHeaderProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
  logout(): void;
  username: string;
  userDetails: UserInfo;
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
  isAdmin: state.GET_USER.data.role !== UserRole.PUBLISHER,
  username: state.GET_USER.data.username,
  userDetails: state.GET_USER.data,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

function AppHeaderComponent({
  isLoggedIn,
  username,
  isAdmin,
  logout,
  userDetails,
}: AppHeaderProps) {
  const [isMobileView, setIsMobileView] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      setIsMobileView(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 1200;

        if (ismobile !== isMobileView) setIsMobileView(ismobile);
      },
      false,
    );
  }, [isMobileView]);

  function renderLogo() {
    return (
      <Navbar.Brand as={Link} to={routes.HOME} className="app-header__brand">
        <img
          src={LogoHorizontal}
          alt="NVZN Logo"
          className="app-header__logo"
        />
      </Navbar.Brand>
    );
  }

  function renderAdminLinks() {
    let adminLinks = (
      <Nav.Item>
        <NavDropdown
          title="Admin"
          id="nav-admin-dropdown"
          className="app-header__admin"
        >
          <NavDropdown.Item
            eventKey="0"
            as={Link}
            //TODO: Change this to proper route from routes object
            to={`/user/edit/${userDetails.uuid}`}
            className="app-header__admin-all-users"
          >
            Account
          </NavDropdown.Item>
          {isAdmin && (
            <>
              {' '}
              <NavDropdown.Item
                eventKey="1"
                as={Link}
                to={routes.ADMIN_ALL_USERS}
                className="app-header__admin-all-users"
              >
                All Users
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="2"
                as={Link}
                to={routes.ADMIN_ALL_MODELS}
                className="app-header__admin-all-models"
              >
                All Models
              </NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav.Item>
    );
    return adminLinks;
  }

  function renderPublisherLinks() {
    let publisherLinks;
    if (isLoggedIn) {
      publisherLinks = (
        <Fragment>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={routes.USER_MODELS}
              className="app-header__user-models"
            >
              My Models
            </Nav.Link>
          </Nav.Item>
          {renderAdminLinks()}
        </Fragment>
      );
    }
    return <Nav>{publisherLinks}</Nav>;
  }

  function renderAccountLinks() {
    let accountLinks = (
      <>
        <Nav.Item>
          <a
            href="https://www.nvzn.net/pricing"
            target="_blank"
            className={
              isMobileView
                ? 'app-header__subscribe_mobile mr-3'
                : 'app-header__subscribe'
            }
          >
            Pricing
          </a>
        </Nav.Item>
        <Nav.Item>
          <Navbar.Text>
            <a
              href="https://www.nvzn.net/contact"
              target="_blank"
              className="app-header__contact-in-button"
            >
              Contact
            </a>
          </Navbar.Text>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            to={routes.LOG_IN}
            className="app-header__sign-in-button"
          >
            Log In
          </Nav.Link>
        </Nav.Item>
      </>
    );
    if (isLoggedIn) {
      accountLinks = (
        <Fragment>
          <Nav.Item>
            <Navbar.Text className="app-header__username mr-3">
              {username}
            </Navbar.Text>
          </Nav.Item>
          <Nav.Item>
            <Navbar.Text className="app-header__support mr-2">
              <a className="mailto" href="mailto:support@nvzn.net">
                Support
              </a>
            </Navbar.Text>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={logout} className="app-header__log-out-button">
              Log Out
            </Nav.Link>
          </Nav.Item>
        </Fragment>
      );
    }
    return <Nav>{accountLinks}</Nav>;
  }
  return (
    <Navbar className="app-header" bg="dark" expand="xl" variant="dark">
      {renderLogo()}
      <Navbar.Toggle aria-controls="mobile-open-menu" />
      <Navbar.Collapse
        id="mobile-open-menu"
        className="justify-content-between"
      >
        {renderPublisherLinks()}
        {renderAccountLinks()}
      </Navbar.Collapse>
    </Navbar>
  );
}

export const AppHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppHeaderComponent);
