import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { PageNotFound } from './PageNotFound';
import { EditUser, AllUsers, AllAssets, InviteUser, Dashboard } from './admin';
import { LogIn, SignUp } from './auth';
import { PublicModelViewer, ModelPosterCaptureView } from './modelViewers';
import { Upload, Editor } from './uploader';
import { UserAssets } from './user';
import { PrivateRoute, AppHeader } from '../components';
import { routes } from '../util';

export function Routes() {
  // TODO REMOVE DUPLICATE SIGN UP ROUTE ONCE BE IS USING QUERRY PARAMS IN PROD
  function renderContainerRoutes() {
    return (
      <Switch>
        <Route component={Home} path={routes.HOME} exact />
        <Route component={LogIn} path={routes.LOG_IN} exact />
        <Route component={SignUp} path="/signup/:email/:code" exact />
        <Route component={SignUp} path={routes.SIGN_UP} exact />
        <PrivateRoute component={Upload} path={routes.MODEL_UPLOADER} exact />
        <PrivateRoute component={Editor} path={routes.MODEL_EDITOR} exact />
        <PrivateRoute component={UserAssets} path={routes.USER_MODELS} exact />
        <PrivateRoute component={Dashboard} path={routes.DASHBOARD} exact />
        <PrivateRoute
          component={AllAssets}
          path={routes.ADMIN_ALL_MODELS}
          exact
        />
        <PrivateRoute
          component={AllUsers}
          path={routes.ADMIN_ALL_USERS}
          exact
        />
        <PrivateRoute
          component={EditUser}
          path={routes.ADMIN_EDIT_USER}
          exact
        />
        <PrivateRoute
          component={InviteUser}
          path={routes.ADMIN_INVITE_USER}
          exact
        />
        <PrivateRoute component={EditUser} path={routes.EDIT_USER} exact />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
  function renderAppContainerRoute() {
    return (
      <Route path="/">
        <AppHeader />
        <Container className="content p-0" fluid>
          <Row noGutters>
            <Col>{renderContainerRoutes()}</Col>
          </Row>
        </Container>
      </Route>
    );
  }
  return (
    <div>
      <Switch>
        <Route component={PublicModelViewer} path="/models/view/:uuid" />
        <Route
          component={ModelPosterCaptureView}
          path="/models/preview/:uuid"
        />
        {renderAppContainerRoute()}
      </Switch>
    </div>
  );
}
