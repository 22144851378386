import { UserRole } from 'nvzn-models';

export enum PageName {
  HOME = 'HOME',
  SIGN_UP = 'SIGN_UP',
  LOG_IN = 'LOG_IN',
  USER_MODELS = 'USER_MODELS',
  MODEL_VIEWER = 'MODEL_VIEWER',
  MODEL_CAPTURE_VIEW = 'MODEL_CAPTURE_VIEW',
  MODEL_EDITOR = 'MODEL_EDITOR',
  MODEL_UPLOADER = 'MODEL_UPLOADER',
  ADMIN_ALL_USERS = 'ADMIN_ALL_USERS',
  ADMIN_ALL_MODELS = 'ADMIN_ALL_MODELS',
  ADMIN_EDIT_USER = 'ADMIN_EDIT_USER',
  ADMIN_INVITE_USER = 'ADMIN_INVITE_USER',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  DASHBOARD = 'DASHBOARD',
  EDIT_USER = 'EDIT_USER',
}
type Routes = {
  [page in PageName]: string;
};
// IF YOU CHANGE THESE, DON'T FORGET TO UPDATE PATHS IN END TO ENDS!
export const routes: Routes = {
  [PageName.HOME]: '/',
  [PageName.SIGN_UP]: '/signup',
  [PageName.LOG_IN]: '/login',
  [PageName.USER_MODELS]: '/models',
  [PageName.MODEL_VIEWER]: '/models/view/:uuid',
  [PageName.MODEL_CAPTURE_VIEW]: '/models/preview/:uuid',
  [PageName.MODEL_EDITOR]: '/models/edit/:uuid',
  [PageName.MODEL_UPLOADER]: '/models/upload',
  [PageName.ADMIN_ALL_USERS]: '/admin/users',
  [PageName.ADMIN_ALL_MODELS]: '/admin/models',
  [PageName.ADMIN_EDIT_USER]: '/admin/users/edit/:uuid',
  [PageName.ADMIN_INVITE_USER]: '/admin/users/invite',
  [PageName.PAGE_NOT_FOUND]: '/404-page-not-found',
  [PageName.DASHBOARD]: '/admin/dashboard',
  [PageName.EDIT_USER]: '/user/edit/:uuid',
};

interface RouteParams {
  [PageName.MODEL_VIEWER]: {
    uuid: string;
  };
  [PageName.MODEL_CAPTURE_VIEW]: {
    uuid: string;
  };
  [PageName.MODEL_EDITOR]: {
    uuid: string;
  };
  [PageName.ADMIN_EDIT_USER]: {
    uuid: string;
  };
}

export function getInterpolatedPath<P extends keyof RouteParams>(
  pageName: P,
  args: RouteParams[P],
): string {
  return Object.entries(args).reduce((path, [paramName, paramValue]) => {
    return path.replace(`:${paramName}`, paramValue);
  }, routes[pageName]);
}

type RoutePermissions = {
  [page in PageName]?: UserRole;
};

export const routePermissions: RoutePermissions = {
  [routes.USER_MODELS]: UserRole.PUBLISHER,
  [routes.MODEL_EDITOR]: UserRole.PUBLISHER,
  [routes.MODEL_UPLOADER]: UserRole.PUBLISHER,
  [routes.ADMIN_ALL_USERS]: UserRole.ADMIN,
  [routes.ADMIN_ALL_MODELS]: UserRole.ADMIN,
  [routes.ADMIN_EDIT_USER]: UserRole.ADMIN,
  [routes.ADMIN_INVITE_USER]: UserRole.ADMIN,
  [routes.EDIT_USER]: UserRole.PUBLISHER,
};
