import {
  routes,
  AssetMetadata,
  UserIdPayload,
  AssetUuidPayload,
  AssetUploadStatusPayload,
  AssetData,
  AssetUploadPayload,
} from 'nvzn-models';
import { createAction, ACTION } from './actionHelpers';
import { AssetDataModel } from '../models';

export const fetchAssetsForUser = createAction<UserIdPayload, AssetMetadata[]>({
  actionName: ACTION.FETCH_ASSETS,
  route: routes.assets.getAssetsForUser,
  requestConfig: {
    headers: {
      'Cache-Control': 'no-cache',
    },
  },
  mergeState: (state, payload: AssetMetadata[]) => {
    state[ACTION.FETCH_ASSETS].data = payload;
    return state;
  },
});

export const fetchAllPulicAssets = createAction<UserIdPayload, AssetMetadata[]>(
  {
    actionName: ACTION.FETCH_ALL_PUBLIC_ASSETS,
    route: routes.assets.getPublicAssets,
    requestConfig: {
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
    mergeState: (state, payload: AssetMetadata[]) => {
      //filter out uploaded assets
      const uploadedAssets = payload.filter((asset) => asset.isUploadComplete);
      state[ACTION.FETCH_ALL_PUBLIC_ASSETS].data = uploadedAssets;
      return state;
    },
  },
);

export const deleteAsset = createAction<AssetUuidPayload, AssetUuidPayload>({
  actionName: ACTION.DELETE_ASSET,
  route: routes.assets.deleteAsset,
  mergeState: (state, payload: AssetUuidPayload) => {
    state[ACTION.FETCH_ASSETS].data = state[ACTION.FETCH_ASSETS].data.filter(
      (asset: AssetMetadata): boolean => {
        return asset.uuid !== payload.uuid;
      },
    );
    return state;
  },
});

export const getUploadStatus = createAction<
  AssetUuidPayload,
  AssetUploadStatusPayload
>({
  actionName: ACTION.GET_UPLOAD_STATUS,
  route: routes.assets.assetUploadStatus,
  requestConfig: {
    headers: {
      'Cache-Control': 'no-cache',
    },
  },
  mergeState: (state, payload: AssetUploadStatusPayload) => {
    const { uuid, isUploadComplete } = payload;
    state[ACTION.FETCH_ASSETS].data = state[ACTION.FETCH_ASSETS].data.map(
      (asset: AssetMetadata): AssetMetadata => {
        if (asset.uuid === uuid) {
          asset.isUploadComplete = isUploadComplete;
        }
        return asset;
      },
    );
    return state;
  },
});

export const uploadAsset = createAction<AssetUploadPayload, AssetData>({
  transformOut: (data: AssetUploadPayload) => {
    const formData = new FormData();
    const {
      files: { glb, usdz, backgroundImage, environmentalImage },
      config,
    } = data;
    const configData = config;
    configData['glbFileName'] = glb.name;
    configData['usdzFileName'] = usdz.name;
    formData.append('config', JSON.stringify(configData));
    formData.append('glb', glb);
    formData.append('usdz', usdz);
    formData.append('backgroundImage', backgroundImage);
    formData.append('environmentalImage', environmentalImage);
    return formData;
  },
  actionName: ACTION.UPLOAD_ASSET,
  route: routes.assets.uploadAsset,
  mergeState: (state) => {
    return state;
  },
});

export const editAsset = createAction<AssetUploadPayload, AssetData>({
  actionName: ACTION.EDIT_ASSET,
  route: routes.assets.editAsset,
  mergeState: (state) => {
    return state;
  },
  transformOut: (data: AssetUploadPayload) => {
    const formData = new FormData();
    const {
      files: { glb, usdz, backgroundImage, environmentalImage },
      config,
      uuid,
    } = data;
    const configData = config;
    configData['glbFileName'] = glb.name;
    configData['usdzFileName'] = usdz.name;
    formData.append('config', JSON.stringify(configData));
    formData.append('uuid', uuid);
    formData.append('glb', filterNonFiles(glb));
    formData.append('usdz', filterNonFiles(usdz));
    formData.append('backgroundImage', filterNonFiles(backgroundImage));
    formData.append('environmentalImage', filterNonFiles(environmentalImage));
    return formData;
  },
});

export const getAsset = createAction<AssetUuidPayload, AssetDataModel>({
  actionName: ACTION.GET_ASSET,
  route: routes.assets.getAsset,
  requestConfig: {
    headers: {
      'Cache-Control': 'no-cache',
    },
  },
  mergeState: (state, payload) => {
    state[ACTION.GET_ASSET].data = new AssetDataModel(payload);
    return state;
  },
});

interface bannerData {
  desc: string;
  title: string;
  icon: string;
}

interface bannerConfigData {
  addToBasket: bannerData;
  addToCart: bannerData;
  addToFoodOrder: bannerData;
  addToWishList: bannerData;
  buyNow: bannerData;
}

export const fetchBannerConfig = createAction<
  bannerConfigData,
  bannerConfigData
>({
  actionName: ACTION.GET_BANNER_CONFIG,
  route: routes.assets.getBannerConfig,
  requestConfig: {
    headers: {
      'Cache-Control': 'no-cache',
    },
  },
  mergeState: (state, payload: bannerConfigData) => {
    state[ACTION.GET_BANNER_CONFIG].data = payload;
    return state;
  },
});

const filterNonFiles = (val) => {
  return val instanceof File ? val : undefined;
};
