import React, { Component } from 'react';
import { connect } from 'react-redux';
import { State } from '../../state/reducer';
import { config } from '../../util';

const mapStateToProps = (state: State) => ({
  assets: state.FETCH_ASSETS.data,
  asset: state.GET_ASSET.data,
  loading: state.FETCH_ASSETS.loading,
  deleting: state.DELETE_ASSET.loading,
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
});
// let res = await fetch(`${appConfig.backEndUrl}/assets/${uuid}`);
const src = `${config.dataStudioURL}`;
class DasboardComponent extends Component {
  public state = {
    iframeSrc: `${src}?params={"ds6.token":"${localStorage.getItem('token')}"}`,
  };

  public render() {
    console.log(`${localStorage.getItem('token')}`);
    const token = {
      'ds16.token': localStorage.getItem('token'),
    };
    const encoded = JSON.stringify(token);
    const iframeSrc = `${src}?params=${encoded}`;
    return (
      <div className="dashboard">
        <iframe
          width={document.body.clientWidth}
          height={document.body.clientHeight}
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export const Dashboard = connect(mapStateToProps)(DasboardComponent);
