import { Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AssetUploadPayload, AR_SCALE } from 'nvzn-models';
import React, { Component, Fragment } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Prompt } from 'react-router';
import '@google/model-viewer/dist/model-viewer';
import { UploadForm } from './Form';
import { validationSchema, initialValues } from './validationSchema';
import { ModelPreviewer } from '../../components';
import { PageLoader } from '../../components/PageLoader';
import { ACTION, ActionError } from '../../state/actionHelpers';
import { uploadAsset, clearError } from '../../state/actions';
import { State } from '../../state/reducer';
import { routes } from '../../util';

interface IUploadProps extends RouteChildrenProps {
  upload(assetData: AssetUploadPayload): void;
  clearError(): void;
  loading: boolean;
  error: ActionError;
}

interface IUploadState {
  willLoseChanges: boolean;
}

const mapStateToProps = (state: State) => ({
  loading: state.UPLOAD_ASSET.loading,
  error: state.UPLOAD_ASSET.error,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (assetData: AssetUploadPayload) => dispatch(uploadAsset(assetData)),
  clearError: () => dispatch(clearError(ACTION.UPLOAD_ASSET)),
});

class Upload extends Component<IUploadProps, IUploadState> {
  public state = {
    willLoseChanges: false,
  };

  public componentDidUpdate(prevProps: IUploadProps) {
    const { error, loading } = this.props;
    if (prevProps.loading && !loading) {
      if (!error) {
        this.props.history.push(
          this.props.location.state
            ? this.props.location.state.referrer
            : false || routes.USER_MODELS,
        );
      }
    }
  }
  private normalizeProp<ToType>(obj: Partial<AssetUploadPayload>): ToType {
    return ({
      files: {
        ...(obj.files || {}),
      },
      config: {
        ...(obj.config || {}),
        alignModel: {
          ...(obj.config?.alignModel || {}),
        },
        cameraOrbit: {
          ...(obj.config?.cameraOrbit || {}),
        },
        minCameraOrbit: {
          ...(obj.config?.minCameraOrbit || {}),
        },
        maxCameraOrbit: {
          ...(obj.config?.maxCameraOrbit || {}),
        },
        cameraTarget: {
          ...(obj.config?.cameraTarget || {}),
        },
        fieldOfView: {
          ...(obj.config?.fieldOfView || {}),
        },
        modelInfo: {
          ...(obj.config?.modelInfo || {}),
        },
      },
    } as unknown) as ToType;
  }
  private handleSubmit = (values: AssetUploadPayload): void => {
    this.setState(
      {
        willLoseChanges: false,
      },
      () => this.props.upload(values),
    );
  };
  private handleCancel = (): void => {
    this.props.history.push(
      this.props.location.state
        ? this.props.location.state.referrer
        : false || routes.USER_MODELS,
    );
  };
  private updateDidChange = (): void => {
    const { willLoseChanges } = this.state;
    if (!willLoseChanges) {
      this.setState({
        willLoseChanges: true,
      });
    }
  };
  private renderErrorModal() {
    const { error, clearError } = this.props;
    return (
      <Modal show={!!error}>
        <Modal.Body>{`${error?.message}`}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  public render() {
    const { willLoseChanges } = this.state;
    const { loading } = this.props;
    return (
      <Fragment>
        <Prompt
          message="Your changes will not be saved. Are you sure you want to cancel?"
          when={willLoseChanges}
        />
        {this.renderErrorModal()}
        <Formik
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }: FormikProps<AssetUploadPayload>) => {
            return loading ? (
              <PageLoader />
            ) : (
              <Row noGutters>
                <Col xs={6} lg={7} xl={8}>
                  <ModelPreviewer
                    metadata={values}
                    errors={errors}
                    isSubmitting={isSubmitting}
                  />
                </Col>
                <Col xs={6} lg={5} xl={4} className="editor__side-pane">
                  <UploadForm
                    editMode={false}
                    handleSubmit={handleSubmit}
                    handleCancel={this.handleCancel}
                    handleChange={(e: React.ChangeEvent<HTMLFormElement>) => {
                      handleChange(e);
                      this.updateDidChange();
                      if (e.target.id === 'files.glb' && e.target.value?.name) {
                        const name = e.target.value.name;
                        setFieldValue(
                          'config.name',
                          name.substring(0, name.lastIndexOf('.')),
                        );
                      } else if (e.target.id === 'config.arScale') {
                        setFieldValue(
                          'config.arScale',
                          e.target.checked ? AR_SCALE.fixed : AR_SCALE.auto,
                        );
                      }
                    }}
                    handleBlur={handleBlur}
                    values={values}
                    touched={this.normalizeProp<
                      FormikTouched<AssetUploadPayload>
                    >((touched as unknown) as Partial<AssetUploadPayload>)}
                    isValid={isValid}
                    errors={this.normalizeProp<
                      FormikErrors<AssetUploadPayload>
                    >((errors as unknown) as Partial<AssetUploadPayload>)}
                    isSubmitting={isSubmitting}
                  />
                </Col>
              </Row>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const UploadConnected = connect(mapStateToProps, mapDispatchToProps)(Upload);

export { UploadConnected as Upload };
