import {
  routes,
  InviteUserPayload,
  Invite,
  UserInfo,
  UserIdPayload,
  AssetMetadata,
  UpdateUserPayload,
  AdminManageUserPayload,
  AssetReassignPayload,
  AssetUuidPayload,
  AdminAssetsUuidPayload,
} from 'nvzn-models';
import { createAction, ACTION } from './actionHelpers';

export const inviteUser = createAction<InviteUserPayload, Invite>({
  actionName: ACTION.INVITE_USER,
  route: routes.admin.inviteUser,
  mergeState: (state, payload: Invite) => {
    state[ACTION.INVITE_USER].data = payload;
    return state;
  },
});

export const getAllUsers = createAction<undefined, UserInfo[]>({
  actionName: ACTION.GET_ALL_USERS,
  route: routes.admin.getAllUsers,
  mergeState: (state, payload: UserInfo[]) => {
    state[ACTION.GET_ALL_USERS].data = payload;
    return state;
  },
});

export const reassignModel = createAction<AssetReassignPayload, AssetMetadata>({
  actionName: ACTION.ADMIN_REASSIGN_MODEL,
  route: routes.admin.reassignModel,
  mergeState: (state, payload: AssetMetadata) => {
    const allAssets = state[ACTION.FETCH_ASSETS].data;
    state[ACTION.FETCH_ASSETS].data = allAssets.map((assetData) => {
      let rv = assetData;
      if (assetData.uuid === payload.uuid) {
        rv = payload;
      }
      return rv;
    });
    return { ...state };
  },
});

export const duplicateModal = createAction<
  AdminAssetsUuidPayload,
  AssetUuidPayload
>({
  actionName: ACTION.ADMIN_DUPLICATE_MODAL,
  route: routes.admin.duplicateModal,
  mergeState: (state, payload: AdminAssetsUuidPayload) => {
    return state;
  },
});

export const getAllAssets = createAction<UserIdPayload, AssetMetadata[]>({
  actionName: ACTION.FETCH_ASSETS,
  route: routes.admin.getAllAssets,
  mergeState: (state, payload: AssetMetadata[]) => {
    state[ACTION.FETCH_ASSETS].data = payload;
    return state;
  },
});

export const adminDeleteUser = createAction<AdminManageUserPayload, UserInfo>({
  actionName: ACTION.ADMIN_DELETE_USER,
  route: routes.auth.deleteUser,
  mergeState: (state, payload: UserInfo) => {
    state[ACTION.ADMIN_DELETE_USER].data = payload;
    state[ACTION.GET_ALL_USERS].data = state[ACTION.GET_ALL_USERS].data.filter(
      (user) => user.uuid !== payload.uuid,
    );
    return state;
  },
});

export const updateUser = createAction<UpdateUserPayload, UserInfo>({
  actionName: ACTION.ADMIN_UPDATE_USER,
  route: routes.admin.updateUser,
  mergeState: (state, payload: UserInfo) => {
    state[ACTION.ADMIN_UPDATE_USER].data = payload;
    state[ACTION.GET_ALL_USERS].data = state[ACTION.GET_ALL_USERS].data.map(
      (user) => {
        let rv = user;
        if (user.uuid === payload.uuid) {
          rv = payload;
        }
        return rv;
      },
    );
    return state;
  },
});

export const adminGetUser = createAction<AdminManageUserPayload, UserInfo>({
  actionName: ACTION.ADMIN_GET_USER,
  route: routes.admin.adminGetUser,
  mergeState: (state, payload: UserInfo) => {
    state[ACTION.ADMIN_GET_USER].data = payload;
    return state;
  },
});
