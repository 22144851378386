import {
  AssetData,
  AssetConfig,
  DISTANCE,
  CAM_ANGLE,
  IMAGE_TYPE,
  AssetUploadPayload,
  BANNER_TYPE,
} from 'nvzn-models';
import { config as appConfig } from '../util';

const EMPTY_CONFIG: AssetConfig = {
  alignModel: {
    x: undefined,
    y: undefined,
    z: undefined,
  },
  authorName: undefined,
  autoRotate: undefined,
  autoplay: undefined,
  backgroundColor: undefined,
  cameraOrbit: {
    azimuthal: undefined,
    azimuthalUnit: undefined,
    polar: undefined,
    polarUnit: undefined,
    distance: undefined,
    distanceUnit: undefined,
  },
  cameraTarget: {
    x: undefined,
    xUnit: undefined,
    y: undefined,
    yUnit: undefined,
    z: undefined,
    zUnit: undefined,
  },
  desc: undefined,
  exposure: undefined,
  fieldOfView: {
    angle: undefined,
    unit: undefined,
  },
  arPlacement: undefined,
  glbFileName: undefined,
  name: undefined,
  shadowIntensity: undefined,
  usdzFileName: undefined,
  additionalInfo: undefined,
  arScale: undefined,
  backgroundImageType: undefined,
  environmentalImageType: undefined,
  linkOut: undefined,
  maxCameraOrbit: {
    azimuthal: undefined,
    azimuthalUnit: undefined,
    polar: undefined,
    polarUnit: undefined,
    distance: undefined,
    distanceUnit: undefined,
  },
  minCameraOrbit: {
    azimuthal: undefined,
    azimuthalUnit: undefined,
    polar: undefined,
    polarUnit: undefined,
    distance: undefined,
    distanceUnit: undefined,
  },
  modelInfo: undefined,
  nvznPortal: undefined,
  privateCheck: undefined,
  shadowSoftness: undefined,
  showSharing: undefined,
  ctaBanner: {
    ctaSwitch: undefined,
    ctaType: undefined,
    ctaUrl: undefined,
  },
};

interface CtaBannerData {
  ctaSwitch: boolean;
  ctaType: BANNER_TYPE;
  ctaUrl: string;
}

const EMPTY_ASSET_DATA: AssetData = {
  username: undefined,
  userId: undefined,
  uuid: undefined,
  uploadDate: undefined,
  lastEditedDate: undefined,
  isUploadComplete: undefined,
  config: EMPTY_CONFIG,
};

export class AssetDataModel {
  public static fromUploadPayload(
    uploadPayload: AssetUploadPayload,
  ): AssetDataModel {
    const data: AssetData = {
      ...uploadPayload,
      uploadDate: undefined,
      isUploadComplete: undefined,
    } as AssetData;
    return new AssetDataModel(data);
  }
  public constructor(
    private readonly assetData: AssetData = EMPTY_ASSET_DATA,
  ) {}

  public get userId(): string {
    return this.assetData.userId;
  }
  public set userId(userId: string) {
    this.assetData.userId = userId;
  }
  public get username(): string {
    return this.assetData.username;
  }
  public set username(username: string) {
    this.assetData.username = username;
  }
  public get uuid(): string {
    return this.assetData.uuid;
  }
  public set uuid(uuid: string) {
    this.assetData.uuid = uuid;
  }
  public get uploadDate(): number {
    return this.assetData.uploadDate;
  }
  public get lastEditedDate(): number {
    return this.assetData.lastEditedDate;
  }
  public set lastEditedDate(date: number) {
    this.assetData.lastEditedDate = date;
  }
  public get isUploadComplete(): boolean {
    return this.assetData.isUploadComplete;
  }
  public get config(): AssetConfig {
    return this.assetData.config || EMPTY_CONFIG;
  }
  public set config(config: AssetConfig) {
    this.assetData.config = config || EMPTY_CONFIG;
  }
  public get name(): string {
    return this.config.name;
  }
  public get desc(): string {
    return this.config.desc;
  }
  public get glbFileName(): string {
    return this.config.glbFileName;
  }
  public get usdzFileName(): string {
    return this.config.usdzFileName;
  }
  public get autoRotate(): boolean {
    return this.config.autoRotate;
  }
  public get autoplay(): boolean {
    return this.config.autoplay;
  }
  public get playerSrc(): string {
    return this.config.playerSrc;
  }
  public get backgroundColor(): string {
    return `#${this.config.backgroundColor}`;
  }
  public get glbSrc(): string {
    return `${appConfig.cdnUrl}/${this.uuid}/glb.glb?v=${this.lastEditedDate}`;
  }
  public get usdzSrc(): string {
    return `${appConfig.cdnUrl}/${this.uuid}/usdz.usdz?v=${this.lastEditedDate}`;
  }
  public get backgroundImageType(): IMAGE_TYPE {
    return this.config.backgroundImageType;
  }
  public get backgroundImageSrc(): string {
    let extension = `hdr?v=${this.lastEditedDate}#.hdr`;
    if (this.backgroundImageType !== 'octet-stream')
      extension = `${this.backgroundImageType}?v=${this.lastEditedDate}`;
    return this.backgroundImageType
      ? `${appConfig.cdnUrl}/${this.uuid}/backgroundImage.${extension}`
      : undefined;
  }
  public get environmentalImageType(): IMAGE_TYPE {
    return this.config.environmentalImageType;
  }
  public get environmentalImageSrc(): string {
    let extension = `hdr?v=${this.lastEditedDate}#.hdr`;
    if (this.environmentalImageType !== 'octet-stream')
      extension = `${this.environmentalImageType}?v=${this.lastEditedDate}`;
    return this.environmentalImageType
      ? `${appConfig.cdnUrl}/${this.uuid}/environmentalImage.${extension}`
      : undefined;
  }
  public get posterImageSrc(): string {
    return `${appConfig.cdnUrl}/${this.uuid}/poster.png?v=${this.lastEditedDate}`;
  }
  public get shadowIntensity(): number {
    return this.config.shadowIntensity;
  }
  public get shadowSoftness(): number {
    return this.config.shadowSoftness;
  }
  public get exposure(): number {
    return this.config.exposure;
  }
  public get cameraOrbit(): string {
    const { cameraOrbit } = this.config;
    const azimuthal = `${cameraOrbit.azimuthal}${cameraOrbit.azimuthalUnit}`;
    const polar = `${cameraOrbit.polar}${cameraOrbit.polarUnit}`;
    const distanceAmount =
      cameraOrbit.distanceUnit === DISTANCE.auto ? '' : cameraOrbit.distance;
    const distance = `${distanceAmount}${cameraOrbit.distanceUnit}`;
    return `${azimuthal} ${polar} ${distance}`;
  }
  public get cameraTarget(): string {
    const { cameraTarget } = this.config;
    const cameraTargetX =
      cameraTarget.xUnit === DISTANCE.auto
        ? DISTANCE.auto
        : `${cameraTarget.x}${cameraTarget.xUnit}`;
    const cameraTargetY =
      cameraTarget.yUnit === DISTANCE.auto
        ? DISTANCE.auto
        : `${cameraTarget.y}${cameraTarget.yUnit}`;
    const cameraTargetZ =
      cameraTarget.zUnit === DISTANCE.auto
        ? DISTANCE.auto
        : `${cameraTarget.z}${cameraTarget.zUnit}`;
    return `${cameraTargetX} ${cameraTargetY} ${cameraTargetZ}`;
  }
  public get fov(): string {
    const { fieldOfView } = this.config;

    //If unit is auto then no need to set angle
    if (fieldOfView.unit === 'auto') return 'auto';

    return `${fieldOfView.angle}${fieldOfView.unit}`;
  }
  public get arPlacement(): string {
    const { arPlacement } = this.config;
    //Default value
    if (!arPlacement) return 'floor';
    return arPlacement.toLowerCase();
  }
  public get autorotate(): boolean {
    return this.config.autoRotate;
  }
  public get arScale(): string {
    return this.config.arScale || 'fixed';
  }
  public get minCameraOrbit(): string {
    const { minCameraOrbit } = this.config;
    let minCameraAzimuthal: string = CAM_ANGLE.auto;
    let minCameraPolar: string = CAM_ANGLE.auto;
    let minCameraDistance: string = CAM_ANGLE.auto;
    if (minCameraOrbit?.azimuthalUnit !== CAM_ANGLE.auto) {
      minCameraAzimuthal = `${minCameraOrbit.azimuthal}${minCameraOrbit.azimuthalUnit}`;
    }
    if (minCameraOrbit?.polarUnit !== CAM_ANGLE.auto) {
      minCameraPolar = `${minCameraOrbit.polar}${minCameraOrbit.polarUnit}`;
    }
    if (minCameraOrbit?.distanceUnit !== DISTANCE.auto) {
      minCameraDistance = `${minCameraOrbit.distance}${minCameraOrbit.distanceUnit}`;
    }
    return `${minCameraAzimuthal} ${minCameraPolar} ${minCameraDistance}`;
  }
  public get maxCameraOrbit(): string {
    const { maxCameraOrbit } = this.config;
    let maxCameraAzimuthal: string = CAM_ANGLE.auto;
    let maxCameraPolar: string = CAM_ANGLE.auto;
    let maxCameraDistance: string = CAM_ANGLE.auto;
    if (maxCameraOrbit?.azimuthalUnit !== CAM_ANGLE.auto) {
      maxCameraAzimuthal = `${maxCameraOrbit.azimuthal}${maxCameraOrbit.azimuthalUnit}`;
    }
    if (maxCameraOrbit?.polarUnit !== CAM_ANGLE.auto) {
      maxCameraPolar = `${maxCameraOrbit.polar}${maxCameraOrbit.polarUnit}`;
    }
    if (maxCameraOrbit?.distanceUnit !== DISTANCE.auto) {
      maxCameraDistance = `${maxCameraOrbit.distance}${maxCameraOrbit.distanceUnit}`;
    }
    return `${maxCameraAzimuthal} ${maxCameraPolar} ${maxCameraDistance}`;
  }
  public get ctaBannerData(): CtaBannerData {
    const ctaBanner = this.config.ctaBanner ?? {};
    return ctaBanner;
  }
}
