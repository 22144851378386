import cloneDeep from 'lodash.clonedeep';
import {
  AuthPayload,
  RegisterPayload,
  LoginPayload,
  AssetMetadata,
  AssetData,
  AssetUuidPayload,
  UserInfo,
  Invite,
  NVZNInterface,
  NVZNResponseInterface,
} from 'nvzn-models';
import { ACTION, getDefaultState, Action, ActionError } from './actionHelpers';
import { AssetDataModel } from '../models';
import { Environment, environment } from '../util';

export interface StateEntry<EntryData> {
  loading: boolean;
  error: ActionError;
  data: EntryData;
}

interface bannerData {
  desc: string;
  title: string;
  icon: string;
}

interface bannerConfigData {
  addToBasket: bannerData;
  addToCart: bannerData;
  addToFoodOrder: bannerData;
  addToWishList: bannerData;
  buyNow: bannerData;
}

export interface State {
  [ACTION.VERIFY_TOKEN]: StateEntry<AuthPayload>;
  [ACTION.LOGIN]: StateEntry<RegisterPayload>;
  [ACTION.GET_USER]: StateEntry<UserInfo>;
  [ACTION.ADMIN_GET_USER]: StateEntry<UserInfo>;
  [ACTION.REGISTER]: StateEntry<LoginPayload>;
  [ACTION.FETCH_ASSETS]: StateEntry<AssetMetadata[]>;
  [ACTION.FETCH_ALL_PUBLIC_ASSETS]: StateEntry<AssetMetadata[]>;
  [ACTION.UPLOAD_ASSET]: StateEntry<AssetData>;
  [ACTION.GET_ASSET]: StateEntry<AssetDataModel>;
  [ACTION.EDIT_ASSET]: StateEntry<AssetData>;
  [ACTION.DELETE_ASSET]: StateEntry<AssetUuidPayload>;
  [ACTION.INVITE_USER]: StateEntry<Invite>;
  [ACTION.GET_ALL_USERS]: StateEntry<UserInfo[]>;
  [ACTION.FETCH_ALL_ASSETS]: StateEntry<AssetMetadata[]>;
  [ACTION.ADMIN_DELETE_USER]: StateEntry<UserInfo>;
  [ACTION.ADMIN_UPDATE_USER]: StateEntry<UserInfo>;
  [ACTION.ADMIN_REASSIGN_MODEL]: StateEntry<never>;
  [ACTION.GET_BANNER_CONFIG]: StateEntry<bannerConfigData>;
  [ACTION.ADMIN_DUPLICATE_MODAL]: StateEntry<never>;
}

export const defaultState: State = getDefaultState();

export function reducer<
  Payload extends NVZNInterface | NVZNResponseInterface | ACTION
>(
  state = defaultState,
  { type, payload, mergeState, action, stage }: Action<Payload>,
): State {
  if (environment !== Environment.production && !!action) {
    console.groupCollapsed(`${action} [${stage}]`);
    console.groupCollapsed('PAYLOAD');
    console.log(payload);
    console.groupEnd();
    console.groupCollapsed('PREVIOUS STATE');
    console.log(state);
    console.groupEnd();
  }
  let rv = cloneDeep(state);
  if (type === ACTION.CLEAR_ERROR) {
    state[payload as ACTION].error = undefined;
  } else if (mergeState) {
    rv = mergeState(state, payload);
  }
  if (environment !== Environment.production && !!action) {
    console.groupCollapsed('NEW STATE');
    console.log(rv);
    console.groupEnd();
    console.groupEnd();
  }
  return { ...rv };
}
