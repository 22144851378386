import { DeleteModal } from './DeleteModal';
import { IntegrationModal } from './IntegrationModal';
import { ShareModal } from './ShareModal';
import { ViewInARModal } from './ViewInARModal';
import { ConnectReassignModal as ReassignModal } from './ReassignModal';
import { DuplicateModal } from './DuplicateModal';

export {
  ViewInARModal,
  ShareModal,
  IntegrationModal,
  DeleteModal,
  ReassignModal,
  DuplicateModal,
};

export enum ModalType {
  AR = 'AR',
  SHARE = 'SHARE',
  INTEGRATION = 'INTEGRATION',
  DELETE = 'DELETE',
  REASSIGN = 'REASSIGN',
  DUPLICATE = 'DUPLICATE',
}

export enum SelectedTab {
  SHARE = 'SHARE',
  EMBED = 'EMBED',
}
