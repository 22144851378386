import React from 'react';
import { Helmet } from 'react-helmet';
import { AssetDataModel } from '../models';
import { config } from '../util';

export interface ModelViewerHelmetProps {
  asset: AssetDataModel;
  loading: boolean;
}

export function ModelViewerHelmet({ asset, loading }: ModelViewerHelmetProps) {
  let title = 'Nvzn Augmented Reality';
  let description =
    'Nvzn is a web-based platform that makes engagement in augmented reality experiences spontaneous, ephemeral and delightful — no app required.';
  let imgUrl: string;
  if (asset && !loading) {
    title = `${asset.name} by ${asset.username} | ${title}`;
    description = asset.desc;
    imgUrl = `${config.cdnUrl}/${asset.uuid}/preview.png?v=${asset.lastEditedDate}`;
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="Nvzn Augmented Reality" />
      <meta name="twitter:image:alt" content={title} />
    </Helmet>
  );
}
