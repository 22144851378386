import React, { useRef, useState } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaCheck, FaExclamation, FaTrash } from 'react-icons/fa';

interface IFileUploadProps {
  text: string;
  name: string;
  id: string;
  onChange(e): void;
  onBlur(val): void;
  isInvalid: boolean;
  disabled: boolean;
  accept?: string;
  value: File;
}

export function FileUpload({
  text,
  name,
  id,
  onChange,
  onBlur,
  isInvalid,
  disabled,
  accept,
  value,
}: IFileUploadProps) {
  const fileInput = useRef(null);
  const [val, setVal] = useState(value);
  function handleChange(e) {
    e.preventDefault();
    const {
      target: { files },
    } = e;
    const file = files[files.length - 1];
    onChange({
      ...e,
      target: {
        ...e.target,
        value: file,
        id: id,
        name: id,
      },
    });
    onBlur({
      ...e,
      target: {
        ...e.target,
        id: id,
        name: id,
      },
    });
    setVal(file);
  }
  function clearValue() {
    onChange({
      target: {
        value: undefined,
        id: id,
        name: id,
      },
    });
    onBlur({
      target: {
        value: undefined,
        id: id,
        name: id,
      },
    });
    setVal(undefined);
  }
  function renderStatus() {
    let rv = null;
    if (val) {
      const IconClass = isInvalid ? FaExclamation : FaCheck;
      rv = <IconClass className="ml-2 mt-1 float-left" />;
    }
    return rv;
  }
  let buttonVariant:
    | 'primary'
    | 'secondary'
    | 'outline-danger'
    | 'outline-success' = disabled ? 'secondary' : 'primary';
  if (val) {
    buttonVariant = isInvalid ? 'outline-danger' : 'outline-success';
  }
  return (
    <span>
      <input
        type="file"
        name={id}
        id={id}
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={handleChange}
        accept={accept}
      />
      <InputGroup>
        <FormControl
          as={Button}
          onClick={() => fileInput.current.click()}
          block
          disabled={disabled}
          variant={buttonVariant}
          className="overflow-hidden text-truncate"
        >
          {renderStatus()}
          {val ? val.name : text}
        </FormControl>
        {val && (
          <InputGroup.Append>
            <Button
              variant={buttonVariant}
              onClick={clearValue}
              id={`delete-${id}`}
            >
              <FaTrash />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    </span>
  );
}
