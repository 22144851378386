import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Route, NVZNInterface, NVZNResponseInterface } from 'nvzn-models';
import { State } from './reducer';
import { config } from '../util';

export enum ACTION {
  CLEAR_ERROR = 'CLEAR_ERROR',
  VERIFY_TOKEN = 'VERIFY_TOKEN',
  LOGIN = 'LOGIN',
  GET_USER = 'GET_USER',
  REGISTER = 'REGISTER',
  LOG_OUT = 'LOG_OUT',
  FETCH_ASSETS = 'FETCH_ASSETS',
  FETCH_ALL_ASSETS = 'FETCH_ALL_ASSETS',
  FETCH_ALL_PUBLIC_ASSETS = 'FETCH_ALL_PUBLIC_ASSETS',
  GET_UPLOAD_STATUS = 'GET_UPLOAD_STATUS',
  UPLOAD_ASSET = 'UPLOAD_ASSET',
  EDIT_ASSET = 'EDIT_ASSET',
  DELETE_ASSET = 'DELETE_ASSET',
  GET_ASSET = 'GET_ASSET',
  GET_ALL_USERS = 'GET_ALL_USERS',
  ADMIN_DELETE_USER = 'ADMIN_DELETE_USER',
  INVITE_USER = 'INVITE_USER',
  ADMIN_GET_USER = 'ADMIN_GET_USER',
  ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER',
  ADMIN_REASSIGN_MODEL = 'ADMIN_REASSIGN_MODEL',
  GET_BANNER_CONFIG = 'GET_BANNER_CONFIG',
  ADMIN_DUPLICATE_MODAL = 'ADMIN_DUPLICATE_MODAL',
}

export enum STAGE {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type stateMerger<Resp extends NVZNInterface | NVZNResponseInterface> = (
  state: State,
  payload?: Resp,
) => State;

export interface RequestActionsCreator<
  Req extends NVZNInterface,
  Resp extends NVZNResponseInterface
> {
  actionName: ACTION;
  route: Route<Req, Resp>;
  mergeState?: stateMerger<Resp>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformOut?: (req: Req) => any;
  requestConfig?: AxiosRequestConfig;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Action<Payload extends NVZNInterface | NVZNResponseInterface> {
  type: string;
  action: ACTION;
  stage: STAGE;
  mergeState: stateMerger<Payload>;
  payload?: Payload;
}

export type ActionCreator<Req extends NVZNInterface> = (
  body?: Req,
  urlParams?: { [param: string]: string },
) => (dispatch: (action: Action<Req>) => void) => void;

export interface ActionError {
  status: number;
  message: string;
}

export const handler = axios.create({
  baseURL: config.backEndUrl,
});

const onFetch = <Payload extends NVZNInterface>(
  dispatch,
  action: ACTION,
  payload: Payload,
): Action<Payload> => {
  return dispatch({
    type: `${action}__${STAGE.INIT}`,
    action,
    stage: STAGE.INIT,
    payload,
    mergeState: (state) => {
      state[action].loading = true;
      return state;
    },
  });
};

const onResponse = <Payload extends NVZNResponseInterface>(
  dispatch,
  action: ACTION,
  mergeState: stateMerger<Payload>,
) => (payload: Payload): Action<Payload> =>
  dispatch({
    type: `${action}__${STAGE.SUCCESS}`,
    action,
    stage: STAGE.SUCCESS,
    payload,
    mergeState: (state) => {
      state[action].loading = false;
      state[action].error = undefined;
      return mergeState(state, payload);
    },
  });

const onError = <Payload extends NVZNInterface>(dispatch, action: ACTION) => (
  error: AxiosError,
): Action<Payload> => {
  setTimeout(() => {
    return dispatch({
      type: `${action}__${STAGE.ERROR}`,
      action,
      stage: STAGE.ERROR,
      payload: '',
      mergeState: (state) => {
        state[action].loading = false;
        state[action].error = '';
        return state;
      },
    });
  }, 5 * 1000);
  if (error.response) {
    return dispatch({
      type: `${action}__${STAGE.ERROR}`,
      action,
      stage: STAGE.ERROR,
      payload: error?.response?.data,
      mergeState: (state) => {
        state[action].loading = false;
        state[action].error = error?.response?.data;
        return state;
      },
    });
  }
};

function applyUrlParams(
  path,
  urlParams: { [param: string]: string } = {},
): string {
  return path
    .split('/')
    .map((pathPart: string) => {
      let rv = pathPart;
      if (pathPart.startsWith(':')) {
        const param = pathPart.substr(1);
        rv = urlParams[param] || pathPart;
      }
      return rv;
    })
    .join('/');
}

export function createAction<
  Req extends NVZNInterface,
  Resp extends NVZNResponseInterface
>({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformOut = (body: Req): any => body,
  requestConfig = {},
  actionName,
  route,
  mergeState = (state: State): State => state,
}: RequestActionsCreator<Req, Resp>): ActionCreator<Req> {
  return (
    body: Req = undefined,
    urlParams?: { [param: string]: string },
    // TODO: Figure out correct type (types are harddddddd)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => async (dispatch: (action: Action<Req>) => void): Promise<any> => {
    onFetch(dispatch, actionName, { ...body, ...urlParams });
    let rv;
    if (route.path) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const args: any[] = [applyUrlParams(route.path, urlParams)];
      if (!['get', 'delete', 'head', 'options'].includes(route.method)) {
        args.push(transformOut(body));
      }
      args.push(requestConfig);

      rv = handler[route.method](...args)
        .then((resp: AxiosResponse) => resp.data)
        .then(onResponse(dispatch, actionName, mergeState))
        .catch(onError(dispatch, actionName));
    } else {
      rv = Promise.resolve(
        setTimeout(onResponse(dispatch, actionName, mergeState)),
      );
    }

    return rv;
  };
}

export type DefaultStates = {
  [action in keyof State]?: State[action]['data'];
};

const defaultStates: DefaultStates = {
  [ACTION.FETCH_ALL_PUBLIC_ASSETS]: [],
  [ACTION.FETCH_ALL_ASSETS]: [],
  [ACTION.FETCH_ASSETS]: [],
  [ACTION.GET_ALL_USERS]: [],
};

export const getDefaultState = (): State =>
  Object.keys(ACTION).reduce((accum, action) => {
    accum[action] = {
      loading: action === ACTION.VERIFY_TOKEN,
      error: undefined,
      data: defaultStates[action] || {},
    };
    return accum;
  }, {}) as State;
