import React from 'react';
import { Button, Spinner, Modal, Alert } from 'react-bootstrap';
import { ModalProps } from './BaseModal';

interface DuplicateModalProps extends ModalProps {
  itemName: string;
  duplicateModal(): void;
  loading: boolean;
  uuid: string;
}

export function DuplicateModal({
  show,
  onClose,
  itemName,
  duplicateModal,
  loading,
}: DuplicateModalProps) {
  return (
    <Modal show={show} onHide={onClose} className="confirm-delete-modal">
      <Modal.Body>
        <Alert variant="primary">
          Create a duplicate of <b>{itemName}</b> and save to your account?
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="confirm-delete-modal__confirm-button"
          onClick={duplicateModal}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              className="mr-2"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Duplicate</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
