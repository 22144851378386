import React, { Component } from 'react';
import { UserInfo } from 'nvzn-models';
import { Helmet } from 'react-helmet';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import {
  DeleteModal,
  ModalType,
  UserListing,
  SearchableTable,
} from '../../components';
import { getAllUsers, adminDeleteUser } from '../../state/actions';
import { State } from '../../state/reducer';
import {
  getInterpolatedPath,
  PageName,
  getFormattedFullName,
  routes,
} from '../../util';

interface AllUsersProps extends RouteChildrenProps {
  isLoggedIn: boolean;
  loading: boolean;
  deleting: boolean;
  users: UserInfo[];
  getAllUsers(): void;
  deleteUser(userId: string): void;
  deletionError: string;
}

interface ModalState {
  type: ModalType;
  uuid: string;
  name: string;
}

interface AllUsersState {
  modalState: ModalState;
}

const MAX_ROWS_PER_PAGE = 15;

enum UserSort {
  USERNAME_DESC = 'USERNAME_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  NAME_DESC = 'NAME_DESC',
  NAME_ASC = 'NAME_ASC',
  ROLE_DESC = 'ROLE_DESC',
  ROLE_ASC = 'ROLE_ASC',
  COMPANY_DESC = 'COMPANY_DESC',
  COMPANY_ASC = 'COMPANY_ASC',
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
}

const SortTypeMap = {
  Username: {
    ascSortType: UserSort.USERNAME_ASC,
    descSortType: UserSort.USERNAME_DESC,
  },
  Email: {
    ascSortType: UserSort.EMAIL_ASC,
    descSortType: UserSort.EMAIL_DESC,
  },
  Name: {
    ascSortType: UserSort.NAME_ASC,
    descSortType: UserSort.NAME_DESC,
  },
  Role: {
    ascSortType: UserSort.ROLE_ASC,
    descSortType: UserSort.ROLE_DESC,
  },
  Company: {
    ascSortType: UserSort.COMPANY_ASC,
    descSortType: UserSort.COMPANY_DESC,
  },
  'Date Created': {
    ascSortType: UserSort.DATE_CREATED_ASC,
    descSortType: UserSort.DATE_CREATED_DESC,
  },
  '': {
    ascSortType: undefined,
    descSortType: undefined,
  },
};

const sortFunctions = {
  // USERNAME
  [UserSort.USERNAME_DESC]: (userA: UserInfo, userB: UserInfo) =>
    userA.username.localeCompare(userB.username),
  [UserSort.USERNAME_ASC]: (userA: UserInfo, userB: UserInfo) =>
    userB.username.localeCompare(userA.username),
  // EMAIL
  [UserSort.EMAIL_DESC]: (userA: UserInfo, userB: UserInfo) =>
    userA.email.localeCompare(userB.email),
  [UserSort.EMAIL_ASC]: (userA: UserInfo, userB: UserInfo) =>
    userB.email.localeCompare(userA.email),
  // NAME
  [UserSort.NAME_DESC]: (userA: UserInfo, userB: UserInfo) =>
    getFormattedFullName(userA).localeCompare(getFormattedFullName(userB)),
  [UserSort.NAME_ASC]: (userA: UserInfo, userB: UserInfo) =>
    getFormattedFullName(userB).localeCompare(getFormattedFullName(userA)),
  // ROLE
  [UserSort.ROLE_DESC]: (userA: UserInfo, userB: UserInfo) =>
    userA.role.localeCompare(userB.role),
  [UserSort.ROLE_ASC]: (userA: UserInfo, userB: UserInfo) =>
    userB.role.localeCompare(userA.role),
  // COMPANY
  [UserSort.COMPANY_DESC]: (userA: UserInfo, userB: UserInfo) =>
    userA.companyName.localeCompare(userB.companyName),
  [UserSort.COMPANY_ASC]: (userA: UserInfo, userB: UserInfo) =>
    userB.companyName.localeCompare(userA.companyName),
  // DATE_CREATED
  [UserSort.DATE_CREATED_DESC]: (userA: UserInfo, userB: UserInfo) =>
    parseInt(userB.dateCreated) - parseInt(userA.dateCreated),
  [UserSort.DATE_CREATED_ASC]: (userA: UserInfo, userB: UserInfo) =>
    parseInt(userA.dateCreated) - parseInt(userB.dateCreated),
};

const mapStateToProps = (state: State): Partial<AllUsersProps> => ({
  users: state.GET_ALL_USERS.data,
  loading: state.GET_ALL_USERS.loading,
  deleting: state.ADMIN_DELETE_USER.loading,
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
});

const mapDispatchToProps = (dispatch): Partial<AllUsersProps> => ({
  getAllUsers: () => dispatch(getAllUsers()),
  deleteUser: (userUserId: string) =>
    dispatch(adminDeleteUser(undefined, { userUserId })),
});

class AllUsersComponent extends Component<AllUsersProps, AllUsersState> {
  public state: AllUsersState = {
    modalState: {
      type: undefined,
      uuid: undefined,
      name: undefined,
    },
  };

  public componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getAllUsers();
    }
  }

  public componentDidUpdate(prevProps: AllUsersProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.props.getAllUsers();
    }
    if (prevProps.deleting && !this.props.deleting) {
      this.handleCloseModal();
    }
  }

  private handleOpenModal = (modalState: ModalState) => {
    this.setState({
      modalState,
    });
  };

  private handleCloseModal = () =>
    this.setState({
      modalState: {
        type: undefined,
        uuid: undefined,
        name: undefined,
      },
    });

  private handleConfirmDelete = () => {
    const { uuid } = this.state.modalState;
    this.props.deleteUser(uuid);
  };

  private onEdit = ({ uuid }: UserInfo) =>
    this.props.history.push(
      getInterpolatedPath(PageName.ADMIN_EDIT_USER, { uuid }),
      {
        referrer: `${this.props.location.pathname}${this.props.location.search}`,
      },
    );

  private onDelete = ({ uuid, username }: UserInfo) =>
    this.handleOpenModal({
      name: username,
      uuid,
      type: ModalType.DELETE,
    });

  private renderHelmet() {
    return (
      <Helmet>
        <title>Users | Nvzn Augmented Reality</title>
        <meta name="title" content="Users | Nvzn Augmented Reality" />
        <meta
          name="description"
          content="Nvzn is a web-based platform that makes engagement in augmented reality experiences spontaneous, ephemeral and delightful — no app required."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }

  private renderActions = () => {
    return (
      <ButtonToolbar>
        <Button
          onClick={() =>
            this.props.history.push(routes.ADMIN_INVITE_USER, {
              referrer: routes.ADMIN_ALL_USERS,
            })
          }
          className="all-users__invite-user"
          variant="dark"
        >
          Invite New User
        </Button>
      </ButtonToolbar>
    );
  };

  private renderRow = (userInfo: UserInfo) => {
    return (
      <UserListing
        key={userInfo.uuid}
        userInfo={userInfo}
        onEdit={() => this.onEdit(userInfo)}
        onDelete={() => this.onDelete(userInfo)}
      />
    );
  };

  public render() {
    const { loading, users, deleting } = this.props;
    const { modalState } = this.state;
    return (
      <div className="all-users">
        {this.renderHelmet()}
        <DeleteModal
          show={modalState.type === ModalType.DELETE}
          onClose={this.handleCloseModal}
          itemName={modalState.name}
          onConfirmDelete={this.handleConfirmDelete}
          deleting={deleting}
        />
        <SearchableTable
          loading={loading}
          items={users}
          headerText="All Users"
          maxItemsPerPage={MAX_ROWS_PER_PAGE}
          renderItem={this.renderRow}
          searchableProperties={[
            'username',
            'email',
            'firstName',
            'lastName',
            'role',
            'companyName',
          ]}
          sortFunctonMap={sortFunctions}
          sortableTableHeaders={SortTypeMap}
          additionalActions={this.renderActions()}
          history={this.props.history}
        />
      </div>
    );
  }
}

export const AllUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllUsersComponent);
