import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

export interface ModalProps {
  show: boolean;
  onClose: () => void;
}

interface BaseModalProps extends ModalProps {
  header: string;
  className?: string;
  renderContent({ onCopy }): ReactNode;
}

interface BaseModalState {
  showToast: boolean;
}

export class BaseModal extends Component<BaseModalProps, BaseModalState> {
  public state: BaseModalState = {
    showToast: false,
  };

  private copyText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    this.setState(
      {
        showToast: true,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              showToast: false,
            }),
          2000,
        ),
    );
  };

  public render() {
    const { renderContent, header, onClose, show, className } = this.props;
    const { showToast } = this.state;
    return (
      <Modal
        show={show}
        onHide={onClose}
        size="lg"
        className={classNames('base-modal', className)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderContent({ onCopy: this.copyText })}</Modal.Body>
        <div className="toast-container fixed-top mt-2">
          <Toast show={showToast}>
            <Toast.Header>
              <strong className="mr-auto">
                <FaCheck className="text-success mr-2" />
                Copied to Clipboard
              </strong>
            </Toast.Header>
          </Toast>
        </div>
      </Modal>
    );
  }
}
