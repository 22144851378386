import { UserInfo, UserRole } from 'nvzn-models';
import React, { Component } from 'react';
import { Form, Card, Button, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { ActionError } from '../../state/actionHelpers';
import { getUser, inviteUser } from '../../state/actions';
import { State } from '../../state/reducer';
import { routes } from '../../util';

interface InviteUserProps
  extends RouteChildrenProps<never, { referrer: string }>,
    State {
  isLoggedIn: boolean;
  getUser(): void;
  inviteUser(email: string): void;
  loading: boolean;
  submitting: boolean;
  wasInviteCreated: boolean;
  error: ActionError;
  userInfo: UserInfo;
}
interface InviteUserState {
  email: string;
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: !!state.VERIFY_TOKEN.data.token && !!state.GET_USER.data,
  loading: state.LOGIN.loading || state.GET_USER.loading,
  submitting: state.INVITE_USER.loading,
  error: state.INVITE_USER.error,
  userInfo: state.GET_USER.data,
  wasInviteCreated: !!state.INVITE_USER.data?.inviteStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  inviteUser: (email: string) => dispatch(inviteUser({ email })),
});

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

class InviteUser extends Component<InviteUserProps, InviteUserState> {
  public state: InviteUserState = {
    email: '',
  };

  public componentDidUpdate(prevProps: InviteUserProps) {
    this.redirectIfNeeded();
    if (!prevProps.wasInviteCreated && this.props.wasInviteCreated) {
      this.setState({
        email: '',
      });
    }
  }

  private redirectIfNeeded = () => {
    const { history, userInfo, isLoggedIn, loading } = this.props;
    const canAccessPage = isLoggedIn && userInfo.role === UserRole.SUPERADMIN;
    if (!loading && !canAccessPage) {
      history.push(routes.HOME);
    }
  };

  private handleChange = ({ target }) => {
    try {
      const { value } = target;
      this.setState({ email: value });
    } catch (error) {
      console.error(error);
    }
  };

  private isValid = (): boolean => {
    const { email } = this.state;
    return email.length && EMAIL_REGEX.test(email);
  };

  private handleSubmit = async () => {
    const { inviteUser } = this.props;
    const { email } = this.state;
    if (this.isValid()) {
      inviteUser(email);
    }
  };
  private renderError = () => {
    const { error } = this.props;
    let rv;
    if (error) {
      rv = <Alert variant="danger">{error.message}</Alert>;
    }
    return rv;
  };
  private renderSuccess = () => {
    const { wasInviteCreated } = this.props;
    let rv;
    if (wasInviteCreated) {
      rv = (
        <Alert variant="success" id="send-invite-success">
          Invite sent successfully
        </Alert>
      );
    }
    return rv;
  };

  public render() {
    const isFormValid = this.isValid();
    const { history, loading, location, submitting } = this.props;
    return (
      <Row>
        <Helmet>
          <title>Invite | Nvzn Augmented Reality</title>
          <meta name="title" content="Invite | Nvzn Augmented Reality" />
          <meta
            name="description"
            content="Invite people to this platform for a whole new experience in Augmented Reality."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Col>
          <Card className="login">
            <Card.Header as="h5">Invite New Publisher</Card.Header>
            <Card.Body>
              {this.renderError()}
              {this.renderSuccess()}
              <Form>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  disabled={!isFormValid || loading}
                  onClick={isFormValid ? this.handleSubmit : undefined}
                  className="mr-4"
                  id="send-invite"
                >
                  {submitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="mr-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Send Invite
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    history.push(location.state?.referrer ?? routes.HOME)
                  }
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const InviteUserConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteUser);

export { InviteUserConnected as InviteUser };
