import bkg from './bkg.hdr';
import Cube from './Cube.svg';
import LogoSquare from './logoSquare.png';
import Logo from './logo.png';
import LogoNew from './logoNew.png';
import IconHand from './iconHand.svg';
import FullScreen from './fullScreen.svg';
import LogoHorizontal from './LogoHorizontal.png';
import ExtFullScreen from './exitFullScreen.svg';
import ProfileImagePlaceholder from './profile-image-placeholder.png';
import BY_NC_ND from './CC-BY-NC-ND.png';
import BY_NC_SA from './CC-BY-NC-SA.png';
import BY_NC from './CC-BY-NC.png';
import BY_ND from './CC-BY-ND.png';
import BY_SA from './CC-BY-SA.png';
import BY from './CC-BY.png';

const CC = {
  BY_NC_ND,
  BY_NC_SA,
  BY_NC,
  BY_ND,
  BY_SA,
  BY,
};

export {
  bkg,
  Cube,
  Logo,
  LogoSquare,
  LogoNew,
  FullScreen,
  ExtFullScreen,
  LogoHorizontal,
  CC,
  ProfileImagePlaceholder,
  IconHand,
};
