import { ACTION, Action } from './actionHelpers';
import {
  fetchAssetsForUser,
  getUploadStatus,
  uploadAsset,
  deleteAsset,
  getAsset,
  fetchBannerConfig,
  editAsset,
  fetchAllPulicAssets,
} from './assetActions';
import {
  verifyToken,
  login,
  logout,
  register,
  getUser,
  getAllUsers,
} from './authActions';
import { State } from './reducer';

const clearError = (stateEntry: keyof State): Action<keyof State> => ({
  type: ACTION.CLEAR_ERROR,
  action: ACTION.CLEAR_ERROR,
  stage: undefined,
  payload: stateEntry,
  mergeState: (state, payload) => {
    state[payload as ACTION].error = undefined;
    return state;
  },
});
export * from './adminActions';
export {
  clearError,
  verifyToken,
  login,
  logout,
  getUser,
  getAllUsers,
  register,
  fetchAssetsForUser,
  getUploadStatus,
  uploadAsset,
  deleteAsset,
  getAsset,
  fetchBannerConfig,
  editAsset,
  fetchAllPulicAssets,
};
