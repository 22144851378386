import React from 'react';
import { UserInfo } from 'nvzn-models';
import Moment from 'moment';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { getFormattedFullName } from '../../util';

interface UserListingProps {
  userInfo: UserInfo;
  onDelete(): void;
  onEdit(): void;
}

export function UserListing({ userInfo, onDelete, onEdit }: UserListingProps) {
  const { uuid, username, email, role, companyName, dateCreated } = userInfo;

  function formatDate(unix: number): string {
    return Moment(unix).format('M/D/YYYY h:mm A');
  }

  function renderOptions() {
    return (
      <td className="pl-0 pr-0">
        <Container className="searchable-table-row__user-actions">
          <Row noGutters>
            <Col xs="6" className="pr-2">
              <Button
                className="searchable-table-row__user-action searchable-table-row__edit-user"
                onClick={onEdit}
                block
                title="Edit"
              >
                <span className="material-icons">tune</span>
              </Button>
            </Col>
            <Col xs="6">
              <Button
                className="searchable-table-row__user-action searchable-table-row__delete-user"
                variant="danger"
                onClick={onDelete}
                block
                title="Delete"
              >
                <span className="material-icons">delete</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </td>
    );
  }

  return (
    <tr key={uuid} id={uuid} className="searchable-table-row">
      <td className="searchable-table-row__username text-nowrap">{username}</td>
      <td className="searchable-table-row__email text-nowrap">{email}</td>
      <td className="searchable-table-row__name text-nowrap">
        {getFormattedFullName(userInfo)}
      </td>
      <td className="searchable-table-row__role text-nowrap">{role}</td>
      <td className="searchable-table-row__company text-nowrap">
        {companyName}
      </td>
      <td className="searchable-table-row__date-created text-nowrap">
        {formatDate(parseInt(dateCreated))}
      </td>
      {renderOptions()}
    </tr>
  );
}
