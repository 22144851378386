import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { FaRegCopy, FaDownload } from 'react-icons/fa';
import { BaseModal } from './BaseModal';
import { config } from '../../util';

interface IntegrationModalProps {
  show: boolean;
  onClose: () => void;
}

export function IntegrationModal({ show, onClose }: IntegrationModalProps) {
  function renderContent({ onCopy }) {
    const yarnInstall = 'yarn add https://github.com/ronpadz/nvzn-widget';
    const scriptTag = `<script type="module" src="${config.cdnUrl}/embed.js"></script>`;
    return (
      <Fragment>
        <h6>
          Add script tag to the <code>&lt;head&gt;</code> section of your site
        </h6>
        <span className="d-flex mb-4">
          <code className="flex-grow-1 border bg-light rounded p-2 mr-4">
            {scriptTag}
          </code>
          <span>
            <Button onClick={() => onCopy(scriptTag)}>
              <FaRegCopy />
            </Button>
          </span>
        </span>
        <h6>
          Or include this script in your file system and import it where needed
        </h6>
        <span className="d-flex mb-4">
          <code className="flex-grow-1 border bg-light rounded p-2 mr-4">
            nvzn.js
          </code>
          <span>
            <Button
              as="a"
              href={`${config.cdnUrl}/embed.js`}
              target="_blank"
              download="nvzn-embed.js"
            >
              <FaDownload />
            </Button>
          </span>
        </span>
        <h6>Or use your favorite package manager to install it</h6>
        <span className="d-flex mb-4">
          <code className="flex-grow-1 border bg-light rounded p-2 mr-4">
            {yarnInstall}
          </code>
          <span>
            <Button onClick={() => onCopy(yarnInstall)}>
              <FaRegCopy />
            </Button>
          </span>
        </span>
      </Fragment>
    );
  }

  return (
    <BaseModal
      show={show}
      onClose={onClose}
      className="view-in-ar-modal"
      header="View in your space"
      renderContent={renderContent}
    />
  );
}
