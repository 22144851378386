import {
  AuthPayload,
  LoginPayload,
  RegisterPayload,
  ValidateTokenPayload,
  routes,
  UserIdPayload,
  UserInfo,
} from 'nvzn-models';
import { createAction, ACTION, handler } from './actionHelpers';

export const verifyToken = createAction<AuthPayload, ValidateTokenPayload>({
  actionName: ACTION.VERIFY_TOKEN,
  route: routes.auth.verify,
  mergeState: (state, payload: ValidateTokenPayload) => {
    if (payload.isValid) {
      const token = localStorage.getItem('token');
      state[ACTION.VERIFY_TOKEN].data = { token };
      handler.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('token');
      state[ACTION.VERIFY_TOKEN].data = { token: undefined };
    }
    return state;
  },
});

export const getUser = createAction<UserIdPayload, UserInfo>({
  actionName: ACTION.GET_USER,
  route: routes.auth.getUser,
  mergeState: (state, payload: UserInfo) => {
    state[ACTION.GET_USER].data = payload;
    return state;
  },
});

export const getAllUsers = createAction<undefined, UserInfo[]>({
  actionName: ACTION.GET_ALL_USERS,
  route: routes.admin.getAllUsers,
  mergeState: (state, payload: UserInfo[]) => {
    state[ACTION.GET_ALL_USERS].data = payload;
    return state;
  },
});

export const login = createAction<LoginPayload, AuthPayload>({
  actionName: ACTION.LOGIN,
  route: routes.auth.login,
  mergeState: (state, payload: AuthPayload) => {
    const token = payload.token;
    localStorage.setItem('token', token);
    state[ACTION.VERIFY_TOKEN].data = { token };
    state[ACTION.VERIFY_TOKEN].loading = false;
    handler.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return state;
  },
});

export const register = createAction<RegisterPayload, AuthPayload>({
  actionName: ACTION.REGISTER,
  route: routes.auth.register,
  mergeState: (state, payload: AuthPayload) => {
    const token = payload.token;
    localStorage.setItem('token', token);
    state[ACTION.VERIFY_TOKEN].data = { token };
    state[ACTION.VERIFY_TOKEN].loading = false;
    handler.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return state;
  },
});

export const logout = createAction<undefined, undefined>({
  actionName: ACTION.LOG_OUT,
  route: routes.misc.noop,

  mergeState: (state) => {
    console.log('here');
    localStorage.removeItem('token');
    state[ACTION.VERIFY_TOKEN].data = { token: undefined };
    return state;
  },
});
