import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const fields = {
  name: 'The name of the model. Also serves as alt tag for accessibility',
  desc: 'Description of the model',
  autoRotate: 'Enables the auto rotation of the model.',
  backgroundColor:
    'Sets the background color of the scene when viewed inline. Takes any valid CSS color string.',
  backgroundImage:
    'Sets the background skybox image of the scene when viewed inline. Supersedes Background Color. Requires an equirectangular projection image. Supports PNG, JPG and HDR (recommended) images. Find examples at https://hdrihaven.com',
  alignModel: 'Set the model alignment in the frame',
  cameraOrbit:
    'Set the starting and/or subsequent orbital position of the camera.',
  minCameraOrbit:
    'Constrains camera controls when viewing 3D object. Azimuthal rotation limits side-to-side rotation and can be used to prevent viewing behind object. Polar rotation limits top-to-bottom rotation and can be used to limit the viewing angle above the object.',
  maxCameraOrbit:
    'Constrains camera controls when viewing 3D object. Azimuthal rotation limits side-to-side rotation and can be used to prevent viewing behind object. Polar rotation limits top-to-bottom rotation and can be used to limit the viewing angle under the object.',
  cameraTarget: 'Starting and/or subsequent point the camera orbits around.',
  fieldOfView: "Range of the camera's visual area.",
  arPlacement:
    'Set the AR placement of a 3D model to either Horizontal or Vertical surfaces.',
  environmentalImage:
    'Controls the environmental reflection of the model. Normally if background-image is set, that image will also be used for the environment-image. Use environment-image to only set the reflection without affecting the background.',
  exposure:
    'Controls the exposure of both the model and skybox, for use primarily with HDR environments. Defaults to 1.',
  shadowIntensity:
    'Controls the opacity of the shadow. Set to 0 to turn off the shadow entirely. Defaults to 0.',
  showSharing:
    'Including this allows users to easily share your experience with others',
  AuthorName:
    'Including this tells your audience who created this but reveals only your Nvzn username',
  AdditionalInfo:
    'Give your audience more information about the subject matter in 5000 characters or less',
  ModelInfo:
    'Check this box if you remixed, transformed or built upon the original artwork',
  LinkOut:
    'Enter a properly formatted URL to take your audience to another web site or Nvzn experience such as <https://www.yourwebsite.com>',
  PrivateCheck:
    'Make your experience undiscoverable in the Nvzn portal and search engines',
  NvznPortal:
    'Including this helps users find their way to creating their own experiences and discovering others',
  Attribution: `Creative Commons license requires you to display the author's name or alias. Enter "unknown" if this information is not provided with the asset.`,
  license:
    'To learn more about Creative Commons licenses see https://creativecommons.org/use-remix/cc-licenses/',
  arScale:
    'Select to disable scalability of the model in AR viewer, locking at 100% scale.',
  banner:
    'Display a clickable banner over the AR scene to prompt a specific call to action',
  playerSrc: 'Enter a properly formatted URL to redirect to a custom player',
};

export interface IFieldInfo {
  fieldName: string;
  disabled?: boolean;
}

export function FieldInfo({ fieldName, disabled }: IFieldInfo) {
  return (
    <OverlayTrigger
      key={fieldName}
      placement={fieldName === 'ModelInfo' ? 'top' : 'right'}
      overlay={
        <Tooltip id={`tooltip-${fieldName}`}>{fields[fieldName]}</Tooltip>
      }
    >
      <Badge variant="secondary" className="ml-1" pill>
        ?
      </Badge>
    </OverlayTrigger>
  );
}
