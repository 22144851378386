import { RegisterPayload } from 'nvzn-models';
import React, { Component } from 'react';
import { Form, Card, Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { ActionError } from '../../state/actionHelpers';
import { register } from '../../state/actions';
import { State } from '../../state/reducer';
import { routes } from '../../util';

interface SignUpRouteParams {
  email: string;
  code: string;
}

interface ISignUpProps
  extends RouteChildrenProps<
    { email: string; code: string },
    { referrer: string }
  > {
  isLoggedIn: boolean;
  register(payload: RegisterPayload): void;
  loading: boolean;
  error: ActionError;
}
interface ISignUpState {
  email: string;
  password: string;
  username: string;
  firstName: string;
  lastName: string;
  companyName: string;
  inviteCode: string;
}

const mapStateToProps = (state: State) => ({
  isLoggedIn: !!state.VERIFY_TOKEN.data.token,
  loading: state.REGISTER.loading,
  error: state.REGISTER.error,
});

const mapDispatchToProps = (dispatch) => ({
  register: (payload: RegisterPayload) => dispatch(register(payload)),
});

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

class SignUp extends Component<ISignUpProps, ISignUpState> {
  public state: ISignUpState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    companyName: '',
    username: '',
    inviteCode: '',
  };

  public componentDidMount() {
    this.redirectIfNeeded();
    const { email, code } = this.getEmailAndCode();
    this.setState({
      email,
      inviteCode: code,
    });
  }

  public componentDidUpdate() {
    this.redirectIfNeeded();
  }

  private getEmailAndCode = (): SignUpRouteParams => {
    const { match, location } = this.props;
    let { email, code } = match.params;
    const { search } = location;
    if (search) {
      const searchParams = new URLSearchParams(search);
      email = decodeURIComponent(searchParams.get('email'));
      code = decodeURIComponent(searchParams.get('code'));
    }
    return { email, code };
  };

  private redirectIfNeeded = () => {
    const { history, location, isLoggedIn } = this.props;
    if (isLoggedIn) {
      history.push(location.state?.referrer ?? routes.HOME);
    }
  };

  private handleChange = ({ target }) => {
    const { id, value } = target as {
      id: 'email' | 'password';
      value: string;
    };
    this.setState((state) => {
      return {
        ...state,
        [id]: value,
      };
    });
  };

  private isValid = (): boolean => {
    const { email, password, inviteCode, username } = this.state;
    return (
      email.length &&
      password.length &&
      inviteCode.length &&
      username.length &&
      EMAIL_REGEX.test(email)
    );
  };

  private handleSubmit = () => {
    const { register, loading } = this.props;
    const {
      email,
      password,
      firstName,
      username,
      lastName,
      companyName,
      inviteCode,
    } = this.state;
    if (this.isValid() && !loading) {
      register({
        email,
        password,
        firstName,
        username,
        lastName,
        companyName,
        inviteCode,
      });
    }
  };

  private renderError = () => {
    const { error } = this.props;
    let rv;
    if (error) {
      rv = <Alert variant="danger">{error}</Alert>;
    }
    return rv;
  };

  public render() {
    const {
      email,
      password,
      username,
      firstName,
      lastName,
      companyName,
      inviteCode,
    } = this.state;
    const isFormValid = this.isValid();
    const { loading } = this.props;
    return (
      <Row>
        <Helmet>
          <title>Sign Up | Nvzn Augmented Reality</title>
          <meta name="title" content="Sign Up | Nvzn Augmented Reality" />
          <meta
            name="description"
            content="Sign Up to Nvzn Augmented Reality to get a never before experience in AR. Publish your models and share your fascinating experience with others too."
          />
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Col>
          <Card className="login">
            <Card.Header as="h5">Sign Up</Card.Header>
            <Card.Body>
              {this.renderError()}
              <Form>
                <Form.Group controlId="inviteCode">
                  <Form.Label>Invite Code</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Invite Code"
                    onChange={this.handleChange}
                    value={inviteCode}
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Email"
                    onChange={this.handleChange}
                    value={email}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Password *</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    value={password}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="username">
                  <Form.Label>Username *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={this.handleChange}
                    value={username}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First"
                        onChange={this.handleChange}
                        value={firstName}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last"
                        onChange={this.handleChange}
                        value={lastName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="companyName">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company (Optional)"
                    onChange={this.handleChange}
                    value={companyName}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  id="submit-sign-up-form"
                  disabled={!isFormValid || loading}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const SignUpConnected = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export { SignUpConnected as SignUp };
