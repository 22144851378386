import React, { Component } from 'react';
import '@google/model-viewer/dist/model-viewer';
import { Alert, Row, Col } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormikErrors } from 'formik';
import { AssetUploadPayload } from 'nvzn-models';
import { PageLoader } from './PageLoader';
import { AssetDataModel } from '../models';

interface IModelViewerProps {
  metadata: AssetUploadPayload;
  errors: FormikErrors<AssetUploadPayload>;
  isSubmitting?: boolean;
}

interface IFileEntry {
  file: File;
  data: string | ArrayBuffer;
}

interface IModelViewerState {
  glb: IFileEntry;
  backgroundImage: IFileEntry;
  environmentalImage: IFileEntry;
  assetData: AssetDataModel;
}

// Model viewer component used by the uploaded / editor
export class ModelPreviewer extends Component<
  IModelViewerProps,
  IModelViewerState
> {
  public state = {
    glb: {
      file: undefined,
      data: undefined,
    },
    backgroundImage: {
      file: undefined,
      data: undefined,
    },
    environmentalImage: {
      file: undefined,
      data: undefined,
    },
    assetData: new AssetDataModel(undefined),
  };
  public componentDidMount() {
    this.updateComponent({
      metadata: {
        files: {
          glb: undefined,
          usdz: undefined,
          backgroundImage: undefined,
          environmentalImage: undefined,
        },
        config: this.props.metadata.config,
      },
      errors: {},
      isSubmitting: false,
    });
  }

  public componentDidUpdate(prevProps: IModelViewerProps) {
    this.updateComponent(prevProps);
  }

  private updateComponent(prevProps: IModelViewerProps) {
    this.updateGlb(prevProps);
    this.updateBackgroundImage(prevProps);
    this.updateEnvironmentalImage(prevProps);
    this.updateConfig(prevProps);
  }

  private updateConfig = (prevProps: IModelViewerProps): void => {
    const {
      config: nextConfig,
      uuid,
      userId,
      lastEditedDate,
    } = this.props.metadata;
    if (prevProps.metadata !== this.props.metadata) {
      this.setState((state: IModelViewerState) => {
        const { assetData } = state;
        assetData.config = nextConfig;
        assetData.uuid = uuid;
        assetData.userId = userId;
        assetData.lastEditedDate = lastEditedDate;
        return { ...state, assetData };
      });
    }
  };

  private updateGlb = (prevProps: IModelViewerProps): void => {
    const { glb: prevGlb } = prevProps.metadata.files;
    const { glb: nextGlb } = this.props.metadata.files;
    const { glb: glbError } = this.props.errors.files || {
      glb: undefined,
    };
    const { glb: prevGlbError } = prevProps.errors.files || {
      glb: undefined,
    };
    const { glb } = this.state;
    if (
      !glbError &&
      (prevGlbError || prevGlb !== nextGlb) &&
      nextGlb !== glb.file &&
      nextGlb instanceof File
    ) {
      this.setState(
        (state: IModelViewerState) => {
          state.glb.file = nextGlb;
          return state;
        },
        () => {
          this.readFile(nextGlb, 'glb');
        },
      );
    }
  };

  private updateBackgroundImage = (prevProps: IModelViewerProps): void => {
    const { backgroundImage: prevBackgroundImage } = prevProps.metadata.files;
    const { backgroundImage: nextBackgroundImage } = this.props.metadata.files;
    const { backgroundImage: backgroundImageError } = this.props.errors
      .files || {
      backgroundImage: undefined,
    };
    const { backgroundImage: prevBackgroundImageError } = prevProps.errors
      .files || {
      backgroundImage: undefined,
    };
    const { backgroundImage } = this.state;
    if (
      !backgroundImageError &&
      (prevBackgroundImageError ||
        prevBackgroundImage !== nextBackgroundImage) &&
      nextBackgroundImage !== backgroundImage.file &&
      nextBackgroundImage instanceof File
    ) {
      this.setState(
        (state: IModelViewerState) => {
          state.backgroundImage.file = nextBackgroundImage;
          return state;
        },
        () => {
          this.readFile(nextBackgroundImage, 'backgroundImage');
        },
      );
    }
  };

  private updateEnvironmentalImage = (prevProps: IModelViewerProps): void => {
    const {
      environmentalImage: prevEnvironmentalImage,
    } = prevProps.metadata.files;
    const {
      environmentalImage: nextEnvironmentalImage,
    } = this.props.metadata.files;
    const { environmentalImage: environmentalImageError } = this.props.errors
      .files || {
      environmentalImage: undefined,
    };
    const { environmentalImage: prevEnvironmentalImageError } = prevProps.errors
      .files || {
      environmentalImage: undefined,
    };
    const { environmentalImage } = this.state;
    if (
      !environmentalImageError &&
      (prevEnvironmentalImageError ||
        prevEnvironmentalImage !== nextEnvironmentalImage) &&
      nextEnvironmentalImage !== environmentalImage.file &&
      nextEnvironmentalImage instanceof File
    ) {
      this.setState(
        (state: IModelViewerState) => {
          state.environmentalImage.file = nextEnvironmentalImage;
          return state;
        },
        () => {
          this.readFile(nextEnvironmentalImage, 'environmentalImage');
        },
      );
    }
  };

  private readFile = (
    file: File,
    name: 'glb' | 'backgroundImage' | 'environmentalImage',
  ) => {
    const reader = new FileReader();
    reader.onerror = () => {
      console.error(reader.error);
      this.setState((state: IModelViewerState) => {
        state[name].file = undefined;
        state[name].data = undefined;
        return state;
      });
    };
    reader.onload = () => {
      this.setState((state: IModelViewerState) => {
        state[name].data = reader.result;
        return state;
      });
    };
    reader.readAsDataURL(file);
  };

  private getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
  }

  private renderModel() {
    const { files } = this.props.metadata;
    const { glb, backgroundImage, environmentalImage, assetData } = this.state;
    const src =
      !files.glb || !(files.glb instanceof File) ? assetData.glbSrc : glb.data;
    const backgroundImageSrc =
      !files.backgroundImage || !(files.backgroundImage instanceof File)
        ? assetData.backgroundImageSrc
        : files.backgroundImage && files.backgroundImage.name.includes('hdr')
        ? `${URL.createObjectURL(files.backgroundImage)}#.hdr`
        : backgroundImage.data;
    const environmentalImageSrc =
      !files.environmentalImage || !(files.environmentalImage instanceof File)
        ? assetData.environmentalImageSrc
        : files.environmentalImage &&
          files.environmentalImage.name.includes('hdr')
        ? `${URL.createObjectURL(files.environmentalImage)}#.hdr`
        : environmentalImage.data;
    const autoRotate = assetData.autoRotate ? assetData.autoRotate : null;

    let _ar_modes = 'scene-viewer quick-look';
    var ua = navigator.userAgent;
    var chromeVersion = this.getChromeVersion();
    //var androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8));
    if (ua.includes('Android')) {
      if (chromeVersion >= 88) {
        _ar_modes = 'webxr scene-viewer quick-look';
      }
    }

    return (
      <model-viewer
        id="model-viewer"
        className="model-viewer"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: assetData.backgroundColor,
          '--poster-color': 'transparent',
        }}
        src={src}
        auto-rotate={autoRotate}
        camera-controls={true}
        alt={assetData.name}
        autoplay={assetData.autoplay}
        camera-target={assetData.cameraTarget}
        skybox-image={backgroundImageSrc}
        environment-image={environmentalImageSrc}
        shadow-intensity={assetData.shadowIntensity}
        shadow-softness={assetData.shadowSoftness}
        camera-orbit={assetData.cameraOrbit}
        min-camera-orbit={assetData.minCameraOrbit}
        max-camera-orbit={assetData.maxCameraOrbit}
        field-of-view={assetData.fov}
        exposure={assetData.exposure}
        ar
        ar-modes={_ar_modes}
        interaction-prompt="none"
        ar-status="not-presenting"
        ar-scale={assetData.arScale}
        ar-placement={assetData.arPlacement}
      ></model-viewer>
    );
  }

  public render() {
    let rv = (
      <Row className="justify-content-center mt-5">
        <Col sm={8} md={7} lg={6}>
          <Alert
            className="model-viewer__no-model d-flex align-items-center"
            variant="warning"
          >
            <FaExclamationTriangle style={{ flex: '0 0 40px' }} />
            <span className="text-center flex-grow-1">
              Please upload a model
            </span>
          </Alert>
        </Col>
      </Row>
    );
    const { metadata, isSubmitting } = this.props;
    const { files, uuid } = metadata;
    const { glb } = files;
    if (isSubmitting) {
      rv = <PageLoader />;
    } else if (glb || uuid) {
      rv = this.renderModel();
    }
    return rv;
  }
}
